import {actcode} from "../actions/actioncodes.jsx";

const initialState = {
  latLng: { lat: 42.1461710, lng: 13.4085674 },
  zoom: 5
};

function mapStore(state = initialState, action) {
  switch (action.type) {
    case actcode.REDUXINIT:
      // INIT della applicazione
      return Object.assign({}, initialState);

    case actcode.MAP_PAN_VIEW:
      return Object.assign({}, state, {
        latLng: action.latLng,
      });

    case actcode.MAP_ZOOM_VIEW:
      return Object.assign({}, state, {
        zoom: action.zoomlevel,
      });
    default:
      return state;
  }
}

export default mapStore;
