//ActionCodes.jsx  codici delle Action di react/redux

import keyMirror from "keymirror";

export const actcode = keyMirror({
  REDUXINIT: "@@redux/INIT",

  MAP_EDITOR_CLEAR: null,

  // Customer Actions
  CUSTOMER_REQUESTED: null,
  CUSTOMER_RECEIVED: null,

  // Map Actions
  MAP_PAN_VIEW: null,
  MAP_ZOOM_VIEW: null,
  MAP_EDITOR_STREETVIEW_OPEN: null,
  MAP_EDITOR_STREETVIEW_CLOSE: null,

  // Assets Actions
  ASSET_SELECT: null,
  ASSET_DESELECT: null,
  ASSET_REQUESTED: null,
  ASSET_RECEIVED: null,
  ASSET_LIST_REQUESTED: null,
  ASSET_LIST_RECEIVED: null,
  ASSET_UPDATE: null,
  ASSET_SCHEMA_RECEIVED: null,
  ASSET_ADDING: null,
  ASSET_EDITING: null,
  ASSET_CREATED: null,
  ASSET_DELETED: null,
  ASSET_PICKING: null,
  ASSET_VALUE_OPTIONS_REQUESTED: null,
  ASSET_VALUE_OPTIONS_RECEIVED: null,
  ASSET_VALUE_DETAILS_REQUESTED: null,
  ASSET_VALUE_DETAILS_RECEIVED: null,
  ASSET_VALUE_DETAILS_UPDATED: null,
  // Scenarios Actions
  SCENARIO_TYPES_RECEIVED: null,
  SCENARIO_LIST_REQUESTED: null,
  SCENARIO_LIST_RECEIVED: null,
  SCENARIO_REQUESTED: null,
  SCENARIO_RECEIVED: null,
  SCENARIO_SELECT: null,
  SCENARIO_DESELECT: null,
  SCENARIO_SCHEMA_RECEIVED: null,
  SCENARIO_ADDING: null,
  SCENARIO_EDITING: null,
  SCENARIO_CREATED: null,
  SCENARIO_DELETED: null,
  // Risklocations Actions
  RISKLOCATION_LIST_REQUESTED: null,
  RISKLOCATION_LIST_RECEIVED: null,
  RISKLOCATION_REQUESTED: null,
  RISKLOCATION_RECEIVED: null,
  RISKLOCATION_SELECT: null,
  RISKLOCATION_DESELECT: null,
  RISKLOCATION_SCHEMA_RECEIVED: null,
  RISKLOCATION_ADDING: null,
  RISKLOCATION_EDITING: null,
  RISKLOCATION_CREATED: null,
  RISKLOCATION_DELETED: null,
  // Processes Actions
  PROCESS_GRAPH_REQUESTED: null,
  PROCESS_GRAPH_RECEIVED: null,
  //   Node Actions
  NODECLASSES_REQUESTED: null,
  NODECLASSES_RECEIVED: null,
  PROCESS_NODE_SELECT: null,
  PROCESS_NODE_DESELECT: null,
  PROCESS_GRAPH_NODEDRAG: null,
  PROCESS_NODE_REQUESTED: null,
  PROCESS_NODE_RECEIVED: null,
  PROCESS_NODE_SCHEMA_REQUESTED: null,
  PROCESS_NODE_SCHEMA_RECEIVED: null,
  PROCESS_NODE_CREATED: null,
  PROCESS_NODE_DELETED: null,
  PROCESS_NODE_ADDING: null,
  PROCESS_NODE_CLONING: null,
  // Edge actions
  PROCESS_EDGE_CREATED: null,
  PROCESS_EDGE_DELETED: null,
  PROCESS_EDGE_UPDATED: null,
  PROCESS_EDGE_ADDING: null,
  PROCESS_EDGE_MOVING: null,

  FEATURE_DELETING: null,

  PROCESS_ACTION_CLEAR: null,

  // Graph Actions
  REQUEST_GRAPH: null,
  RECEIVED_GRAPH: null,

  // Node Actions
  CREATED_NODE: null,
  REQUEST_NODE: null,
  RECEIVED_NODE: null,
  RECEIVED_NODE_SCHEMA: null,
  SAVED_NODE: null,
  DELETED_NODE: null,
  SELECTED_NODE: null,
  SAVED_NODEATTRS: null,
  UPDATED_NODE_ATTR: null,
  UPDATED_NODE_POS: null,
  DESELECT_NODE: null,

  // Edge actions
  CREATED_EDGE: null,
  REQUEST_EDGE: null,
  SAVED_EDGE: null,
  DELETED_EDGE: null,

  // NodeFormActions
  RESET_NODEFORM: null,
  UPDATED_NODEFORM: null,

  ADD_ALERT: null,
});
