import keys from "lodash/keys";

export const APIQuery = (filter) => {
  let q = "";
  if (filter) {
    q = "?";
    const ks = keys(filter);
    ks.forEach((key, index) => {
      if (filter[key] && filter[key].type && filter[key].type === "array") {
        q = q + key + "=" + filter[key].join("%2C");
      } else {
        q = q + key + "=" + filter[key];
      }
      if (index < ks.length - 1) q = q + "&";
    });
  }
  return q;
};
