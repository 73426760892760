import axios from "axios";
import _ from "lodash";

export const WEATHER_FETCHED = "WEATHER_FETCHED";
export const LOAD_WEATHER = "LOAD_WEATHER";
export const IS_WEATHER_EMPTY = "IS_WEATHER_EMPTY";
export const ERROR_WEATHER = "ERROR_WEATHER";
export const CLEAR_WEATHER = "CLEAR_WEATHER";

const ROOT_URL = process.env.REACT_APP_API_URL;
export const fetchWeatherData = (cid) => {
  return (dispatch) => {
    dispatch({ type: LOAD_WEATHER });
    return axios
      .get(`${ROOT_URL}/customers/${cid}/weatheralert/`)
      .then(
        (response) => {
          if (!_.isEmpty(response.data)) {
            dispatch({
              type: WEATHER_FETCHED,
              payload: response.data[0],
            });
          } else {
            dispatch({
              type: IS_WEATHER_EMPTY,
            });
          }
        },
        (error) => {
          dispatch({
            type: ERROR_WEATHER,
          });
          return error;
        }
      )
      .catch((error) => {
          return error;
      });
  };
};

export const downloadWeatherData = (cid) => {
  return (dispatch) => {
    dispatch({ type: LOAD_WEATHER });
    return axios
      .get(`${ROOT_URL}/customers/${cid}/weatherdownload/`)
      .then(
        (response) => {
          if (!_.isEmpty(response.data)) {
            dispatch({
              type: WEATHER_FETCHED,
              payload: response.data,
            });
          } else {
            dispatch({
              type: IS_WEATHER_EMPTY,
            });
          }
          return response;
        },
        (error) => {
          dispatch({
            type: ERROR_WEATHER,
          });
          return error;
        }
      )
      .catch((error) => {
          return error;
      });
  };
};

export const clearWeather = () => {
  return {
    type: CLEAR_WEATHER
  };
}
