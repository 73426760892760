import {APIQuery} from "../utils/apiQuery";
import axios from "axios";
import _ from "lodash";

const ROOT_URL = process.env.REACT_APP_API_URL;

export const GET_BALANCES = "GET_BALANCES";
export const GET_MORE_SCORE = "GET_MORE_SCORE";
export const GET_RATING_DATA = "GET_RATING_DATA";
export const GET_SCORE_DISTRIBUTION = "GET_SCORE_DISTRIBUTION";
export const GET_FINANCIAL_RATIOS = "GET_FINANCIAL_RATIOS";
export const GET_DEFAULT_PROBABILITY = "GET_DEFAULT_PROBABILITY";
export const GET_HEATMAP = "GET_HEATMAP";
export const UPDATE_CHART_DATA = "UPDATE_CHART_DATA";
export const GET_UPDATE_HEATMAP_STATUS = "GET_UPDATE_HEATMAP_STATUS";

export const IS_MORE_SCORE_EMPTY = "IS_MORE_SCORE_EMPTY";
export const ARE_BALANCES_EMPTY = "ARE_BALANCES_EMPTY";
export const IS_RATING_DATA_EMPTY = "IS_RATING_DATA_EMPTY";
export const IS_SCORE_DISTRIBUTION_EMPTY = "IS_SCORE_DISTRIBUTION_EMPTY";
export const ARE_FINANTIAL_RATIOS_EMPTY = "ARE_FINANTIAL_RATIOS_EMPTY";
export const IS_DEFAULT_PROBABILITY_EMPTY = "IS_DEFAULT_PROBABILITY_EMPTY";
export const IS_HEATMAP_EMPTY = "IS_HEATMAP_EMPTY";
export const IS_SCENARIOS_EMPTY = "IS_SCENARIOS_EMPTY";

export const CLEAR_BALANCES = "CLEAR_BALANCES";
export const CLEAR_MORE_SCORE = "CLEAR_MORE_SCORE";
export const CLEAR_RATING_DATA = "CLEAR_RATING_DATA";
export const CLEAR_SCORE_DISTRIBUTION = "CLEAR_SCORE_DISTRIBUTION";
export const CLEAR_FINANCIAL_RATIOS = "CLEAR_FINANCIAL_RATIOS";
export const CLEAR_DEFAULT_PROBABILITY = "CLEAR_DEFAULT_PROBABILITY";
export const CLEAR_HEATMAP = "CLEAR_HEATMAP";
export const CLEAR_UPDATE_CHART_DATA = "CLEAR_UPDATE_CHART_DATA";
export const CLEAR_HEATMAP_STATUS = "CLEAR_HEATMAP_STATUS";

export const DELETE_BALANCE = "DELETE_BALANCE";

export const ERROR_BALANCES = "ERROR_BALANCES";
export const ERROR_MORE_SCORE = "ERROR_MORE_SCORE";
export const ERROR_RATING_DATA = "ERROR_RATING_DATA";
export const ERROR_SCORE_DISTRIBUTION = "ERROR_SCORE_DISTRIBUTION";
export const ERROR_FINANCIAL_RATIOS = "ERROR_FINANCIAL_RATIOS";
export const ERROR_DEFAULT_PROBABILITY = "ERROR_DEFAULT_PROBABILITY";
export const ERROR_HEATMAP = "ERROR_HEATMAP";
export const ERROR_UPDATE_CHART_DATA = "ERROR_UPDATE_CHART_DATA";

export const getBalances = (cid, query = null) => {
  const q = APIQuery(query);
  return (dispatch) => {
    return axios
      .get(`${ROOT_URL}/customers/${cid}/balances/${q}`)
      .then(
        (response) => {
          if (!_.isEmpty(response.data.results))
            dispatch({
              type: GET_BALANCES,
              balances: response.data,
            });
          else
            dispatch({
              type: ARE_BALANCES_EMPTY,
            });
        },
        (error) => {
          dispatch({
            type: ERROR_BALANCES,
          });
          return error;
        }
      )
      .catch((error) => {
        console.error("getBalances ", error);
        return error;
      });
  };
};

export const deleteBalance = (customer_uuid, balance_uuid) => {
  return (dispatch) => {
    return axios
      .delete(
        `${ROOT_URL}/customers/${customer_uuid}/balances/${balance_uuid}/`
      )
      .then(() => {
        dispatch({ type: DELETE_BALANCE });
        dispatch(getBalances(customer_uuid));
      })
      .catch((error) => {
        console.error(error);
      });
  };
};

export const clearBalances = () => {
  return {
    type: CLEAR_BALANCES,
  };
};

export const getMoreScore = (cid, query = null) => {
  const q = APIQuery(query);
  return (dispatch) => {
    return axios
      .get(`${ROOT_URL}/customers/${cid}/charts/morescore/${q}`)
      .then(
        (response) => {
          if (!_.isEmpty(response.data.chart_data))
            dispatch({
              type: GET_MORE_SCORE,
              moreScoreData: { ...response.data },
            });
          else
            dispatch({
              type: IS_MORE_SCORE_EMPTY,
            });
        },
        (error) => {
          dispatch({
            type: ERROR_MORE_SCORE,
          });
          return error;
        }
      )
      .catch((error) => {
        return error;
      });
  };
};

export const clearMoreScore = () => {
  return {
    type: CLEAR_MORE_SCORE,
  };
};

export const getScoreDistribution = (cid, query = null) => {
  const q = APIQuery(query);
  return (dispatch) => {
    return axios
      .get(`${ROOT_URL}/customers/${cid}/charts/scoredistribution/${q}`)
      .then(
        (response) => {
          if (!_.isEmpty(response.data.chart_data))
            dispatch({
              type: GET_SCORE_DISTRIBUTION,
              scoreDistribution: { ...response.data },
            });
          else
            dispatch({
              type: IS_SCORE_DISTRIBUTION_EMPTY,
            });
        },
        (error) => {
          dispatch({
            type: ERROR_SCORE_DISTRIBUTION,
          });
          return error;
        }
      )
      .catch((error) => {
        return error;
      });
  };
};

export const clearScoreDistribution = () => {
  return {
    type: CLEAR_SCORE_DISTRIBUTION,
  };
};

export const getRatingData = (cid) => {
  return (dispatch) => {
    return axios
      .get(`${ROOT_URL}/customers/${cid}/charts/ratingdata/`)
      .then(
        (response) => {
          if (!_.isEmpty(response.data.rating_data)) {
            if (
              !_.isEmpty(response.data.rating_data.has_black_records) &&
              !_.isEmpty(response.data.rating_data.credit_limit) &&
              !_.isEmpty(response.data.rating_data.black_records_check_date)
            )
              dispatch({
                type: GET_RATING_DATA,
                ratingData: {
                  hasBlackRecords: {
                    ...response.data.rating_data.has_black_records,
                  },
                  creditLimit: {
                    ...response.data.rating_data.credit_limit,
                  },
                  blackRecordsCheckDate: {
                    ...response.data.rating_data.black_records_check_date,
                  },
                },
              });
            if (!_.isEmpty(response.data.rating_data.probability_of_default)) {
              dispatch({
                type: GET_DEFAULT_PROBABILITY,
                probabilityOfDefaultData: {
                  ...response.data.rating_data.probability_of_default,
                },
              });
            }
          } else if (_.isEmpty(response.data.rating_data)) {
            dispatch({
              type: IS_RATING_DATA_EMPTY,
            });
            dispatch({ type: IS_DEFAULT_PROBABILITY_EMPTY });
          } else if (
            _.isEmpty(response.data.rating_data.has_black_records) ||
            _.isEmpty(response.data.rating_data.credit_limit) ||
            _.isEmpty(response.data.rating_data.black_records_check_date)
          ) {
            dispatch({
              type: IS_RATING_DATA_EMPTY,
            });
          }

          if (!response.data.rating_data.probability_of_default) {
            dispatch({ type: IS_DEFAULT_PROBABILITY_EMPTY });
          }
        },
        (error) => {
          dispatch({
            type: ERROR_DEFAULT_PROBABILITY,
          });
          dispatch({
            type: ERROR_RATING_DATA,
          });
          return error;
        }
      )
      .catch((error) => {
        console.error("getRatingData ", error);
        return error;
      });
  };
};

export const clearRatingData = () => {
  return {
    type: CLEAR_RATING_DATA,
  };
};

export const clearDefaultProbabilityData = () => {
  return {
    type: CLEAR_DEFAULT_PROBABILITY,
  };
};

export const getFinancialRatios = (cid, query = null) => {
  const q = APIQuery(query);
  return (dispatch) => {
    return axios
      .get(`${ROOT_URL}/customers/${cid}/charts/financialratios/${q}`)
      .then(
        (response) => {
          if (!_.isEmpty(response.data.chart_data))
            dispatch({
              type: GET_FINANCIAL_RATIOS,
              financialRatios: { ...response.data },
            });
          else
            dispatch({
              type: ARE_FINANTIAL_RATIOS_EMPTY,
            });
        },
        (error) => {
          dispatch({
            type: ERROR_FINANCIAL_RATIOS,
          });
          return error;
        }
      )
      .catch((error) => {
        console.error("getFinancialRatios ", error);
        return error;
      });
  };
};

export const clearFinancialRatios = () => {
  return {
    type: CLEAR_FINANCIAL_RATIOS,
  };
};

export const getHeatmap = (cid, query = null) => {
  const q = APIQuery(query);
  return (dispatch) => {
    return axios
      .get(`${ROOT_URL}/customers/${cid}/charts/heatmap/${q}`)
      .then(
        (response) => {
          if (!_.isEmpty(response.data.chart_data)) {
            dispatch({
              type: GET_HEATMAP,
              heatmap: { ...response.data.chart_data },
            });
          } else {
            dispatch({
              type: IS_HEATMAP_EMPTY,
            });
          }
        },
        (error) => {
          dispatch({
            type: ERROR_HEATMAP,
          });
          return error;
        }
      )
      .catch((error) => {
        console.error("getHeatmap ", error);
        return error;
      });
  };
};

export const updateChartData = (cID, query = null) => {
  return (dispatch) => {
    return axios
      .get(`${ROOT_URL}/customers/${cID}/newfinancialdata/`)
      .then(
        (response) => {
          dispatch({
            type: UPDATE_CHART_DATA,
            updateChartDataMsg: {
              message: response.data.message,
              status: response.data.status,
              uuid: cID,
            },
          });
          return response;
        },
        (error) => {
          dispatch({
            type: ERROR_UPDATE_CHART_DATA,
          });
        }
      )
      .catch((error) => {
        console.error("updateChartlData ", error);
      });
  };
};

export const heatMapUpdateData = (cID) => {
  return (dispatch) => {
    return axios.post(`${ROOT_URL}/customers/${cID}/analysis/base/`, null).then(
      (response) => {
        //   console.log(response)
      },
      (error) => {
        console.error("error", error);
      }
    );
  };
};

export const getMapUpdateDataStatus = (cID, query = null) => {
  return (dispatch) => {
    return axios
      .get(`${ROOT_URL}/customers/${cID}/analysis/update/`)
      .then(
        (response) => {
          dispatch({
            type: GET_UPDATE_HEATMAP_STATUS,
            heatMapStatus: { ...response.data },
          });
        },
        (error) => {
          console.error("getMapUpdateDataStatus ", error);
        }
      )
      .catch((error) => {
        console.error("getMapUpdateDataStatus ", error);
      });
  };
};

export const clearHeatmapStatus = () => {
  return {
    type: CLEAR_HEATMAP_STATUS,
  };
};

export const clearUpdateChartData = () => {
  return {
    type: CLEAR_UPDATE_CHART_DATA,
  };
};

export const clearHeatmap = () => {
  return {
    type: CLEAR_HEATMAP,
  };
};
