//nodeformStore.jxs : reducer for Node Form

import {actcode} from "../actions/actioncodes.jsx";

import {debug} from "../lib/util.jsx";

const formSchema = {
  type: "object",
  title: "",
  description: "",
  properties: {},
  required: [],
};

const formData = {};

const initialState = {
  isFetching: false,
  didInvalidate: true,
  uuid: undefined,
  formSchema: formSchema,
  formData: formData,
  nodeClasses: {},
};

function nodeFormStore(state = initialState, action) {
  switch (action.type) {
    case actcode.REDUXINIT:
      // INIT della applicazione
      return Object.assign({}, initialState);

    case actcode.NODECLASSES_RECEIVED:
      return Object.assign({}, state, {
        nodeClasses: action.data,
      });

    case actcode.RESET_NODEFORM:
    case actcode.DESELECT_NODE:
    case actcode.ASSET_DESELECT:
    case actcode.SCENARIO_DESELECT:
    case actcode.PROCESS_NODE_DESELECT:
      // Reset del form
      return Object.assign({}, state, initialState);

    case actcode.PROCESS_NODE_SELECT:
      return Object.assign({}, state, {
        uuid: action.nodeUuid,
      });

    case actcode.ASSET_SELECT:
      return Object.assign({}, state, {
        uuid: action.assetUuid,
      });

    case actcode.REQUEST_NODE:
      return Object.assign({}, state, {
        isFetching: true,
        didInvalidate: false,
      });

    case actcode.RECEIVED_NODE_SCHEMA:
    case actcode.PROCESS_NODE_SCHEMA_RECEIVED:
      // leggo lo schema per costruire il form adatto al Node
      var response = action.data;
      var formSchema = response["JSONSchema"];
      // impongo i campi da mostrare nel form (devono esistere in formSchema)
      return Object.assign({}, state, {
        isFetching: false,
        didInvalidate: false,
        uuid: action.nodeUuid,
        formSchema: formSchema,
        objectType: "node",
      });

    case actcode.ASSET_SCHEMA_RECEIVED:
      let asr_response = action.data;
      let asr_schema = asr_response["JSONSchema"];
      return Object.assign({}, state, {
        isFetching: false,
        didInvalidate: false,
        uuid: action.assetUuid,
        formSchema: asr_schema,
        objectType: "asset",
      });

    case actcode.RISKLOCATION_SCHEMA_RECEIVED:
      let rsr_response = action.data;
      let rsr_schema = rsr_response["JSONSchema"];
      return Object.assign({}, state, {
        isFetching: false,
        didInvalidate: false,
        uuid: action.risklocationUuid,
        formSchema: rsr_schema,
        objectType: "risklocation",
      });

    case actcode.ASSET_RECEIVED:
      if (state.uuid !== action.assetUuid) return state;

      return Object.assign({}, state, {
        isFetching: false,
        didInvalidate: false,
        uuid: action.assetUuid,
        formData: action.data,
      });

    case actcode.SCENARIO_TYPES_RECEIVED:
      return Object.assign({}, state, {
        scenarioTypes: action.scenario_types,
      });

    case actcode.SCENARIO_SCHEMA_RECEIVED:
      let ssr_response = action.data;
      let ssr_schema = ssr_response["JSONSchema"];

      let ssr_types = state.scenarioTypes;
      let scenario_types_enum = [""];
      let scenario_types_names = ["---------"];

      // get scenario types from types list
      for (let i = 0; i < ssr_types.length; i++) {
        scenario_types_enum.push(ssr_types[i].scenario_code);
        scenario_types_names.push(ssr_types[i].name);
      }

      ssr_schema.properties.scenario_type.enum = scenario_types_enum;
      ssr_schema.properties.scenario_type.enumNames = scenario_types_names;

      return Object.assign({}, state, {
        isFetching: false,
        didInvalidate: false,
        uuid: action.scenarioUuid,
        formSchema: ssr_schema,
        objectType: "scenario",
      });

    case actcode.SCENARIO_RECEIVED:
      debug("SCENARIO RECEIVED", action.data);
      if (state.uuid !== action.scenarioUuid) return state;

      return Object.assign({}, state, {
        isFetching: false,
        didInvalidate: false,
        uuid: action.scenarioUuid,
        formData: action.data,
      });

    case actcode.RECEIVED_NODE:
    case actcode.UPDATED_NODEFORM:
    case actcode.PROCESS_NODE_RECEIVED:
      if (state.uuid !== action.nodeUuid) return state;

      var nodeData = Object.assign({}, action.data);

      /** ricostruisco l'Object eav_values perché dal Serializer mi arriva
       *  una lista di object invece che un unico Object
       */
      var eav_values = {};
      var eavInput = nodeData.eav_values;
      for (var key in eavInput) {
        if (eavInput.hasOwnProperty(key)) {
          var slug = eavInput[key]["slug"];
          var value = eavInput[key]["value"];
          eav_values[slug] = value;
          debug("found " + key + ", slug: " + slug + ", val: " + value);
        }
      }
      nodeData.eav_values = eav_values;
      return Object.assign({}, state, {
        isFetching: false,
        didInvalidate: false,
        uuid: action.nodeUuid,
        formData: nodeData,
      });

    default:
      return state;
  } // case
  // return state;
}

export default nodeFormStore;
