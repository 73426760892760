import axios from "axios";
import {APIQuery} from "../utils/apiQuery";
import _ from "lodash";

export const GET_CUSTOMERS = "GET_CUSTOMERS";
export const ERROR_GET_CUSTOMERS = "ERROR_GET_CUSTOMERS";
export const GET_CUSTOMER = "GET_CUSTOMER";
export const CLEAR_CUSTOMER_DATA = "CLEAR_CUSTOMER_DATA";
export const CLEAR_CUSTOMER_CONTACT = "CLEAR_CUSTOMER_CONTACT";
export const ERROR_GET_CUSTOMER = "ERROR_GET_CUSTOMER";
export const EMPTY_ARCHIVED_CUSTOMERS = "EMPTY_ARCHIVED_CUSTOMERS";
export const EMPTY_NOT_ARCHIVED_CUSTOMERS = "EMPTY_NOT_ARCHIVED_CUSTOMERS";
export const ADD_CUSTOMER = "ADD_CUSTOMER";
export const ADD_CUSTOMER_CONTACT = "ADD_CUSTOMER_CONTACT";
export const ADD_CUSTOMER_ERROR = "ADD_CUSTOMER_ERROR";
export const ADD_CUSTOMER_CONTACT_ERROR = "ADD_CUSTOMER_ERROR";
export const LOAD_CUSTOMERS = "LOAD_CUSTOMERS";
export const UPDATE_CUSTOMER = "UPDATE_CUSTOMER";
export const ERROR_UPDATE_CUSTOMER = "ERROR_UPDATE_CUSTOMER";
export const UPDATING_CUSTOMER = "UPDATING_CUSTOMER";
export const DELETE_CUSTOMER_PIC = "DELETE_CUSTOMER_PIC";
export const ADDING_CUSTOMER = "ADDING_CUSTOMER";
export const ADDING_CUSTOMER_CONTACT = "ADDING_CUSTOMER_CONTACT";
export const CLEAR_ADDED_CUSTOMER = "CLEAR_ADDED_CUSTOMER";
export const RESET_SAVE_CHANGES = "RESET_SAVE_CHANGES";
export const SAVE_CHANGES = "SAVE_CHANGES";
export const SUCCESS_UPLOADING_CUSTOMER_PICTURE =
    "SUCCESS_UPLOADING_CUSTOMER_PICTURE";
export const ERROR_UPLOADING_CUSTOMER_PICTURE =
    "ERROR_UPLOADING_CUSTOMER_PICTURE";
export const GET_CUSTOMER_PICTURES = "GET_CUSTOMER_PICTURES";
export const ADD_CUSTOMER_PICTURES = "ADD_CUSTOMER_PICTURES";
export const PATCH_CUSTOMER_PICTURE = "PATCH_CUSTOMER_PICTURE";
export const IS_FETCHING_CUSTOMER_PICTURES = "IS_FETCHING_CUSTOMER_PICTURES";
export const CLEAR_CUSTOMER_PICTURES = "CLEAR_CUSTOMER_PICTURES";
export const CANDIDATES_LOADING = "CANDIDATES_LOADING";
export const GET_CANDIDATES = "GET_CANDIDATES";
export const EMPTY_CANDIDATES = "EMPTY_CANDIDATES";
export const ERROR_CANDIDATES = "ERROR_CANDIDATES";
export const STOP_AUTOPILOT = "STOP_AUTOPILOT";
export const START_AUTOPILOT = "START_AUTOPILOT";
export const AUTOPILOT_STATUS = "AUTOPILOT_STATUS";
export const ERROR_AUTOPILOT_STATUS = "ERROR_AUTOPILOT_STATUS";
export const EMPTY_AUTOPILOT_STATUS = "EMPTY_AUTOPILOT_STATUS";
export const CLEAR_AUTOPILOT = "CLEAR_AUTOPILOT";
export const FETCH_PICTURE_TYPES = "FETCH_PICTURE_TYPES";
export const PIC_UPLOADED_DONE = "PIC_UPLOADED_DONE";
export const PIC_UPLOADED = "PIC_UPLOADED";
export const SELECTED_AUTOPILOT_UUID = "SELECTED_AUTOPILOT_UUID";
export const GET_CUSTOMER_CONTACT = "GET_CUSTOMER_CONTACT";
export const ERROR_GET_CUSTOMER_CONTACT = "ERROR_GET_CUSTOMER_CONTACT";
export const PIVA_DUPLICATE = "PIVA_DUPLICATE";
export const CLEAN_PIVA_DUPLICATE = "CLEAN_PIVA_DUPLICATE";
export const STOP_ADDING_CUSTOMER = "STOP_ADDING_CUSTOMER";

export const SET_SHOW_AUTO_ADD_CUSTOMER_MODAL = "SET_SHOW_AUTO_ADD_CUSTOMER_MODAL";
export const UPDATE_STATE = "UPDATE_STATE";
export const SET_USE_CUSTOMERS_FILTER_NAME = "SET_USE_CUSTOMERS_FILTER_NAME";

export const setShowAutoAddCustomerModal = (status) => {
    return {type: SET_SHOW_AUTO_ADD_CUSTOMER_MODAL, payload: status};
};

const ROOT_URL = process.env.REACT_APP_API_URL;
export const getCustomers = (query = null) => {
    const q = APIQuery(query);
    return (dispatch) => {
        return axios
            .get(`${ROOT_URL}/customers/${q}`)
            .then(
                (response) => {
                    if (!_.isEmpty(response.data.results)) {
                        dispatch({
                            type: GET_CUSTOMERS,
                            customers: response.data,
                        });
                    } else {
                        if (query.archived) {
                            dispatch({type: EMPTY_ARCHIVED_CUSTOMERS});
                        } else {
                            dispatch({type: EMPTY_NOT_ARCHIVED_CUSTOMERS});
                        }
                    }
                },
                (error) => {
                    dispatch({type: ERROR_GET_CUSTOMERS,});
                    return error;
                }
            )
            .catch((error) => {
                console.error("ERROR GET CUSTOMERS", error);
                return error;
            });
    };
};

export const getCustomer = (cid) => {
    return (dispatch) => {
        return axios
            .get(`${ROOT_URL}/customers/${cid}/`)
            .then(
                (response) => {
                    dispatch({type: GET_CUSTOMER, customer: {...response.data},});
                },
                (error) => {
                    dispatch({type: ERROR_GET_CUSTOMER,});
                    return error;

                }
            )
            .catch((error) => {
                console.error("ERROR GET CUSTOMERS", error);
                return error;
            });
    };
};

export const clearCustomerContact = () => {
    return {
        type: CLEAR_CUSTOMER_CONTACT,
    };
};

/**
 * Archivia o dis-archivia un customer
 * @param {boolean} archive
 */
export const archiveCustomer = (cid, archive, page = 1) => {
    return (dispatch) => {
        return axios
            .put(`${ROOT_URL}/customers/${cid}/archive/`, {
                archived: archive,
            })
            .then(
                (res) => {
                    dispatch(
                        getCustomers({
                            page: page,
                            archived: !archive,
                            ordering: localStorage.getItem("customer_order"),
                        })
                    );
                    return res;
                },
                (error) => {
                    return error;
                }
            )
            .catch((error) => {
                console.error("archiveCustomer", error);
                return error;
            });
    };
};

export const clearPivaDuplicateError = () => {
    return {
        type: CLEAN_PIVA_DUPLICATE,
    };
};

export const addCustomer = (customerData) => {
    return (dispatch) => {
        return axios
            .post(`${ROOT_URL}/customers/`, customerData)
            .then(
                (response) => {
                    return ({type: "success", content: "customers.customer_created"});
                },
                (error) => {
                    if ((error?.response?.data?.STATUS === "P.IVA ALREADY ADDED")) {
                        return ({type: "error", content: "customers.piva_is_already_exist"});
                    }
                    return ({type: "error", content: "customers.customer_creation_error"});
                }
            )
            .catch((error) => {
                console.error("addCustomer ", error);
                return ({type: "error", content: "customers.customer_creation_error"});
            });
    };
};

export const addCustomerContact = (cid, customerContactData) => {
    return (dispatch) => {
        dispatch({type: ADDING_CUSTOMER_CONTACT});
        const obj = {
            username: customerContactData.username,
            first_name: customerContactData.first_name,
            last_name: customerContactData.last_name,
            email: customerContactData.email,
        };
        return axios
            .post(`${ROOT_URL}/customers/${cid}/usercustomercontact/create/`, {...obj,})
            .then(
                (result) => {
                    dispatch({type: ADD_CUSTOMER_CONTACT});
                    return result;
                },
                (error) => {
                    dispatch({type: ADD_CUSTOMER_CONTACT_ERROR});
                    return error;
                }
            )
            .catch((error) => {
                console.error("addCustomerContact ", error);
                return error;
            });
    };
};

export const addCustomerContactRevisited = (cid, customerContactData) => {
    return (dispatch) => {
        dispatch({type: ADDING_CUSTOMER_CONTACT});
        return axios
            .post(`${ROOT_URL}/customers/${cid}/usercustomercontact/create/`, customerContactData)
            .then(
                (response) => {
                    dispatch({type: ADD_CUSTOMER_CONTACT});
                    dispatch(getCustomerContact(cid));
                    return {type: "success", content: "customers.customer_contact_inserted_success"};
                },
                (error) => {
                    dispatch({type: ADD_CUSTOMER_CONTACT_ERROR});
                    return {type: "error", content: "customers.customer_contact_inserted_error"};
                }
            )
            .catch((error) => console.error("addCustomerContact ", error));
    };
};


export const getCustomerContact = (cid) => {
    return (dispatch) => {
        return axios
            .get(`${ROOT_URL}/customers/${cid}/usercustomercontact/`)
            .then(
                (response) => {
                    dispatch({
                        type: GET_CUSTOMER_CONTACT,
                        customerContact: {...response.data},
                    });
                    return response;
                },
                (error) => {
                    dispatch({type: ERROR_GET_CUSTOMER_CONTACT,});
                    return error;
                }
            )
            .catch((error) => {
                console.error("ERROR GET CUSTOMERS", error);
                return error;
            });
    };
};

export const clearAddedCustomerVariable = () => {
    return {
        type: CLEAR_ADDED_CUSTOMER,
    };
};

export const updateRegistry = (cid, customerData) => {
    return (dispatch) => {
        dispatch({type: UPDATING_CUSTOMER});

        return axios.patch(`${ROOT_URL}/customers/${cid}/`, {...customerData})
            .then(
                (response) => {
                    dispatch({type: UPDATE_CUSTOMER});
                    dispatch({
                        type: GET_CUSTOMER,
                        customer: {...response.data},
                    });
                    return {type: "success", message: "customers.registry_update_success"};
                },
                (error) => {
                    dispatch({type: ERROR_UPDATE_CUSTOMER});
                    return {type: "error", message: "customers.registry_update_error"};
                }
            );
    };
};

export const unsetChangesSaved = () => {
    return {type: RESET_SAVE_CHANGES};
};

export const handleNewCustomersPicture = (cid, data) => {
    return (dispatch) => {
        dispatch({type: UPDATING_CUSTOMER});
        dispatch({type: PIC_UPLOADED});
        const promises = [];
        const config = {
            headers: {"content-type": "multipart/form-data"},
        };
        Array.from(data).forEach((el) => {
            let picture = new FormData();
            picture.append("name", el.name);
            picture.append("content", el);
            picture.append("file", el);
            picture.append("content_type", el.type);
            picture.append("type", "GENERIC");
            promises.push(axios.post(`${ROOT_URL}/customers/${cid}/pictures/`, picture, config));
        });
        return Promise.all(promises)
            .then((results) => {
                const images = results.map(result => result.data);
                dispatch({type: ADD_CUSTOMER_PICTURES, payload: images});
                dispatch({type: SAVE_CHANGES});
                return results;
            })
            .then(
                (res) => {
                    dispatch({type: SUCCESS_UPLOADING_CUSTOMER_PICTURE});
                    dispatch({type: PIC_UPLOADED_DONE});
                    return res;
                },
                (error) => {
                    dispatch({type: ERROR_UPLOADING_CUSTOMER_PICTURE});
                    return error;
                }
            )
            .catch((error) => {
                console.error(error);
                return error;
            });
    };

};

export const getCustomerPictures = (cid) => {
    return (dispatch) => {
        dispatch({
            type: IS_FETCHING_CUSTOMER_PICTURES,
        });
        axios
            .get(`${ROOT_URL}/customers/${cid}/pictures/?pictures_type=GENERIC`)
            .then((response) => {
                if (!_.isEmpty(response?.data))
                    dispatch({
                        type: GET_CUSTOMER_PICTURES,
                        payload: [...response.data],
                    });
                else
                    dispatch({
                        type: GET_CUSTOMER_PICTURES,
                        payload: [],
                    });
            });
    };
};

export const clearCustomerPictures = () => {
    return {
        type: CLEAR_CUSTOMER_PICTURES,
    };
};

export const handleUpdateCustomerPicture = (cid, uuid, type) => {
    return (dispatch, store) => {
        dispatch({type: UPDATING_CUSTOMER});
        return axios
            .patch(`${ROOT_URL}/customers/${cid}/pictures/${uuid}/`, {type: type})
            .then(
                (res) => {
                    dispatch({type: PATCH_CUSTOMER_PICTURE, payload: {uuid: uuid, type: type}});
                    dispatch({type: SAVE_CHANGES});
                    return res;
                },
                (error) => {
                    dispatch({type: ERROR_UPLOADING_CUSTOMER_PICTURE});
                    return error;
                }
            )
            .catch((error) => {
                console.error(error);
                return error;
            });
    };
};

export const deleteCustomerPicture = (cid, uuid) => {
    return (dispatch) => {
        return axios
            .delete(`${ROOT_URL}/customers/${cid}/pictures/${uuid}/`)
            .then(() => {
                dispatch({type: DELETE_CUSTOMER_PIC, payload: uuid});
                dispatch({type: SAVE_CHANGES});
            })
            .catch((error) => {
                console.error(error);
            });
    };
};

export const getCandidates = (country, piva) => {
    return (dispatch) => {
        dispatch({
            type: CANDIDATES_LOADING,
        });
        const query = {
            q: piva,
            country: country,
        };
        const q = APIQuery(query);
        axios.get(`${ROOT_URL}/candidates/${q}`).then(
            (response) => {
                if (!_.isEmpty(response.data)) {
                    dispatch({type: GET_CANDIDATES, payload: [...response.data]});
                } else {
                    dispatch({type: EMPTY_CANDIDATES});
                }
            },
            () => {
                dispatch({type: ERROR_CANDIDATES});
            }
        );
    };
};

export const clearCandidates = () => {
    return dispatch => dispatch({type: EMPTY_CANDIDATES});
};

export const startAutopilot = (candidate) => {
    return (dispatch) => {
        dispatch({type: START_AUTOPILOT});
        return axios.post(`${ROOT_URL}/candidates/`, candidate).then(
            (response) => {
                dispatch({
                    type: SELECTED_AUTOPILOT_UUID,
                    payload: response.data.customer_uuid,
                });
                //   dispatch({ type: STOP_AUTOPILOT });
                dispatch(
                    getCustomers({
                        page: 1,
                        archived: false,
                        ordering: localStorage.getItem("customer_order"),
                    })
                );
            },
            (error) => {
                console.error("error", error);
            }
        );
    };
};

export const getAutopilotStatus = (cid, query = null) => {
    const q = APIQuery(query);
    return (dispatch) => {
        //  dispatch({type: SELECTED_AUTOPILOT_UUID, payload: cid })

        return axios.get(`${ROOT_URL}/customers/${cid}/autopilot/update/${q}`).then(
            (response) => {
                if (!_.isEmpty(response?.data))
                    dispatch({type: AUTOPILOT_STATUS, payload: response.data});
                else dispatch({type: EMPTY_AUTOPILOT_STATUS, payload: {}});
            },
            () => {
                dispatch({type: ERROR_AUTOPILOT_STATUS});
            }
        );
    };
};

export const loadingCustomer = () => {
    return {
        type: LOAD_CUSTOMERS,
    };
};

export const clearAutopilot = () => {
    return {
        type: CLEAR_AUTOPILOT,
    };
};

export const stopAutopilot = () => {
    return {
        type: STOP_AUTOPILOT,
    };
};

export function fetchPictureTypes() {
    return (dispatch) => {
        return axios.get(`${ROOT_URL}/picturetypes/`).then((res) => {
            dispatch({
                type: FETCH_PICTURE_TYPES,
                payload: res.data,
            });
            return res;
        });
    };
}

export const stopAddingCustomer = () => {
    return {
        type: STOP_ADDING_CUSTOMER,
    };
};

export const updateStatusCustomer = (cid, status) => {
    return (dispatch) => {
        dispatch({type: UPDATE_STATE, payload: status});
        axios.patch(`${ROOT_URL}/customers/${cid}/`, {status})
            .then(res => res).catch(error => console.log(error));
    };
};

export const setUseCustomersFilterName = (value) => {
    return dispatch => dispatch({type: SET_USE_CUSTOMERS_FILTER_NAME, payload: value});
};

export const clearCustomerData = () => {
    return dispatch => dispatch({type: CLEAR_CUSTOMER_DATA});
};