import LoginReducer from "./login/loginReducer";
import UserReducer from "./user/reducer";
import CustomersReducer from "./customers/reducer";
import CommonReducer from "./common/reducer";
import ScenariosReducer from "./insurancemanagement/reducer";
import ConstructionValueReducer from "./constructionvalue/reducer";
import {combineReducers} from "redux";
import nodeFormStore from "./constructionvalue/components/akane/reducers/nodeformstore";
import mapStore from "./constructionvalue/components/akane/reducers/mapstore";
import processStore from "./constructionvalue/components/akane/reducers/processstore";
import {polyglotReducer} from "redux-polyglot";
import ChartsReducer from "./charts/reducer";
import WeatherReducer from "./dashboard/components/weatherdatacard/reducer";
import GrossProfitReducer from "./dashboard/components/grossProfit/reducer";
import ContentValueReducer from "./dashboard/components/contentvalue/reducer";
import DocumentsReducer from "./dashboard/components/documents/reducer";
import ReportReducer from "./dashboard/components/reportCard/reducer";

const appReducer = combineReducers({
  login: LoginReducer,
  user: UserReducer,
  customers: CustomersReducer,
  charts: ChartsReducer,
  common: CommonReducer,
  scenarios: ScenariosReducer,
  constructionValue: ConstructionValueReducer,
  nodeFormStore: nodeFormStore,
  mapStore: mapStore,
  processStore: processStore,
  polyglot: polyglotReducer,
  weather: WeatherReducer,
  grossProfit: GrossProfitReducer,
  contentValue: ContentValueReducer,
  documents: DocumentsReducer,
  report: ReportReducer
});

const rootReducer = (state, action) => {
  if (action.type === "USER_LOGOUT") {
    return appReducer(undefined, action);
  }
  return appReducer(state, action);
}

export default rootReducer;
