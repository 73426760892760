//funzioni di utilità

const DEBUG_LOG = process.env.NODE_ENV === "dev";

export function debug(...args) {
  if (DEBUG_LOG) {
    // console.log(...args);
  }
}

/*
 * Restituisce la stringa con la prima lettera maiuscola
 */
export function ucFirst(str) {
  return str.charAt(0).toUpperCase() + str.substr(1);
}

/**
 * Returns a function, that, as long as it continues to be invoked, will not
 * be triggered. The function will be called after it stops being called for
 * N milliseconds. If `immediate` is passed, trigger the function on the
 * leading edge, instead of the trailing.
 */
export function debounce(func, wait, immediate) {
  var timeout;
  return function () {
    var context = this,
      args = arguments;
    var later = function () {
      timeout = null;
      if (!immediate) func.apply(context, args);
    };
    var callNow = immediate && !timeout;
    clearTimeout(timeout);
    timeout = setTimeout(later, wait);
    if (callNow) func.apply(context, args);
  };
}

/**
 * Verifica che il parametro sia un numero e non una stringa
 */
export function isNumeric(n) {
  return !isNaN(parseFloat(n)) && isFinite(n);
}

/**
 * Clona un oggetto rimuovendo una chiave
 */
export function removeByKeys(myObj, deleteKeys) {
  if (!(deleteKeys.constructor === Array)) deleteKeys = [deleteKeys];

  return Object.keys(myObj)
    .filter((key) => -1 === deleteKeys.indexOf(key))
    .reduce((result, current) => {
      result[current] = myObj[current];
      return result;
    }, {});
  /*return Object.keys(myObj)
    .filter(key => key !== deleteKey)
    .reduce((result, current) => {
      result[current] = myObj[current];
      return result;
  }, {});*/
}

/*********************************/
/* Data transformation functions */
/*********************************/
/**
 * Parse an area string and create a corresponding javascript object
 *
 * Example string
 * SRID=4326;POLYGON ((8.9853572845458984 45.4203832454540262,
 * 8.9845848083496094 45.4173106905884154, 8.9888763427734375
 * 45.4165274635874283, 8.9923095703125000 45.4181541536710185,
 * 8.9903354644775391 45.4209856875865370, 8.9853572845458984
 * 45.4203832454540262))
 */
/*export function createAreaCoordsStr(type, coords, isLatLng, isStr) {
  if(isStr)
    return _createAreaCoordsStr(type, coords, isLatLng);
  else
    return _createAreaCoordsObj(type, coords, isLatLng);
}*/

export function createAreaCoordsObj(type, coords, isLatLng) {
  if (isLatLng === undefined) isLatLng = false;
  let lati = isLatLng ? 0 : 1;
  let lngi = isLatLng ? 1 : 0;
  let retObj = {};

  switch (type) {
    case "POINT":
      retObj.type = "point";
      retObj.coordinates = [coords[lngi], coords[lati]];
      return retObj;

    case "POLYGON":
      retObj.type = "Polygon";
      let polygonCoords = [];
      for (let i = 0; i < coords.length; i++) {
        polygonCoords.push([coords[i][lngi], coords[i][lati]]);
      }
      retObj.coordinates = [polygonCoords];
      return retObj;

    default:
      return "";
  }
}

export function createAreaCoordsStr(type, coords, isLatLng) {
  if (isLatLng === undefined) isLatLng = false;

  switch (type) {
    case "POINT":
      let pointStr = "SRID=4326;POINT (" + coords.lng + " " + coords.lat + ")";
      return pointStr;

    case "POLYGON":
      let polygonCoords = [];
      let lati = isLatLng ? 0 : 1;
      let lngi = isLatLng ? 1 : 0;
      for (let i = 0; i < coords.length; i++) {
        polygonCoords.push(coords[i][lngi] + "  " + coords[i][lati]);
      }
      return "SRID=4326;POLYGON((" + polygonCoords.join(",") + "))";

    default:
      return "";
  }
}

/**
 * Parse an area string and create a corresponding javascript object
 *
 * Example string
 * SRID=4326;POLYGON ((8.9853572845458984 45.4203832454540262,
 * 8.9845848083496094 45.4173106905884154, 8.9888763427734375
 * 45.4165274635874283, 8.9923095703125000 45.4181541536710185,
 * 8.9903354644775391 45.4209856875865370, 8.9853572845458984
 * 45.4203832454540262))
 * Returns:
 * coordinate(s) in [lat, lng] format
 */
export function parseAreaCoords(coords, isLatLng) {
  if (isLatLng === undefined) isLatLng = false;

  if (typeof coords == "string") return _parseAreaCoords(coords);
  else {
    if (!isLatLng) {
      for (let i = 0; i < coords.coordinates.length; i++) {
        for (let j = 0; j < coords.coordinates[i].length; j++) {
          coords.coordinates[i][j] = [
            coords.coordinates[i][j][1],
            coords.coordinates[i][j][0],
          ];
        }
      }
    }
    return coords.coordinates;
  }
}

function _parseAreaCoords(coordStr) {
  let type = coordStr.includes("POINT")
    ? "POINT"
    : coordStr.includes("POLYGON")
    ? "POLYGON"
    : "";

  switch (type) {
    case "POINT":
      let pointCoords = coordStr
        .substring(coordStr.indexOf("(") + 1, coordStr.indexOf(")"))
        .split(" ");
      let aux = parseFloat(pointCoords[0]);
      pointCoords[0] = parseFloat(pointCoords[1]);
      pointCoords[1] = aux;
      return pointCoords;

    case "POLYGON":
      let pathReg = /\([0-9\s\.\-,]+\)/g;
      let polygonsStr = coordStr.match(pathReg);

      // polygonsStr now is an array with a string for each polygon
      // Parse each polygon string-representation and create an array of point objects
      return polygonsStr.map(function (str) {
        str = str.replace("(", "").replace(")", "");
        // parse each point
        return str.split(",").map((pointStr) => {
          pointStr = pointStr.trim().split(" ");
          //let point = { lat: parseFloat(pointStr[0]), lng: parseFloat(pointStr[1]) };
          return [parseFloat(pointStr[1]), parseFloat(pointStr[0])];
        });
      });

    default:
      return [];
  }
}
