import cloneDeep from "lodash/cloneDeep";
import {
    ADD_CUSTOMER_DOCUMENT,
    DELETE_DOCUMENT,
    ERROR_UPDATE_DOCUMENT,
    FETCH_DOCUMENT_CATEGORIES,
    FETCH_DOCUMENTS,
    RESET_DOCUMENTS,
    SET_DOCUMENT_CATEGORY,
    UPDATE_DOCUMENT,
    UPLOAD_DOCUMENT
} from "./actions";

const INITIAL_STATE = {
    documents: undefined,
    newDataDownloaded: null,
    isUpdated: false,
};

export default function DocumentsReducer(state = INITIAL_STATE, action) {
    switch (action.type) {
        case FETCH_DOCUMENTS:
            return {...state, documents: action.payload};
        case ADD_CUSTOMER_DOCUMENT:
            return {...state, documents: [action.payload, ...state.documents]};
        case RESET_DOCUMENTS:
            return {...state, documents: INITIAL_STATE.documents};
        case UPLOAD_DOCUMENT:
            let updatedDocuments = cloneDeep(state.documents);
            updatedDocuments.push(action.payload);
            return {...state, documents: updatedDocuments};
        case FETCH_DOCUMENT_CATEGORIES:
            return {...state, categories: [...action.payload]};
        case SET_DOCUMENT_CATEGORY:
            return {...state};
        case DELETE_DOCUMENT:
            return {
                ...state,
                documents: state.documents.filter(document => document.uuid !== action.payload)
            };
        case UPDATE_DOCUMENT:
            return {
                ...state,
                isUpdated: true,
                documents: state.documents.map(document => document.uuid === action.payload.data.uuid ? action.payload.data : document),
            };
        case ERROR_UPDATE_DOCUMENT:
            return {...state, isUpdated: false};
        default:
            return state;
    }
}
