import {Col, Menu, Row} from "antd";
import React from "react";
import {T} from "react-polyglot-hooks";
import {setSidebarOpen} from "../actions";
import {TeamOutlined} from "@ant-design/icons";
import {Link, useLocation} from "react-router-dom";
import {useWindowSize} from "../customHooks/useWindowSize";
import {useDispatch} from "react-redux";
import {getItem, isMobile} from "../sidebarUtils";

const SidebarExtraItems = () => {
    const dispatch = useDispatch();
    const windowSize = useWindowSize();
    const location = useLocation();

    return (<>
        <Row justify={"start"}>
            <Col flex={1}>
                <Menu mode={"vertical"}
                      items={!location.pathname.includes("login") && location.pathname !== "/customers/" ? [
                          getItem(<T phrase="content_value.content_value_customers"/>,
                              "1",
                              <Link to={"/customers/"}>
                                  <TeamOutlined style={{fontSize: "25px"}}/>
                              </Link>,
                              null,
                              () => {
                                  if (windowSize && isMobile(windowSize) === true)
                                      dispatch(setSidebarOpen(false));
                              },
                          )
                      ] : []}
                      style={{backgroundColor: "transparent", border: "none"}}
                />
            </Col>
        </Row>
    </>);
};

export default SidebarExtraItems;