import {APIQuery} from "../utils/apiQuery";
import axios from "axios";
import _ from "lodash";

const ROOT_URL = process.env.REACT_APP_API_URL;

export const GET_SCENARIOS = "GET_SCENARIOS";
export const ERROR_GETTING_SCENARIOS = "ERROR_GETTING_SCENARIOS";
export const ARE_SCENARIOS_EMPTY = "ARE_SCENARIOS_EMPTY";
export const GET_RISK_LOCATIONS = "GET_RISK_LOCATIONS";
export const ERROR_GETTING_RISK_LOCATIONS = "ERROR_GETTING_RISK_LOCATIONS";
export const ARE_RISK_LOCATIONS_EMPTY = "ARE_RISK_LOCATIONS_EMPTY";
export const CLEAR_RISK_LOCATIONS = "CLEAR_RISK_LOCATIONS";
export const CLEAR_SCENARIOS = "CLEAR_SCENARIOS";
export const ADD_SCENARIOS = "ADD_SCENARIOS";
export const ERROR_ADDING_SCENARIOS = "ERROR_ADDING_SCENARIOS";
export const DELETE_SCENARIO = "DELETE_SCENARIO";
export const FETCH_CUSTOMER_SURVEYS = "FETCH_CUSTOMER_SURVEYS";
export const FETCH_CUSTOMER_SURVEY = "FETCH_CUSTOMER_SURVEY";
export const FETCH_CUSTOMER_SURVEYS_FILTERED = "FETCH_CUSTOMER_SURVEYS_FILTERED";
export const UPDATE_CUSTOMER_SURVEY = "UPDATE_CUSTOMER_SURVEY";
export const CREATE_ANSWER = "CREATE_ANSWER";
export const DELETE_ANSWER = "DELETE_ANSWER";
export const FETCH_CUSTOMER_SURVEY_TYPE = "FETCH_CUSTOMER_SURVEY_TYPE";

export const FETCH_SURVEY_RESULTS = "FETCH_SURVEY_RESULTS";
export const UPDATE_SURVEYS_RESULTS = "UPDATE_SURVEYS_RESULTS";
export const DELETE_SURVEYS_RESULTS = "DELETE_SURVEYS_RESULTS";

export const FETCH_SURVEY_RESULT = "FETCH_SURVEY_RESULT";
export const PATCH_SURVEY_RESULT = "PATCH_SURVEY_RESULT";
export const FETCH_ASSETS = "FETCH_ASSETS";

export const FETCH_CONTENT_ROWS_TOTAL_SUM = "FETCH_CONTENT_ROWS_TOTAL_SUM";

export const fetchAssets = (cid, location_uuid) => {
    return (dispatch) => {
        return axios
            .get(`${ROOT_URL}/customers/${cid}/risklocations/${location_uuid}/assets/`)
            .then(
                response => {
                    if (!_.isEmpty(response.data)) {
                        dispatch({
                            type: FETCH_ASSETS,
                            payload: [...response.data],
                        });
                    }
                    return response;
                }
            )
            .catch((error) => {
                console.error("FETCH_ASSETS ", error);
                return error;
            });
    };
};

export const getContentRowsTotalSumByRiskLocation = (customer_uuid, risk_location) => {
    return (dispatch) => {
        return axios
            .get(`${ROOT_URL}/customers/${customer_uuid}/risklocations/${risk_location}/risklocationcontentrowstotalsum/`)
            .then(
                response => {
                    if (!_.isEmpty(response.data)) {
                        dispatch({
                            type: FETCH_CONTENT_ROWS_TOTAL_SUM,
                            payload: [...response.data],
                        });
                    }
                    return response;
                }
            )
            .catch((error) => {
                console.error("FETCH_ASSETS ", error);
                return error;
            });
    };
};

export const getScenarios = (cid, query = null) => {
    const q = APIQuery(query);
    return (dispatch) => {
        axios
            .get(`${ROOT_URL}/customers/${cid}/scenarios/${q}`)
            .then(
                (response) => {
                    if (!_.isEmpty(response.data)) {
                        dispatch({
                            type: GET_SCENARIOS,
                            scenarios: [...response.data],
                        });
                    } else {
                        dispatch({
                            type: ARE_SCENARIOS_EMPTY,
                        });
                    }
                },
                (error) => {
                    dispatch({
                        type: ERROR_GETTING_SCENARIOS,
                    });
                }
            )
            .catch((error) => {
                console.error("GET_SCENARIOS ", error);
            });
    };
};

export const addScenarios = (cid, query = null) => {
    const q = APIQuery(query);
    return (dispatch) => {
        return axios
            .post(`${ROOT_URL}/customers/${cid}/scenarios/${q}`)
            .then(
                (response) => {
                    dispatch({
                        type: ADD_SCENARIOS,
                        scenarios: [...response.data],
                    });
                },
                (error) => {
                    dispatch({
                        type: ERROR_ADDING_SCENARIOS,
                    });
                }
            )
            .catch((error) => {
                console.error("ADD_SCENARIOS ", error);
            });
    };
};
export const clearScenarios = () => {
    return {
        type: CLEAR_SCENARIOS,
    };
};

export const deleteScenario = (cid, scenario) => {
    return (dispatch) => {
        return axios
            .delete(`${ROOT_URL}/customers/${cid}/scenarios/${scenario}`)
            .then(() => {
                dispatch({type: DELETE_SCENARIO});
            })
            .catch((error) => {
                console.error(error);
            });
    };
};

export const getRiskLocations = (cid, query = null) => {
    const q = APIQuery(query);
    return (dispatch) => {
        return axios
            .get(`${ROOT_URL}/customers/${cid}/risklocationspage/${q}`)
            .then(
                (response) => {
                    if (response.data.results.length > 0)
                        dispatch({
                            type: GET_RISK_LOCATIONS,
                            riskLocations: [...response.data.results],
                        });
                    else
                        dispatch({
                            type: ARE_RISK_LOCATIONS_EMPTY,
                        });
                },
                () => {
                    dispatch({
                        type: ERROR_GETTING_RISK_LOCATIONS,
                    });
                }
            )
            .catch((error) => {
                console.error("getRiskLocations ", error);
            });
    };
};

export const clearRiskLocations = () => {
    return {
        type: CLEAR_RISK_LOCATIONS,
    };
};

/****************************************** Surveys ************************************************ */
export function fetchSurveyType(query = null) {
    const q = APIQuery(query);
    return (dispatch) => {
        return axios
            .get(`${ROOT_URL}/surveytypeorganization/${q}`)
            .then((res) => {
                dispatch({
                    type: FETCH_CUSTOMER_SURVEY_TYPE,
                    payload: res.data,
                });
                return res;
            })
            .catch((error) => {
                console.log(error);
            });
    };
}

export function fetchCustomerSurveys(customerId, query = null) {
    const q = APIQuery(query);
    return (dispatch) => {
        return axios
            .get(`${ROOT_URL}/customers/${customerId}/surveys/${q}`)
            .then((res) => {
                console.log(res, "ddd");
                dispatch({
                    type: FETCH_CUSTOMER_SURVEYS,
                    payload: res.data,
                });
                return res;
            })
            .catch((error) => {
                console.log(error);
            });
    };
}

export function fetchCustomerSurveysFiltered(customerId, query = null) {
    const q = APIQuery(query);
    return (dispatch) => {
        return axios
            .get(`${ROOT_URL}/customers/${customerId}/surveysfiltered/${q}`)
            .then((res) => {
                dispatch({
                    type: FETCH_CUSTOMER_SURVEYS_FILTERED,
                    payload: res.data,
                });
                return res;
            })
            .catch((error) => {
                console.log(error);
            });
    };
}

export function fetchCustomerSurvey(customerId, surveyId, query = null) {
    const q = APIQuery(query);
    return (dispatch) => {
        return axios
            .get(`${ROOT_URL}/customers/${customerId}/surveys/${surveyId}/${q}`)
            .then((res) => {
                dispatch({
                    type: FETCH_CUSTOMER_SURVEY,
                    payload: res.data,
                });
                return res;
            })
            .catch((error) => {
                console.log(error);
            });
    };
}

export function updateCustomerSurvey(customerId, surveyId, data) {
    return (dispatch) => {
        return axios
            .put(`${ROOT_URL}/customers/${customerId}/surveys/${surveyId}/`, data)
            .then((res) => {
                dispatch({
                    type: UPDATE_CUSTOMER_SURVEY,
                    payload: res.data,
                });
                return res;
            }, error => error)
            .catch((error) => {
                console.log(error);
                return error;
            });
    };
}


export function fetchSurveyResults(customerId) {
    return (dispatch) => {
        return axios
            .get(`${ROOT_URL}/customers/${customerId}/surveyresults/`)
            .then((res) => {
                dispatch({
                    type: FETCH_SURVEY_RESULTS,
                    payload: res.data,
                });
                return res.data;
            })
            .catch((error) => {
                console.log(error);
            });
    };
}

export function fetchSurveyResult(customerId, uuid) {
    return (dispatch) => {
        return axios
            .get(`${ROOT_URL}/customers/${customerId}/surveyresults/${uuid}/`)
            .then((res) => {
                dispatch({
                    type: FETCH_SURVEY_RESULT,
                    payload: res.data,
                });
                return res.data;
            })
            .catch((error) => {
                console.log(error);
            });
    };
}

export function updateSurveyResults(customerId, data) {
    return (dispatch) => {
        return axios
            .post(`${ROOT_URL}/customers/${customerId}/surveyresults/`, data)
            .then((res) => {
                dispatch({
                    type: UPDATE_SURVEYS_RESULTS,
                    payload: res.data,
                });
                return res.data;
            })
            .catch((error) => {
                console.log(error);
            });
    };
}

export function patchSurveyResult(customerId, uuid, data) {
    return (dispatch) => {
        //dispatch({type: PATCH_SURVEY_RESULT, uuid: uuid, payload: data,});
        return axios
            .patch(`${ROOT_URL}/customers/${customerId}/surveyresults/${uuid}/`, data)
            .then((res) => {
                dispatch({
                    type: PATCH_SURVEY_RESULT,
                    uuid: uuid,
                    payload: res.data,
                });
                return res;
            })
            .catch((error) => {
                console.log(error);
                return error
            });
    };
}

export const deleteSurveyResults = (cid, survey) => {
    return (dispatch) => {
        return axios
            .delete(`${ROOT_URL}/customers/${cid}/surveyresults/${survey}/`)
            .then(dispatch({type: DELETE_SURVEYS_RESULTS, payload: survey}))
            .catch((error) => {
                console.error(error);
            });
    };
};

export function createAnswer(customerId, data) {
    return (dispatch) => {
        return axios
            .post(`${ROOT_URL}/customers/${customerId}/createanswer/`, data)
            .then((res) => {
                dispatch({
                    type: CREATE_ANSWER,
                    payload: res.data.answer_uuid,
                });
                dispatch(
                    fetchCustomerSurveysFiltered(customerId, {
                        type_survey: "UNDERWRITING",
                    })
                );
                return res.data.answer_uuid;
            })
            .catch((error) => {
                console.log(error);
            });
    };
}

export function deleteAnswer(customerId, surveyId) {
    return (dispatch) => {
        return axios
            .delete(`${ROOT_URL}/customers/${customerId}/surveys/${surveyId}/`)
            .then((res) => {
                dispatch({
                    type: DELETE_ANSWER,
                    payload: res.data,
                });
                dispatch(
                    fetchCustomerSurveysFiltered(customerId, {
                        type_survey: "UNDERWRITING",
                    })
                );
                return res;
            })
            .catch((error) => {
                console.log(error);
            });
    };
}

export function deleteDocument(cid, documentId) {
    return (dispatch) => {
        return axios
            .delete(`${ROOT_URL}/customers/${cid}/documents/${documentId}/`)
            .then((res) => {
                return {type: "SUCCESS", data: res};
            })
            .catch((error) => {
                return {type: "ERROR", data: error};
            });

    };
}

export function documentUpload(cid, data) {
    return (dispatch) => {
        return axios
            .post(`${ROOT_URL}/customers/${cid}/documents/`, data, {headers: {"content-type": "multipart/form-data"}})
            .then((res) => {
                return {status: res.status, data: res};
            })
            .catch((error) => {
                return {status: error.status};
            });
    };
}
