import {useEffect, useRef} from "react";
import {useConnectionCheck} from "./useConnectionCheck";

export const useInterval = (callback, delay = null) => {

  const savedCallback = useRef();
  const isOnline = useConnectionCheck();

  useEffect(()=>{
    savedCallback.current = callback;
  }, [callback]);

  useEffect(()=> {
    function tick() {
      if (isOnline)
        savedCallback.current();
    }
    if (delay !== null && isOnline) {
      const id = setInterval(tick, delay);
      return ()=> clearInterval(id);
    }
  }, [callback, delay, isOnline]);

};
