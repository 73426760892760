import {
    ADD_ASSET_INTENDED_USE,
    CLEAR_ASSET_UUID_FROM_MENU,
    CLEAR_ASSETS,
    CLEAR_GRAPHS,
    CLEAR_RISK_LOCATIONS,
    EMPTY_ASSET,
    EMPTY_GRAPHS,
    ERROR_GET_ASSET,
    ERROR_GET_GRAPHS, FETCH_ASSET_DETAILS, FETCH_ASSET_INTENDED_USES, FETCH_ASSET_VALUES, FETCH_INTENDED_USE_TYPES,
    GET_ASSET,
    GET_GRAPHS, PATCH_ASSET_INTENDED_USE, REMOVE_ASSET_INTENDED_USE,
    SET_ASSET_UUID_FROM_MENU,
} from "./actions";

const INITIAL_STATE = {
    graphs: [],
    errorGraphs: false,
    emptyGraphs: false,
    selectedAsset: {},
    errorGetSelectedAsset: false,
    emptyAsset: false,
    assetUuidFromMenu: null,
    intendedUsesTypes: [],
    intendedUses: [],
    risklocationAssetValues: [],
    assetDetails: [],
};

export default function ConstructionValueReducer(
    state = INITIAL_STATE,
    action
) {
    switch (action.type) {
        case FETCH_ASSET_DETAILS:
            return {...state, assetDetails: action.payload};
        case FETCH_ASSET_VALUES:
            return {...state, risklocationAssetValues: action.payload};
        case FETCH_INTENDED_USE_TYPES:
            return {...state, intendedUsesTypes: action.payload};
        case FETCH_ASSET_INTENDED_USES:
            return {...state, intendedUses: action.payload};
        case ADD_ASSET_INTENDED_USE:
            return {...state, intendedUses: [...state.intendedUses, action.payload]};
        case REMOVE_ASSET_INTENDED_USE:
            return {
                ...state,
                intendedUses: state.intendedUses.filter(intended_use => intended_use.uuid !== action.payload)
            };
        case PATCH_ASSET_INTENDED_USE:
            return {
                ...state,
                intendedUses: state.intendedUses.map(intended_use => intended_use.uuid === action.payload.uuid ? action.payload.patch : intended_use)
            };
        case SET_ASSET_UUID_FROM_MENU:
            return {
                ...state,
                assetUuidFromMenu: action.payload,
            };
        case GET_GRAPHS:
            return {
                ...state,
                graphs: [...action.payload],
                errorGraphs: false,
                emptyGraphs: false,
            };
        case EMPTY_GRAPHS:
            return {
                ...state,
                emptyGraphs: true,
                errorGraphs: false,
            };
        case ERROR_GET_GRAPHS:
            return {
                ...state,
                errorGraphs: true,
                emptyGraphs: false,
            };

        case GET_ASSET:
            return {
                ...state,
                selectedAsset: {...action.payload},
                errorGetSelectedAsset: false,
                emptyAsset: false,
            };
        case ERROR_GET_ASSET:
            return {
                ...state,
                errorGetSelectedAsset: true,
                emptyAsset: false,
            };
        case EMPTY_ASSET:
            return {
                ...state,
                errorGetSelectedAsset: false,
                emptyAsset: true,
            };
        case CLEAR_ASSETS:
            return {
                ...state,
                assetArray: [],
            };
        case CLEAR_RISK_LOCATIONS:
            return {
                ...state,
                riskLocationsArray: [],
            };
        case CLEAR_ASSET_UUID_FROM_MENU:
            return {
                ...state,
                assetUuidFromMenu: null,
            };
        case CLEAR_GRAPHS:
            return {
                ...state,
                graphs: [],
            };
        default:
            return state;
    }
}
