import {T, useT} from "react-polyglot-hooks";
import {userUnauthorized} from "../actions";
import {Button, Col, Modal, Row, Typography} from "antd";
import React, {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";

export const UserUnauthorizedModal = (props) => {
    const dispatch = useDispatch();
    const {Text} = Typography;
    const getPhrase = useT();

    const isUserUnauthorized = useSelector(store => store.login.isUserUnauthorized);

    const [isModalOpen, setIsModalOpen] = useState(false);

    useEffect(() => {
        if (isUserUnauthorized === true) {
            setIsModalOpen(true);
        }
    }, [isUserUnauthorized]);

    return (
        <Modal open={isModalOpen}
               destroyOnClose={true}
               centered={true}
               closable={false}
               title={<T phrase={"general.user_unauthorized"}/>}
               footer={<Button danger={true}
                               onClick={() => {
                                   setIsModalOpen(false);
                                   dispatch(userUnauthorized(false));
                               }}>Logout</Button>}
        >
            <Row>
                <Col>
                    <Text>{`${getPhrase("general.user_unauthorized_description")}`}</Text>
                </Col>
            </Row>
        </Modal>
    );
};