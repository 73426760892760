/*
import {
    FETCH_GROSS_PROFIT_DATA, 
    UPDATE_GROSS_PROFIT_DATA,
    ERROR_GROSS_PROFIT_DATA,
    IS_GROSS_PROFIT_EMPTY
  } from "./actions";

  const INITIAL_STATE = {
    grossProfitData: null,
    isGrossProfitEmpty: false,
    errorGrossProfit: false, 
 }
  export default function GrossProfitReducer(state = INITIAL_STATE, action) {
    switch (action.type) {
        case FETCH_GROSS_PROFIT_DATA:
            return {
                ...state,
                grossProfitData: {...action.payload},
                isGrossProfitEmpty:false,
                errorGrossProfit: false,
            };
        
        case UPDATE_GROSS_PROFIT_DATA:
          //  return Object.assign( {...state}, {grossProfitData: action.payload});
          return {
            ...state,
            grossProfitData: {...action.payload},
            isGrossProfitEmpty:false,
            errorGrossProfit: false,
        };
        case IS_GROSS_PROFIT_EMPTY:
                return {
                    ...state,
                    grossProfitData: {},
                    isGrossProfitEmpty:true,
                    errorGrossProfit: false,
                };

        case ERROR_GROSS_PROFIT_DATA:
                return {
                    ...state,
                    grossProfitData: {},
                    isGrossProfitEmpty: false,
                    errorGrossProfit: true,
                };
        
        default:
            return state;
    }
  }

  */
import {
    CLEAR_GROSS_PROFIT_DATA,
    ERROR_GROSS_PROFIT_DATA,
    FETCH_GROSS_PROFIT_DATA,
    IS_GROSS_PROFIT_EMPTY,
    UPDATE_GROSS_PROFIT_DATA
} from "./actions";

const INITIAL_STATE = {
    grossProfitData: undefined,
    isGrossProfitEmpty: undefined,
    errorGrossProfit: undefined,
 }
  export default function GrossProfitReducer(state = INITIAL_STATE, action) {
    switch (action.type) {
        case FETCH_GROSS_PROFIT_DATA:
            return {
                ...state,
                grossProfitData: {...action.payload},
                isGrossProfitEmpty:false,
                errorGrossProfit: false,
            };
        
        case UPDATE_GROSS_PROFIT_DATA:
          //  return Object.assign( {...state}, {grossProfitData: action.payload});
          return {
            ...state,
            grossProfitData: {...action.payload},
            isGrossProfitEmpty:false,
            errorGrossProfit: false,
        };
        case IS_GROSS_PROFIT_EMPTY:
                return {
                    ...state,
                    grossProfitData: {},
                    isGrossProfitEmpty:true,
                    errorGrossProfit: false,
                };

        case ERROR_GROSS_PROFIT_DATA:
                return {
                    ...state,
                    //grossProfitData: {},
                    isGrossProfitEmpty: false,
                    errorGrossProfit: true,
                };
      case CLEAR_GROSS_PROFIT_DATA:
        return {
          ...state,
          grossProfitData: undefined,
          isGrossProfitEmpty: undefined,
          errorGrossProfit: undefined,
        };
        default:
            return state;
    }
  }

  
  