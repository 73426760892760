import {getReportList} from "./actions";
import {useDispatch, useSelector} from "react-redux";
import {useEffect, useState} from "react";
import {Button, notification, Space} from "antd";
import {useT} from "react-polyglot-hooks";
import {useHistory} from "react-router-dom";
import {useInterval} from "./customHooks/useInterval";

export function ReportPooling() {
  const dispatch = useDispatch();
  const [currentLatestReport, setCurrentLatestReport] = useState(undefined)
  const actualLatestReport = useSelector(store => store.common.latestReport)
  const getPhrase = useT()
  const history = useHistory();

  useEffect(() => {
    if (currentLatestReport !== actualLatestReport) {
      setCurrentLatestReport(actualLatestReport)
    }
  }, [actualLatestReport, currentLatestReport])


  useEffect(() => {
    if (currentLatestReport && actualLatestReport && new Date(actualLatestReport.date_creation).getTime() > new Date(currentLatestReport.date_creation).getTime()) {
      notification.open({
        key: actualLatestReport.uuid,
        message: getPhrase("report.notification_title"),
        description: <>
          <Space direction={"vertical"}>
            {getPhrase("report.notification_description", {customer: actualLatestReport.customer.name})}
            <Button type={"link"} style={{margin: 0, padding: 0}} onClick={() => {
              history.push(`/customers/${actualLatestReport.customer.uuid}`)
              notification.destroy(actualLatestReport.uuid)
            }}>{getPhrase("report.go_to_customer", {customer: actualLatestReport.customer.name} )}</Button>
          </Space>
        </>,
        duration: null
      });
    }
  }, [actualLatestReport, currentLatestReport, dispatch, getPhrase, history])


  useInterval(async () => {
    dispatch(getReportList())
  }, 10000);


  return <></>
}