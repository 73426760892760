import {
    LOGIN_USER_FAILURE,
    LOGIN_USER_SUCCESS,
    SERVER_DOWN,
    SET_TOKEN,
    START_LOGIN,
    TOKEN_EXPIRED,
    USER_UNAUTHORIZED
} from "./actions";

const INITIAL_STATE = {
  token: null,
  isLoading: false,
  decodedUser: {},
  isAuthenticating: false,
  isAuthenticated: false,
  errorMessageLogin: false,
  errorServerDown: false,
  hasTokenExpired: false,
  isUserUnauthorized: false,
};

export default function LoginReducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    case START_LOGIN:
      return {
        ...state,
        isLoading: true,
        hasTokenExpired: false
      };
    case SET_TOKEN:
      return {
        ...state,
        token: action.token,
        isLoading: false,
      };
    case LOGIN_USER_SUCCESS:
      return {
        ...state,
        token: action.token,
        isAuthenticating: false,
        isAuthenticated: true,
        errorMessageLogin: null,
        errorServerDown: false,
        decodedUser: {...action.decodedUser},
        isLoading: false,
      };
    case LOGIN_USER_FAILURE:
      return {
        ...state,
        isAuthenticating: false,
        isAuthenticated: false,
        errorMessageLogin: action.errorMessageLogin,
        errorServerDown: false,
      };
    case SERVER_DOWN:
      return {
        ...state,
        errorServerDown: true,
      };
    case TOKEN_EXPIRED:
      return {...state, hasTokenExpired: action.payload};
    case USER_UNAUTHORIZED:
      return {...state, isUserUnauthorized: action.payload};
    default:
      return state;
  }
}
