import {actcode} from "../actions/actioncodes.jsx";
import {removeByKeys} from "../lib/util.jsx";

const initialState = {
  isFetching: false,
  didInvalidate: true,
  mapInteraction: { mapAction: "" },
  selectedNode: "",
  selectedProcess: "",
  selectedAsset: "",
  selectedScenario: "",
  selectedRisklocation: "",
  selectedUuid: "",
  selectedType: "",
  streetViewVisible: false,
  asset_value_details: {},
  asset_value_options: {},

  customer: {},

  /*
  Processes: Object
   key: process uuid
  */
  processes: {},
  /*
  Assets: Object
   key: asset uuid
  */
  assets: {},
  assetsArray: [],
  assetsFetching: false,
  assetsFetched: false,
  scenarios: {},
  scenariosArray: [],
  risklocations: {},
  riskLocationsArray: [],
  nodes: {},
  edges: {},
  risklocationsFetching: false,
  risklocationsFetched: false,
  scenariosFetching: false,
  scenariosFetched: false,
  scenarioTypesFetched: true,
  nodeClassesFetching: false,
  nodeClassesFetched: false,
};

function processStore(state = initialState, action) {
  switch (action.type) {
    case actcode.REDUXINIT:
    case actcode.MAP_EDITOR_CLEAR:
      // INIT della applicazione
      return Object.assign({}, initialState);

    case actcode.MAP_EDITOR_STREETVIEW_OPEN:
      return Object.assign({}, state, {
        streetViewVisible: true,
      });

    case actcode.MAP_EDITOR_STREETVIEW_CLOSE:
      return Object.assign({}, state, {
        streetViewVisible: false,
      });

    case actcode.ASSET_LIST_REQUESTED:
      return Object.assign({}, state, {
        assetsFetching: true,
      });

    case actcode.ASSET_LIST_RECEIVED:
      let assetsDiff = {};
      action.asset_set.forEach(function (item) {
        assetsDiff[item.uuid] = item;
      });

      return Object.assign({}, state, {
        assets: assetsDiff,
        assetsFetching: false,
        assetsFetched: true,
        assetsArray: [...action.asset_set],
      });

    case actcode.CUSTOMER_RECEIVED:
      //let cr_assets_array = action.customer.asset_set;
      let cr_customer = action.customer;
      delete cr_customer.asset_set;

      return Object.assign({}, state, {
        customer: cr_customer,
      });

    case actcode.ASSET_RECEIVED:
    case actcode.ASSET_CREATED:
      let assetList = Object.assign({}, state.assets);
      assetList[action.assetUuid] = action.data;

      return Object.assign({}, state, {
        assets: assetList,
      });

    case actcode.ASSET_DELETED:
      let ad_assetList = removeByKeys(state.assets, action.assetUuid);

      let ad_state = Object.assign({}, state, {
        assets: ad_assetList,
      });
      if (state.selectedUuid === action.assetUuid)
        // clear nodeform
        ad_state["selectedUuid"] = "";

      return ad_state;

    case actcode.ASSET_SELECT:
      return Object.assign({}, state, {
        selectedAsset: action.assetUuid,
        selectedUuid: action.assetUuid,
        selectedType: "asset",
      });

    case actcode.ASSET_DESELECT:
      return Object.assign({}, state, {
        selectedAsset: "",
        selectedUuid: "",
        selectedType: "",
      });

    case actcode.ASSET_VALUE_OPTIONS_RECEIVED:
      return Object.assign({}, state, {
        asset_value_options: action.data,
      });

    case actcode.ASSET_VALUE_DETAILS_RECEIVED:
    case actcode.ASSET_VALUE_DETAILS_UPDATED:
      return Object.assign({}, state, {
        asset_value_details: action.data,
      });

    case actcode.PROCESS_GRAPH_REQUEST:
      return Object.assign({}, state, {
        isFetching: true,
        didInvalidate: false,
      });

    case actcode.PROCESS_GRAPH_RECEIVED:
      // Extract nodes
      let nodes = {};
      let process_nodes_refs = [];
      for (let i = 0; i < action.processData.node_set.length; i++) {
        let n = action.processData.node_set[i];
        n.processUuid = action.processUuid;

        // TODO: remove this part and get the right coordinates.
        // Trying to project x,y coordinates in lat,lng, just for devel purpose
        //n.coordinate = parseAreaCoords(n.coordinate);

        // NODE COORDINATE CHECK
        // set x and y to be lng/lat
        /*if( (n.x < -180.0 || n.x > 180.0) || (n.y < -180.0 || n.y > 180.0)
          || ( Math.abs( n.x - n.coordinate[1]) + Math.abs( n.y - n.coordinate[0]) > 0.04 ) ) {
          n.x = n.coordinate[1];
          n.y = n.coordinate[0];
        }*/
        //
        while (n.x < -180) n.x = n.x + 180;
        while (n.x > 180) n.x = n.x - 180;

        nodes[n.uuid] = n;
        process_nodes_refs.push(n.uuid);
      }

      let edges = {};
      let process_edges_refs = [];
      for (let i = 0; i < action.processData.edge_set.length; i++) {
        let e = action.processData.edge_set[i];
        e.processUuid = action.processUuid;
        edges[e.uuid] = e;
        process_edges_refs.push(e.uuid);
      }

      let processUuid = action.processUuid;
      let processGraph = {
        nodes: process_nodes_refs, //action.processData.node_set,
        edges: process_edges_refs, //action.processData.edge_set,
        customerUuid: action.processData.customer_uuid,
        name: action.processData.name,
        description: action.processData.description,
      };

      // put received graph in processes dictionary
      let processesDiff = {};
      processesDiff[processUuid] = processGraph;
      let processes = Object.assign({}, state.processes, processesDiff);

      /*let assetsDiff = {};
      action.processData.asset_set.forEach( function(item) {
        assetsDiff[item.uuid] = item;
      });
      let assets = Object.assign({}, state.assets, assetsDiff);*/

      return Object.assign({}, state, {
        isFetching: false,
        didInvalidate: false,
        processes: processes,
        nodes: nodes,
        edges: edges,
        customer: action.customer,
        /*assets: assets*/
      });
    // END PROCESS_GRAPH_RECEIVED

    case actcode.NODECLASSES_REQUESTED:
      return Object.assign({}, state, {
        nodeClassesFetching: true,
      });

    case actcode.NODECLASSES_RECEIVED:
      return Object.assign({}, state, {
        nodeClassesFetching: false,
        nodeClassesFetched: true,
      });

    /*********************************/
    /* Graph nodes and edges actions */
    case actcode.PROCESS_NODE_SELECT:
      return Object.assign({}, state, {
        selectedNode: action.nodeUuid,
        selectedProcess: action.processUuid,
        selectedUuid: action.nodeUuid,
        selectedType: "node",
      });

    case actcode.PROCESS_NODE_DESELECT:
      return Object.assign({}, state, {
        selectedNode: "",
        selectedUuid: "",
        selectedType: "",
      });

    case actcode.PROCESS_GRAPH_NODEDRAG:
    case actcode.PROCESS_NODE_ADDING:
    case actcode.PROCESS_NODE_CLONING:
    case actcode.PROCESS_EDGE_ADDING:
    case actcode.PROCESS_EDGE_MOVING:
    case actcode.ASSET_ADDING:
    case actcode.ASSET_EDITING:
    case actcode.ASSET_PICKING:
    case actcode.SCENARIO_ADDING:
    case actcode.SCENARIO_EDITING:
    case actcode.RISKLOCATION_ADDING:
    case actcode.RISKLOCATION_EDITING:
    case actcode.FEATURE_DELETING:
    case actcode.PROCESS_ACTION_CLEAR:
      return Object.assign({}, state, {
        mapInteraction: action,
      });

    case actcode.PROCESS_NODE_REQUESTED:
      // TODO: set fetching flag?
      return state;

    case actcode.PROCESS_NODE_RECEIVED:
    case actcode.UPDATED_NODEFORM:
      let pnr_nodesDiff = {};
      //action.data.coordinate = parseAreaCoords(action.data.coordinate);

      // NODE COORDINATE CHECK
      // set x and y to be lng/lat
      /*if( (action.data.x < -180.0 || action.data.x > 180.0) || (action.data.y < -180.0 || action.data.y > 180.0)
        || ( Math.abs( action.data.x - action.data.coordinate[1]) + Math.abs( action.data.y - action.data.coordinate[0]) > 0.04 ) ) {
        action.data.x = action.data.coordinate[1];
        action.data.y = action.data.coordinate[0];
      }*/

      if (action.data.hasOwnProperty("x")) {
        while (action.data.x < -180) action.data.x = action.data.x + 180;
        while (action.data.x > 180) action.data.x = action.data.x - 180;
      }
      pnr_nodesDiff[action.nodeUuid] = action.data;
      pnr_nodesDiff[action.nodeUuid].processUuid = action.processUuid
        ? action.processUuid
        : state.nodes[action.nodeUuid].processUuid;
      let pnr_nodes = Object.assign({}, state.nodes, pnr_nodesDiff);

      return Object.assign({}, state, {
        nodes: pnr_nodes,
      });

    case actcode.PROCESS_NODE_CREATED:
      let pnc_proc = state.processes[action.processUuid];

      let pnc_proc_nodes = JSON.parse(JSON.stringify(pnc_proc.nodes));
      // Add new nodeUuid in process nodes array
      if (pnc_proc_nodes.indexOf(action.nodeUuid) === -1)
        pnc_proc_nodes.push(action.nodeUuid);

      // create new state process object
      let pnc_processDiff = { nodes: pnc_proc_nodes };
      let pnc_process = Object.assign({}, pnc_proc, pnc_processDiff);

      // create new state processES object
      let pnc_processesDiff = {};
      pnc_processesDiff[action.processUuid] = pnc_process;
      let pnc_processes = Object.assign({}, state.processes, pnc_processesDiff);

      // create new state nodES object

      // TODO: remove this part and get the right coordinates.
      // Trying to project x,y coordinates in lat,lng, just for devel purpose
      action.data.location = [action.data.y, action.data.x];
      //action.data.coordinate = parseAreaCoords(action.data.coordinate);

      let pnc_nodesDiff = {};
      pnc_nodesDiff[action.nodeUuid] = action.data;
      pnc_nodesDiff[action.nodeUuid].processUuid = action.processUuid;
      let pnc_nodes = Object.assign({}, state.nodes, pnc_nodesDiff);

      return Object.assign({}, state, {
        nodes: pnc_nodes,
        processes: pnc_processes,
      });

    case actcode.PROCESS_NODE_DELETED:
      let pnd_proc = state.processes[action.processUuid];

      // remove deleted edges which where connected to the node
      let pnd_proc_edges = [];
      let pnd_edges_to_delete = [];
      for (let i = 0; i < pnd_proc.edges.length; i++) {
        let e = state.edges[pnd_proc.edges[i]];
        if (action.nodeUuid !== e.node_from && action.nodeUuid !== e.node_to)
          pnd_proc_edges.push(pnd_proc.edges[i]);
        else pnd_edges_to_delete.push(pnd_proc.edges[i]);
      }

      // remove deleted nodeUuid from process node array
      let pnd_proc_nodes = pnd_proc.nodes.filter((item) => {
        return item !== action.nodeUuid;
      });

      // create new state process object
      let pnd_processDiff = { nodes: pnd_proc_nodes, edges: pnd_proc_edges };
      let pnd_process = Object.assign({}, pnd_proc, pnd_processDiff);

      // create new state processES object
      let pnd_processesDiff = {};
      pnd_processesDiff[action.processUuid] = pnd_process;
      let pnd_processes = Object.assign({}, state.processes, pnd_processesDiff);

      // create new nodES object
      let pnd_nodes = removeByKeys(state.nodes, action.nodeUuid);
      let pnd_edges = removeByKeys(state.edges, pnd_edges_to_delete);

      let pnd_stateDiff = {
        nodes: pnd_nodes,
        edges: pnd_edges,
        processes: pnd_processes,
        selectedNode: "",
      };
      if (state.selectedUuid === action.nodeUuid)
        // clear nodeform
        pnd_stateDiff["selectedUuid"] = "";

      return Object.assign({}, state, pnd_stateDiff);

    case actcode.PROCESS_EDGE_CREATED:
      let pec_proc = state.processes[action.processUuid];

      let pec_proc_edges = JSON.parse(JSON.stringify(pec_proc.edges));
      // Add new edgeUuid in process edges array
      if (pec_proc_edges.indexOf(action.edgeUuid) === -1)
        pec_proc_edges.push(action.edgeUuid);

      // create new state process object
      let pec_processDiff = { edges: pec_proc_edges };
      let pec_process = Object.assign({}, pec_proc, pec_processDiff);

      // create new state processES object
      let pec_processesDiff = {};
      pec_processesDiff[action.processUuid] = pec_process;
      let pec_processes = Object.assign({}, state.processes, pec_processesDiff);

      // create new state nodES object
      let pec_edgesDiff = {};
      pec_edgesDiff[action.edgeUuid] = action.data;
      pec_edgesDiff[action.edgeUuid].processUuid = action.processUuid;
      let pec_edges = Object.assign({}, state.edges, pec_edgesDiff);

      return Object.assign({}, state, {
        edges: pec_edges,
        processes: pec_processes,
      });

    case actcode.PROCESS_EDGE_UPDATED:
      let peu_edgesDiff = {};
      peu_edgesDiff[action.data.uuid] = action.data;
      peu_edgesDiff[action.data.uuid].processUuid = action.processUuid
        ? action.processUuid
        : state.edges[action.data.uuid].processUuid;
      let peu_edges = Object.assign({}, state.edges, peu_edgesDiff);

      return Object.assign({}, state, {
        edges: peu_edges,
      });

    case actcode.PROCESS_EDGE_DELETED:
      let ped_proc = state.processes[action.processUuid];

      // remove deleted edgeUuid from process edges array
      let ped_proc_edges = ped_proc.edges.filter((item) => {
        return item !== action.edgeUuid;
      });

      // create new state process object
      let ped_processDiff = { edges: ped_proc_edges };
      let ped_process = Object.assign({}, ped_proc, ped_processDiff);

      // create new state processES object
      let ped_processesDiff = {};
      ped_processesDiff[action.processUuid] = ped_process;
      let ped_processes = Object.assign({}, state.processes, ped_processesDiff);

      // create new edgES object
      let ped_edges = removeByKeys(state.edges, action.edgeUuid);

      return Object.assign({}, state, {
        edges: ped_edges,
        processes: ped_processes,
      });

    /* SCENARIOS */
    case actcode.SCENARIO_TYPES_RECEIVED:
      return Object.assign({}, state, {
        scenarioTypesFetched: true,
        scenarioTypes: action.scenario_types,
      });

    case actcode.SCENARIO_LIST_REQUESTED:
      return Object.assign({}, state, {
        scenariosFetching: true,
      });

    case actcode.SCENARIO_LIST_RECEIVED:
      let scenarios = {};
      for (let i = 0; i < action.scenario_set.length; i++) {
        let s = action.scenario_set[i];
        if (s.scenario_type !== "NONE") scenarios[s.uuid] = s;
      }

      return Object.assign({}, state, {
        scenarios: scenarios,
        scenariosArray: action.scenario_set,
        scenariosFetching: false,
        scenariosFetched: true,
      });

    case actcode.SCENARIO_RECEIVED:
    case actcode.SCENARIO_CREATED:
      let scenarioList = Object.assign({}, state.scenarios);
      scenarioList[action.scenarioUuid] = action.data;

      return Object.assign({}, state, {
        scenarios: scenarioList,
      });

    case actcode.SCENARIO_DELETED:
      let sd_assetList = removeByKeys(state.scenarios, action.scenarioUuid);

      let sd_state = Object.assign({}, state, {
        scenarios: sd_assetList,
      });
      if (state.selectedUuid === action.scenarioUuid)
        // clear nodeform
        sd_state["selectedUuid"] = "";

      return sd_state;

    case actcode.SCENARIO_SELECT:
      return Object.assign({}, state, {
        selectedScenario: action.scenarioUuid,
        selectedUuid: action.scenarioUuid,
        selectedType: "scenario",
      });

    case actcode.SCENARIO_DESELECT:
      return Object.assign({}, state, {
        selectedScenario: "",
        selectedUuid: "",
        selectedType: "",
      });

    /* RISK LOCATIONS */
    case actcode.RISKLOCATION_LIST_REQUESTED:
      return Object.assign({}, state, {
        risklocationsFetching: true,
      });

    case actcode.RISKLOCATION_LIST_RECEIVED:
      return Object.assign({}, state, {
        risklocations: action.risklocation_set,
        insuredsums: action.insuredsum_set,
        riskLocationsArray: action.riskLocationsArray,
        risklocationsFetching: false,
        risklocationsFetched: true,
      });

    case actcode.RISKLOCATION_RECEIVED:
    case actcode.RISKLOCATION_CREATED:
      //let newRisklocation = {};

      let risklocationDiff = {};
      risklocationDiff[action.risklocationUuid] = action.data;
      let risklocationsList = Object.assign(
        {},
        state.risklocations,
        risklocationDiff
      );

      //risklocationsList[action.risklocationUuid] = action.data;

      let rl_receivedDiff = {
        risklocations: risklocationsList,
      };
      if (action.hasOwnProperty("insuredsums"))
        rl_receivedDiff["insuredsums"] = action.insuredsums;

      return Object.assign({}, state, rl_receivedDiff);

    case actcode.RISKLOCATION_DELETED:
      let rd_risklocationsList = removeByKeys(
        state.risklocations,
        action.risklocationUuid
      );

      let rd_state = Object.assign({}, state, {
        risklocations: rd_risklocationsList,
      });
      if (state.selectedUuid === action.risklocationUuid)
        // clear nodeform
        rd_state["selectedUuid"] = "";

      return rd_state;

    case actcode.RISKLOCATION_SELECT:
      return Object.assign({}, state, {
        selectedRisklocation: action.risklocationUuid,
        selectedUuid: action.risklocationUuid,
        selectedType: "risklocation",
      });

    case actcode.RISKLOCATION_DESELECT:
      return Object.assign({}, state, {
        selectedRisklocation: "",
        selectedUuid: "",
        selectedType: "",
      });

    default:
      return state;
  }
}

export default processStore;

