import axios from "axios";


export const LOAD_GENERATE_REPORT = "LOAD_GENERATE_REPORT";
export const GENERATE_REPORT = "GENERATE_REPORT";
export const ERROR_GENERATE_REPORT = "ERROR_GENERATE_REPORT";
export const GET_REPORT_LIST = "GET_REPORT_LIST";
export const REPORT_DOWNLOADED = "REPORT_DOWNLOADED";
export const CLEAR_REPORTS = "CLEAR_REPORTS";

const ROOT_URL = process.env.REACT_APP_API_URL;

export const generateReport = (customer_uuid, with_answers, with_gross_profit, with_weather) => {
  return (dispatch) => {
    dispatch({ type: LOAD_GENERATE_REPORT });
    return axios
        .get(
            `${ROOT_URL}/customers/${customer_uuid}/printsurveyresults/`,
            {params: {with_answers, with_gross_profit, with_weather}}
        )
        .then(
            (res) => {
              dispatch({ type: GENERATE_REPORT });
              return res
            },
            (error) => {
              dispatch({ type: ERROR_GENERATE_REPORT });
              return error;
            }
        )
        .catch((error) => console.error("Report generation ", error));
  };
};

export const getCustomerReportList = (customer_uuid, page) => {
  return (dispatch) => {
    return axios
        .get(
            `${ROOT_URL}/customers/${customer_uuid}/reports/`,
            {params: {page}}
        )
        .then(response => {
          if (page) {
            dispatch({type: GET_REPORT_LIST, payload: {apiData: response.data, page: page}});
          } else {
            dispatch({type: GET_REPORT_LIST, payload: {apiData: response.data}});
          }
        })
        .catch((error) => console.error("Report generation ", error));
  };
};

export function downloadReport(cid, rid) {
  return (dispatch) => {
    return axios
        .get(rid, {
          responseType: "arraybuffer",
        })
        .then((res) => {
          if (res.status === 200) {
            const blob = new Blob([res.data], {
              type: res.headers["content-type"],
            });
            let n = window.open(URL.createObjectURL(blob));
            // if it"s active pop-up blocker or adblock it opens the window in the same page
            if (n === null) {
              window.open(URL.createObjectURL(blob), "_self");
            }
            window.setTimeout(() => {
              if (
                  res.headers["content-type"] === "application/pdf" &&
                  (!n || n.closed || typeof n.closed === "undefined")
              ) {
                window.open(URL.createObjectURL(blob), "_self");
              }
            }, 1000);
          }
        })
        .catch((error) => {
          console.log(error);
        });
  };
}

export const deleteReport = (report_uuid) => {
  return (dispatch) => {

    return axios
        .delete(
            `${ROOT_URL}/reports/${report_uuid}/`,
        )
        .then(response => {
          return response;
        })
        .catch((error) => {
            console.error("Delete report ", error);
            return error;
        });
  };
};

export const reportDownloaded = (report_uuid) => {
    return (dispatch) => {
        dispatch({type: REPORT_DOWNLOADED, payload: report_uuid});
    };
};

export const clearReports = () => {
  return (dispatch) => {
    dispatch({type: CLEAR_REPORTS});
  };
};