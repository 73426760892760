import {T, useT} from "react-polyglot-hooks";
import {logout, resetTokenExpired} from "../actions";
import {Button, Col, Modal, Row, Typography} from "antd";
import React, {createRef, useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {useHistory} from "react-router-dom";

export const TokenExpiredModal = (props) => {
    const dispatch = useDispatch();
    const getPhrase = useT();
    const history = useHistory();

    const hasTokenExpired = useSelector(store => store.login.hasTokenExpired);

    const [timeRemaining, setTimeRemaining] = useState(5);

    const {Text} = Typography;
    let timeout = createRef(null);

    useEffect(() => {
        if (hasTokenExpired === true) {
            timeout.current = setInterval(() => {
                setTimeRemaining(prevState => prevState - 1);
            }, 1000);
        }
        return () => clearInterval(timeout.current);
    }, [dispatch, hasTokenExpired, history, timeout]);

    useEffect(() => {
        if (timeRemaining === 0) {
            clearInterval(timeout.current);
            history.push("/login/");
            dispatch(resetTokenExpired());
            dispatch(logout());
        }
    }, [dispatch, history, timeRemaining, timeout]);

    return (
        <Modal open={hasTokenExpired}
               destroyOnClose={true}
               centered={true}
               title={<T phrase={"general.go_to_login"}/>}
               footer={<Button className={"primary-btn"}
                               onClick={() => {
                                   clearInterval(timeout.current);
                                   history.push("/login/");
                                   dispatch(resetTokenExpired());
                                   dispatch(logout());
                               }
                               }>Logout</Button>}
               closable={false}
        >
            <Row>
                <Col>
                    <Text>{`${getPhrase("general.token_expired", {timeRemaining: timeRemaining})}`}</Text>
                </Col>
            </Row>
        </Modal>
    );
};