import axios from "axios";
import _ from "lodash";

const ROOT_URL = process.env.REACT_APP_API_URL;

export const GET_COUNTRIES = "GET_COUNTRIES";
export const IS_FETCHING_COUNTRIES = "IS_FETCHING_COUNTRIES";

export const GET_ISIC = "GET_ISIC";
export const IS_FETCHING_ISIC = "IS_FETCHING_ISIC";

export const GET_SEASONALITIES = "GET_SEASONALITIES";
export const IS_FETCHING_SEASONALITIES = "IS_FETCHING_SEASONALITIES";

export const GET_CURRENCIES = "GET_CURRENCIES";
export const IS_FETCHING_CURRENCIES = "IS_FETCHING_CURRENCIES";

export const SET_CID = "SET_CID";
export const EMPTY_CID = "EMPTY_CID";
export const GET_USERS_LIST = "GET_USERS_LIST";
export const EMPTY_GET_USERS_LIST = "EMPTY_GET_USERS_LIST";
export const ERROR_GET_USERS_LIST = "ERROR_GET_USERS_LIST";

export const SET_SIDEBAR_OPEN = "SET_SIDEBAR_OPEN";

export const SET_FULLSCREEN_MODE = "SET_FULLSCREEN_MODE";
export const SET_LATEST_REPORT = "SET_LATEST_REPORT";

export const SET_CUSTOMERS_ORDER_FILTER = "SET_CUSTOMERS_ORDER_FILTER";
export const SET_CUSTOMERS_ARCHIVED_FILTER = "SET_CUSTOMERS_ARCHIVED_FILTER";
export const SET_CUSTOMERS_NAME_FILTER = "SET_CUSTOMERS_NAME_FILTER";

export const START_ONBOARDING = "START_ONBOARDING";
export const END_ONBOARDING = "END_ONBOARDING";

export const SET_ONBOARDING_INDEX = "SET_ONBOARDING_INDEX";

export const setOnboardingIndex = (newIndex) => {
    return {type: SET_ONBOARDING_INDEX, payload: newIndex};
};

export const startOnboarding = () => {
    return {type: START_ONBOARDING};
};

export const endOnboarding = () => {
    return {type: END_ONBOARDING};
};

export const setFullscreenMode = (isInFullscreen) => {
    return {
        type: SET_FULLSCREEN_MODE,
        payload: isInFullscreen,
    };
};

export const setSidebarOpen = (isOpen) => {
    return {
        type: SET_SIDEBAR_OPEN,
        payload: isOpen,
    };
};

export const setCid = (cid) => {
    return {
        type: SET_CID,
        payload: cid,
    };
};
export const emptyCid = () => {
    return {
        type: EMPTY_CID,
    };
};

export const getCountries = () => {
    return (dispatch) => {
        return axios
            .get(`${ROOT_URL}/countries/`)
            .then(
                (response) => {
                    dispatch({
                        type: GET_COUNTRIES,
                        payload: [...response.data],
                    });
                },
                (error) => {
                    return error;
                }
            )
            .catch((error) => {
                console.error("getCountries ", error);
                return error;
            });
    };
};

export const getIsic = () => {
    return (dispatch) => {
        return axios
            .get(`${ROOT_URL}/isic/`)
            .then(
                (response) => {
                    dispatch({
                        type: GET_ISIC,
                        payload: [...response.data],
                    });
                    return response;
                },
                (error) => {
                    return error;
                }
            )
            .catch((error) => {
                console.error("getIsic ", error);
                return error;
            });
    };
};

export const getSeasonalities = () => {
    return (dispatch) => {
        return axios
            .get(`${ROOT_URL}/seasonalities/`)
            .then(
                (response) => {
                    dispatch({
                        type: GET_SEASONALITIES,
                        payload: {...response.data},
                    });
                    return response;
                },
                (error) => {
                    return error;
                }
            )
            .catch((error) => {
                console.error("getSeasonalities ", error);
                return error;
            });
    };
};

export const getCurrencies = () => {
    return (dispatch) => {
        return axios
            .get(`${ROOT_URL}/currencies/`)
            .then(
                (response) => {
                    dispatch({
                        type: GET_CURRENCIES,
                        payload: [...response.data],
                    });
                    return response;
                },
                (error) => {
                    return error;
                }
            )
            .catch((error) => {
                console.error("getCurrencies ", error);
                return error;
            });
    };
};

export const getUsersList = () => {
    return (dispatch) => {
        axios
            .get(`${ROOT_URL}/userslist/`)
            .then(
                (response) => {
                    if (!_.isEmpty(response?.data))
                        dispatch({
                            type: GET_USERS_LIST,
                            payload: [...response.data],
                        });
                    else
                        dispatch({
                            type: EMPTY_GET_USERS_LIST,
                        });
                },
                () => {
                    dispatch({
                        type: ERROR_GET_USERS_LIST,
                    });
                }
            )
            .catch((error) => {
                console.error("getUsersList ", error);
            });
    };
};

export const getReportList = () => {
    return (dispatch) => {
        return axios
            .get(`${ROOT_URL}/reports/`)
            .then(response => {
                dispatch({
                    type: SET_LATEST_REPORT,
                    payload: response.data?.results.length ? response.data?.results[0] : null
                });
            })
            .catch((error) => console.error("Report List ", error));
    };
};

export const setCustomersOrderFilter = (value) => {
    localStorage.setItem("customersOrderFilter", value);
    return (dispatch) => {
        dispatch({type: SET_CUSTOMERS_ORDER_FILTER, value});
    };
};

export const setCustomersArchivedFilter = (value) => {
    localStorage.setItem("customersArchivedFilter", value);
    return (dispatch) => {
        dispatch({type: SET_CUSTOMERS_ARCHIVED_FILTER, value});
    };
};

export const setCustomersNameFilter = (value) => {
    return (dispatch) => {
        dispatch({type: SET_CUSTOMERS_NAME_FILTER, value});
    };
};