import {APIQuery} from "../../../utils/apiQuery";
import axios from "axios";
import _ from "lodash";

export const LOAD_APPRAISALS = "LOAD_APPRAISALS";
export const GET_APPRAISALS = "GET_APPRAISALS";
export const EMPTY_APPRAISALS = "EMPTY_APPRAISALS";
export const ERROR_GET_APPRAISALS = "ERROR_GET_APPRAISALS";
export const LOAD_APPRAISAL = "LOAD_APPRAISAL";
export const GET_APPRAISAL = "GET_APPRAISAL";
export const EMPTY_APPRAISAL = "EMPTY_APPRAISAL";
export const ERROR_GET_APPRAISAL = "ERROR_GET_APPRAISAL";
export const ADDING_APPRAISAL = "ADDING_APPRAISAL";
export const ADD_APPRAISAL = "ADD_APPRAISAL";
export const ADD_APPRAISAL_ERROR = "ADD_APPRAISAL_ERROR";
export const EDIT_APPRAISAL = "EDIT_APPRAISAL";
export const ERROR_EDIT_APPRAISAL = "ERROR_EDIT_APPRAISAL";
export const DELETE_APPRAISAL = "DELETE_APPRAISAL";
export const LOAD_CONTENT_ROWS = "LOAD_CONTENT_ROWS";
export const LOAD_CONTENT_ROW = "LOAD_CONTENT_ROW";
export const GET_CONTENT_ROWS = "GET_CONTENT_ROWS";
export const EMPTY_CONTENT_ROWS = "EMPTY_CONTENT_ROWS";
export const ERROR_GET_CONTENT_ROWS = "ERROR_GET_CONTENT_ROWS";
export const GET_CONTENT_ROW = "GET_CONTENT_ROW";
export const EMPTY_CONTENT_ROW = "EMPTY_CONTENT_ROW";
export const ERROR_GET_CONTENT_ROW = "ERROR_GET_CONTENT_ROW";
export const ADDING_CONTENT_ROW = "ADDING_CONTENT_ROW";
export const ADD_CONTENT_ROW = "ADD_CONTENT_ROW";
export const ADD_CONTENT_ROW_ERROR = "ADD_CONTENT_ROW_ERROR";
export const EDIT_CONTENT_ROW = "EDIT_CONTENT_ROW";
export const ERROR_EDIT_CONTENT_ROW = "ERROR_EDIT_CONTENT_ROW";
export const DELETE_CONTENT_ROW = "DELETE_CONTENT_ROW";
export const LOAD_CONTENT_ROWS_SECTORS = "LOAD_CONTENT_ROWS_SECTORS";
export const GET_CONTENT_ROWS_SECTORS = "GET_CONTENT_ROWS_SECTORS";
export const EMPTY_CONTENT_ROWS_SECTORS = "EMPTY_CONTENT_ROWS_SECTORS";
export const ERROR_GET_CONTENT_ROWS_SECTORS = "ERROR_GET_CONTENT_ROWS_SECTORS";
export const GET_MACRO_CATEGORIES = "GET_MACRO_CATEGORIES";
export const LOAD_MACRO_CATEGORIES = "LOAD_MACRO_CATEGORIES";
export const LOAD_MACRO_CATEGORIE = "LOAD_MACRO_CATEGORIE";
export const EMPTY_MACRO_CATEGORIES = "EMPTY_MACRO_CATEGORIES";
export const ERROR_GET_MACRO_CATEGORIES = "ERROR_GET_MACRO_CATEGORIES";
export const GET_MACRO_CATEGORIE = "GET_MACRO_CATEGORIE";
export const EMPTY_MACRO_CATEGORIE = "EMPTY_MACRO_CATEGORIE";
export const ERROR_GET_MACRO_CATEGORIE = "ERROR_GET_MACRO_CATEGORIE";
export const ADDING_MACRO_CATEGORIE = "ADDING_MACRO_CATEGORIE";
export const ADD_MACRO_CATEGORIE = "ADD_MACRO_CATEGORIE";
export const ADD_MACRO_CATEGORIE_ERROR = "ADD_MACRO_CATEGORIE_ERROR";
export const EDIT_MACRO_CATEGORIE = "EDIT_MACRO_CATEGORIE";
export const ERROR_EDIT_MACRO_CATEGORIE = "ERROR_EDIT_MACRO_CATEGORIE";
export const DELETE_MACRO_CATEGORIE = "DELETE_MACRO_CATEGORIE";
export const LOAD_PHOTOS = "LOAD_PHOTOS";
export const LOAD_PHOTO = "LOAD_PHOTO";
export const GET_PHOTOS = "GET_PHOTOS";
export const EMPTY_PHOTOS = "EMPTY_PHOTOS";
export const ERROR_GET_PHOTOS = "ERROR_GET_PHOTOS";
export const GET_PHOTO = "GET_PHOTO";
export const EMPTY_PHOTO = "EMPTY_PHOTO";
export const ERROR_GET_PHOTO = "ERROR_GET_PHOTO";
export const ADDING_PHOTO = "ADDING_PHOTO";
export const ADD_PHOTO = "ADD_PHOTO";
export const ADD_PHOTO_ERROR = "ADD_PHOTO_ERROR";
export const EDIT_PHOTO = "EDIT_PHOTO";
export const ERROR_PHOTO = "ERROR_PHOTO";
export const DELETE_PHOTO = "DELETE_PHOTO";
export const ERROR_EDIT_PHOTO = "ERROR_EDIT_PHOTO";
export const LOAD_SECTORS = "LOAD_SECTORS";
export const LOAD_SECTOR = "LOAD_SECTOR";
export const GET_SECTORS = "GET_SECTORS";
export const EMPTY_SECTORS = "EMPTY_SECTORS";
export const ERROR_GET_SECTORS = "ERROR_GET_SECTORS";
export const GET_SECTOR = "GET_SECTOR";
export const EMPTY_SECTOR = "EMPTY_SECTOR";
export const ERROR_GET_SECTOR = "ERROR_GET_SECTOR";
export const ADDING_SECTOR = "ADDING_SECTOR";
export const ADD_SECTOR = "ADD_SECTOR";
export const ADD_SECTOR_ERROR = "ADD_SECTOR_ERROR";
export const EDIT_SECTOR = "EDIT_SECTOR";
export const ERROR_EDIT_SECTOR = "ERROR_EDIT_SECTOR";
export const DELETE_SECTOR = "DELETE_SECTOR";
export const LOAD_ASSETS = "LOAD_ASSETS";
export const LOAD_ASSET = "LOAD_ASSET";
export const GET_ASSETS = "GET_ASSETS";
export const EMPTY_ASSETS = "EMPTY_ASSETS";
export const ERROR_GET_ASSETS = "ERROR_GET_ASSETS";
export const GET_ASSET = "GET_ASSET";
export const EMPTY_ASSET = "EMPTY_ASSET";
export const ERROR_GET_ASSET = "ERROR_GET_ASSET";
export const ADDING_ASSET = "ADDING_ASSET";
export const ADD_ASSET = "ADD_ASSET";
export const ADD_ASSET_ERROR = "ADD_ASSET_ERROR";
export const EDIT_ASSET = "EDIT_ASSET";
export const ERROR_EDIT_ASSET = "ERROR_EDIT_ASSET";
export const DELETE_ASSET = "DELETE_ASSET";
export const LOAD_CONTENT_ROWS_RISK_LOCATIONS =
    "LOAD_CONTENT_ROWS_RISK_LOCATIONS";
export const GET_CONTENT_ROWS_RISK_LOCATIONS =
    "GET_CONTENT_ROWS_RISK_LOCATIONS";
export const EMPTY_CONTENT_ROWS_RISK_LOCATIONS =
    "EMPTY_CONTENT_ROWS_RISK_LOCATIONS";
export const ERROR_GET_CONTENT_ROWS_RISK_LOCATIONS =
    "ERROR_GET_CONTENT_ROWS_RISK_LOCATIONS";


export const LOAD_CONTENT_ROWS_ASSETS = "LOAD_CONTENT_ROWS_ASSETS";
export const GET_CONTENT_ROWS_ASSETS = "GET_CONTENT_ROWS_ASSETS";
export const EMPTY_CONTENT_ROWS_ASSETS = "EMPTY_CONTENT_ROWS_ASSETS";
export const ERROR_GET_CONTENT_ROWS_ASSETS = "ERROR_GET_CONTENT_ROWS_ASSETS";

export const LOAD_CONTENT_ROWS_BASE_RISK_LOCATIONS =
    "LOAD_CONTENT_ROWS_BASE_RISK_LOCATIONS";
export const GET_CONTENT_ROWS_BASE_RISK_LOCATIONS =
    "GET_CONTENT_ROWS_BASE_RISK_LOCATIONS";
export const EMPTY_CONTENT_ROWS_BASE_RISK_LOCATIONS =
    "EMPTY_CONTENT_ROWS_BASE_RISK_LOCATIONS";
export const ERROR_GET_CONTENT_ROWS_BASE_RISK_LOCATIONS =
    "ERROR_GET_CONTENT_ROWS_BASE_RISK_LOCATIONS";

export const LOAD_RISK_LOCATIONS_NAMES = "LOAD_RISK_LOCATIONS_NAMES";
export const GET_RISK_LOCATIONS_NAMES = "GET_RISK_LOCATIONS_NAMES";
export const EMPTY_RISK_LOCATIONS_NAMES = "EMPTY_RISK_LOCATIONS_NAMES";
export const ERROR_RISK_LOCATIONS_NAMES = "ERROR_RISK_LOCATIONS_NAMES";

export const GET_LAST_ADDED_APPRAISAL = "GET_LAST_ADDED_APPRAISAL";

export const GET_ACTUALIZATION_FACTOR = "GET_ACTUALIZATION_FACTOR";

export const CLEAR_CONTENT_VALUE = "CLEAR_CONTENT_VALUE";

export const GET_FILTERED_ASSETS = "GET_FILTERED_ASSETS";


const ROOT_URL = process.env.REACT_APP_API_URL;

/************************Appraisals****************************/

export const clearContentValue = () => {
    return (dispatch) => dispatch({type: CLEAR_CONTENT_VALUE});
};

export const getAppraisals = (customer_uuid) => {
    return (dispatch) => {
        dispatch({type: LOAD_APPRAISALS});
        return axios
            .get(`${ROOT_URL}/customers/${customer_uuid}/appraisals/`)
            .then(
                (response) => {
                    if (!_.isEmpty(response)) {
                        dispatch({
                            type: GET_APPRAISALS,
                            payload: response.data,
                        });
                    } else {
                        dispatch({type: EMPTY_APPRAISALS});
                    }
                },
                (error) => {
                    dispatch({
                        type: ERROR_GET_APPRAISALS,
                    });
                }
            )
            .catch((error) => {
                console.error("ERROR GET APPRAISALS", error);
            });
    };
};

export const getAppraisal = (uuid, customer_uuid) => {
    return (dispatch) => {
        dispatch({type: LOAD_APPRAISAL});
        return axios
            .get(`${ROOT_URL}/customers/${customer_uuid}/appraisals/${uuid}/`)
            .then(
                (response) => {
                    if (!_.isEmpty(response)) {
                        dispatch({
                            type: GET_APPRAISAL,
                            payload: response.data,
                        });
                    } else {
                        dispatch({type: EMPTY_APPRAISAL});
                    }
                },
                (error) => {
                    dispatch({
                        type: ERROR_GET_APPRAISAL,
                    });
                }
            )
            .catch((error) => {
                console.error("ERROR GET APPRAISAL", error);
            });
    };
};

export const addAppraisal = (customer_uuid, risk_location) => {
    // localStorage.setItem("appraisalId", null);
    var obj = {
        customer: customer_uuid,
        macrocategories: false,
        hightech_asset: false,
        risk_location: risk_location,
    };
    return (dispatch) => {
        dispatch({type: ADDING_APPRAISAL});
        return axios
            .post(`${ROOT_URL}/customers/${customer_uuid}/appraisals/`, {...obj})
            .then(
                (response) => {

                    dispatch({
                        type: ADD_APPRAISAL,
                        payload: response.data,
                    });
                    dispatch({
                        type: GET_LAST_ADDED_APPRAISAL,
                        payload: response.data,
                    });
                    dispatch(getAppraisal(response.data.uuid, customer_uuid));
                    return response.data.uuid;
                },
                (error) => {
                    dispatch({type: ADD_APPRAISAL_ERROR});
                }
            )
            .catch((error) => console.error("add appraisals ", error));
    };
};

export const getLastAddedAppraisalInfo = () => {
    return {
        type: GET_LAST_ADDED_APPRAISAL,
    };
};

export const editAppraisal = (customer_uuid, uuid, appraisalInfo) => {
    return (dispatch) => {
        return axios.patch(`${ROOT_URL}/customers/${customer_uuid}/appraisals/${uuid}/`, appraisalInfo)
            .then(
                (res) => {
                    dispatch({type: EDIT_APPRAISAL, payload: res.data});
                    return res;
                },
                (error) => {
                    dispatch({type: ERROR_EDIT_APPRAISAL});
                    return error;
                }
            )
            .catch((error) => {
                console.error("editUserData ", error);
                return error;
            });
    };
};

export const deleteAppraisal = (customer_uuid, uuid) => {
    return (dispatch) => {
        return axios
            .delete(`${ROOT_URL}/customers/${customer_uuid}/appraisals/${uuid}/`)
            .then(() => {
                dispatch({type: DELETE_APPRAISAL, payload: uuid});
            })
            .catch((error) => {
                console.error(error);
            });
    };
};

/************************Content Rows****************************/

export const getContentRows = (customer_uuid, appraisal_uuid, query = null) => {
    const q = APIQuery(query);
    return (dispatch) => {
        dispatch({type: LOAD_CONTENT_ROWS});
        return axios
            .get(
                `${ROOT_URL}/customers/${customer_uuid}/appraisals/${appraisal_uuid}/contentrows/${q}`
            )
            .then(
                (response) => {
                    if (!_.isEmpty(response)) {
                        dispatch({
                            type: GET_CONTENT_ROWS,
                            payload: response.data,
                        });
                    } else {
                        dispatch({type: EMPTY_CONTENT_ROWS});
                    }
                },
                (error) => {
                    dispatch({
                        type: ERROR_GET_CONTENT_ROWS,
                    });
                }
            )
            .catch((error) => {
                console.error("ERROR GET CONTENT ROWS", error);
            });
    };
};

export const getContentRow = (customer_uuid, appraisal_uuid, uuid) => {
    return (dispatch) => {
        dispatch({type: LOAD_CONTENT_ROW});
        return axios
            .get(
                `${ROOT_URL}/customers/${customer_uuid}/appraisals/${appraisal_uuid}/contentrows/${uuid}/`
            )
            .then(
                (response) => {
                    if (!_.isEmpty(response)) {
                        dispatch({
                            type: GET_CONTENT_ROW,
                            contentRow: response.data,
                        });
                    } else {
                        dispatch({type: EMPTY_CONTENT_ROW});
                    }
                },
                (error) => {
                    dispatch({
                        type: ERROR_GET_CONTENT_ROW,
                    });
                }
            )
            .catch((error) => {
                console.error("ERROR GET CONTENT ROW", error);
            });
    };
};

export const getContentRowsSectors = (customer_uuid, appraisal_uuid) => {
    return (dispatch) => {
        dispatch({type: LOAD_CONTENT_ROWS_SECTORS});
        return axios
            .get(
                `${ROOT_URL}/customers/${customer_uuid}/appraisals/${appraisal_uuid}/contentrowsmacrocategories/`
            )
            .then(
                (response) => {
                    if (!_.isEmpty(response)) {
                        dispatch({
                            type: GET_CONTENT_ROWS_SECTORS,
                            payload: response.data,
                        });
                    } else {
                        dispatch({type: EMPTY_CONTENT_ROWS_SECTORS});
                    }
                },
                (error) => {
                    dispatch({
                        type: ERROR_GET_CONTENT_ROWS_SECTORS,
                    });
                }
            )
            .catch((error) => {
                console.error("ERROR GET CONTENT ROWS SECTORS", error);
            });
    };
};

export const getContentRowsAssets = (customer_uuid, appraisal_uuid) => {
    return (dispatch) => {
        dispatch({type: LOAD_CONTENT_ROWS_ASSETS});
        return axios
            .get(
                `${ROOT_URL}/customers/${customer_uuid}/appraisals/${appraisal_uuid}/contentrowsassets/`
            )
            .then(
                (response) => {
                    if (!_.isEmpty(response)) {
                        dispatch({
                            type: GET_CONTENT_ROWS_ASSETS,
                            payload: response.data,
                        });
                    } else {
                        dispatch({type: EMPTY_CONTENT_ROWS_ASSETS});
                    }
                },
                (error) => {
                    dispatch({
                        type: ERROR_GET_CONTENT_ROWS_ASSETS,
                    });
                }
            )
            .catch((error) => {
                console.error("ERROR GET CONTENT ROWS RISK LOCATIONS", error);
            });
    };
};

export const getContentRowsRisklocations = (customer_uuid, appraisal_uuid) => {
    return (dispatch) => {
        dispatch({type: LOAD_CONTENT_ROWS_RISK_LOCATIONS});
        return axios
            .get(
                `${ROOT_URL}/customers/${customer_uuid}/appraisals/${appraisal_uuid}/contentrowsrisklocations/`
            )
            .then(
                (response) => {
                    if (!_.isEmpty(response)) {
                        dispatch({
                            type: GET_CONTENT_ROWS_RISK_LOCATIONS,
                            payload: response.data,
                        });
                    } else {
                        dispatch({type: EMPTY_CONTENT_ROWS_RISK_LOCATIONS});
                    }
                },
                (error) => {
                    dispatch({
                        type: ERROR_GET_CONTENT_ROWS_RISK_LOCATIONS,
                    });
                }
            )
            .catch((error) => {
                console.error("ERROR GET CONTENT ROWS RISK LOCATIONS", error);
            });
    };
};

export const getContentRowsBaseRisklocations = (customer_uuid) => {
    return (dispatch) => {
        dispatch({type: LOAD_CONTENT_ROWS_BASE_RISK_LOCATIONS});
        return axios
            .get(
                `${ROOT_URL}/customers/${customer_uuid}/contentrowsbasemacrocategories/`
            )
            .then(
                (response) => {
                    if (!_.isEmpty(response)) {
                        dispatch({
                            type: GET_CONTENT_ROWS_BASE_RISK_LOCATIONS,
                            payload: response.data,
                        });
                    } else {
                        dispatch({type: EMPTY_CONTENT_ROWS_BASE_RISK_LOCATIONS});
                    }
                },
                (error) => {
                    dispatch({
                        type: ERROR_GET_CONTENT_ROWS_BASE_RISK_LOCATIONS,
                    });
                }
            )
            .catch((error) => {
                console.error("ERROR GET CONTENT ROWS RISK LOCATIONS", error);
            });
    };
};

export const getRisklocationsNames = (customer_uuid) => {
    return (dispatch) => {
        dispatch({type: LOAD_RISK_LOCATIONS_NAMES});
        return axios
            .get(`${ROOT_URL}/customers/${customer_uuid}/risklocationsnames/`)
            .then(
                (response) => {
                    if (!_.isEmpty(response)) {
                        dispatch({
                            type: GET_RISK_LOCATIONS_NAMES,
                            payload: response.data,
                        });
                    } else {
                        dispatch({type: EMPTY_RISK_LOCATIONS_NAMES});
                    }
                },
                (error) => {
                    dispatch({
                        type: ERROR_RISK_LOCATIONS_NAMES,
                    });
                }
            )
            .catch((error) => {
                console.error("ERROR RISK LOCATIONS NAMES", error);
            });
    };
};

export const addContentRow = (customer_uuid, appraisal_uuid, rowsInfo) => {
    return (dispatch) => {
        dispatch({type: ADDING_CONTENT_ROW});
        axios
            .post(
                `${ROOT_URL}/customers/${customer_uuid}/appraisals/${appraisal_uuid}/contentrows/`,
                rowsInfo
            )
            .then(
                () => {
                    dispatch({type: ADD_CONTENT_ROW});
                    dispatch(getContentRows(customer_uuid, appraisal_uuid));
                },
                (error) => {
                    dispatch({type: ADD_CONTENT_ROW_ERROR});
                }
            )
            .catch((error) => console.error("Content Row ", error));
    };
};

export const updateContentRow = (
    customer_uuid,
    appraisal_uuid,
    uuid,
    rowInfo
) => {
    return (dispatch) => {
        return axios
            .patch(
                `${ROOT_URL}/customers/${customer_uuid}/appraisals/${appraisal_uuid}/contentrows/${uuid}/`,
                rowInfo
            )
            .then(
                (res) => {
                    dispatch({
                        type: EDIT_CONTENT_ROW,
                        payload: res.data
                    });
                    return res;
                },
                (error) => {
                    dispatch({type: ERROR_EDIT_CONTENT_ROW});
                    return error;
                }
            )
            .catch((error) => console.error("edit content row ", error));
    };
};

export const deleteContentRow = (customer_uuid, appraisal_uuid, uuid) => {
    return (dispatch) => {
        return axios
            .delete(
                `${ROOT_URL}/customers/${customer_uuid}/appraisals/${appraisal_uuid}/contentrows/${uuid}/`
            )
            .then((res) => {
                dispatch({type: DELETE_CONTENT_ROW});
                dispatch(getContentRows(customer_uuid, appraisal_uuid));
                return res;
            })
            .catch((error) => {
                console.error(error);
                return error;
            });
    };
};

/************************Macro Categories****************************/

export const getMacroCategories = (customer_uuid, appraisal_uuid) => {
    return (dispatch) => {
        dispatch({type: LOAD_MACRO_CATEGORIES});
        return axios
            .get(
                `${ROOT_URL}/customers/${customer_uuid}/appraisals/${appraisal_uuid}/macrocategories/`
            )
            .then(
                (response) => {
                    if (!_.isEmpty(response)) {
                        dispatch({
                            type: GET_MACRO_CATEGORIES,
                            payload: response.data,
                        });
                    } else {
                        dispatch({type: EMPTY_MACRO_CATEGORIES});
                    }
                },
                (error) => {
                    dispatch({
                        type: ERROR_GET_MACRO_CATEGORIES,
                    });
                }
            )
            .catch((error) => {
                console.error("ERROR GET MACRO CATEGORIES", error);
            });
    };
};

export const getMacroCategorie = (customer_uuid, appraisal_uuid, uuid) => {
    return (dispatch) => {
        dispatch({type: LOAD_MACRO_CATEGORIE});

        return axios
            .get(
                `${ROOT_URL}/customers/${customer_uuid}/appraisals/${appraisal_uuid}/macrocategories/${uuid}/`
            )
            .then(
                (response) => {
                    if (!_.isEmpty(response)) {
                        dispatch({
                            type: GET_MACRO_CATEGORIE,
                            payload: response.data,
                        });
                    } else {
                        dispatch({type: EMPTY_MACRO_CATEGORIE});
                    }
                },
                (error) => {
                    dispatch({
                        type: ERROR_GET_MACRO_CATEGORIE,
                    });
                }
            )
            .catch((error) => {
                console.error("ERROR GET MACRO CATEGORIE", error);
            });
    };
};

export const addMacroCategorie = (
    customer_uuid,
    appraisal_uuid,
    MacroCategorieInfo
) => {
    return (dispatch) => {
        dispatch({type: ADDING_MACRO_CATEGORIE});
        axios
            .post(
                `${ROOT_URL}/customers/${customer_uuid}/appraisals/${appraisal_uuid}/macrocategories/`,
                MacroCategorieInfo
            )
            .then(
                (res) => {
                    dispatch({type: ADD_MACRO_CATEGORIE, payload: res.data});
                },
                (error) => {
                    dispatch({type: ADD_MACRO_CATEGORIE_ERROR});
                }
            )
            .catch((error) => console.error("MacroCategorieInfo ", error));
    };
};

export const editMacroCategorie = (
    customer_uuid,
    appraisal_uuid,
    uuid,
    macroCategorieInfo
) => {
    return (dispatch) => {
        axios
            .put(
                `${ROOT_URL}/customers/${customer_uuid}/appraisals/${appraisal_uuid}/macrocategories/${uuid}/`,
                macroCategorieInfo
            )
            .then(
                (res) => {
                    dispatch({type: EDIT_MACRO_CATEGORIE, payload: res.data});
                },
                (error) => {
                    dispatch({type: ERROR_EDIT_MACRO_CATEGORIE});
                }
            )
            .catch((error) => console.error("edit macro categorie ", error));
    };
};

export const deleteMacroCategorie = (customer_uuid, appraisal_uuid, uuid) => {
    return (dispatch) => {
        return axios
            .delete(
                `${ROOT_URL}/customers/${customer_uuid}/appraisals/${appraisal_uuid}/macrocategories/${uuid}/`
            )
            .then((res) => {
                dispatch({type: DELETE_MACRO_CATEGORIE, payload: uuid});
            })
            .catch((error) => {
                console.error(error);
            });
    };
};

/******************************Photos****************************/

export const getPhotos = (customer_uuid, appraisal_uuid) => {
    return (dispatch) => {
        dispatch({type: LOAD_PHOTOS});
        return axios
            .get(
                `${ROOT_URL}/customers/${customer_uuid}/appraisals/${appraisal_uuid}/photos/`
            )
            .then(
                (response) => {
                    if (!_.isEmpty(response)) {
                        dispatch({
                            type: GET_PHOTOS,
                            payload: response.data,
                        });
                    } else {
                        dispatch({type: EMPTY_PHOTOS});
                    }
                },
                (error) => {
                    dispatch({
                        type: ERROR_GET_PHOTOS,
                    });
                }
            )
            .catch((error) => {
                console.error("ERROR GET PHOTOS", error);
            });
    };
};

export const getPhoto = (customer_uuid, appraisal_uuid, uuid) => {
    return (dispatch) => {
        dispatch({type: LOAD_PHOTO});
        return axios
            .get(
                `${ROOT_URL}/customers/${customer_uuid}/appraisals/${appraisal_uuid}/photos/${uuid}/`
            )
            .then(
                (response) => {
                    if (!_.isEmpty(response)) {
                        dispatch({
                            type: GET_PHOTO,
                            photo: response.data,
                        });
                    } else {
                        dispatch({type: EMPTY_PHOTO});
                    }
                },
                (error) => {
                    dispatch({
                        type: ERROR_GET_PHOTO,
                    });
                }
            )
            .catch((error) => {
                console.error("ERROR GET PHOTO", error);
            });
    };
};

export const addSectorPhotos = (customer_uuid, appraisal_uuid, photoInfo, file) => {
    return (dispatch) => {
        dispatch({type: ADDING_PHOTO});
        const config = {
            headers: {"content-type": "multipart/form-data"},
        };
        let picture = new FormData();
        picture.append("customer", photoInfo.customer);
        picture.append("appraisal", photoInfo.appraisal);
        picture.append("type", photoInfo.type);
        picture.append("content", file);
        picture.append("risk_location", photoInfo.risk_location);
        picture.append("description", photoInfo.description);

        axios.post(
            `${ROOT_URL}/customers/${customer_uuid}/appraisals/${appraisal_uuid}/photos/`,
            picture,
            config
        )
            .then(
                (res) => {
                    dispatch({type: ADD_PHOTO, payload: res.data});
                },
                (error) => {
                    dispatch({type: ADD_PHOTO_ERROR});
                }
            )
            .catch((error) => console.error("add photo", error));
    };
};

export const editPhoto = (customer_uuid, appraisal_uuid, uuid, photoInfo) => {
    return (dispatch) => {
        return axios.patch(`${ROOT_URL}/customers/${customer_uuid}/appraisals/${appraisal_uuid}/photos/${uuid}/`, photoInfo)
            .then((res) => {
                    dispatch({type: EDIT_PHOTO, payload: res.data});
                    return res;
                },
                (error) => {
                    dispatch({type: ERROR_PHOTO});
                    return error;
                }
            )
            .catch((error) => console.error("edit photo ", error));
    };
};

export const deletePhoto = (customer_uuid, appraisal_uuid, uuid) => {
    return (dispatch) => {
        return axios
            .delete(
                `${ROOT_URL}/customers/${customer_uuid}/appraisals/${appraisal_uuid}/photos/${uuid}/`
            )
            .then(() => {
                dispatch({type: DELETE_PHOTO, payload: uuid});
            })
            .catch((error) => {
                console.error(error);
            });
    };
};

export const uploadSectorPhotos = (
    customer_uuid,
    appraisal_uuid,
    uuid,
    file
) => {
    const config = {
        headers: {"content-type": "multipart/form-data"},
    };
    let picture = new FormData();
    picture.append("file", file);

    return (dispatch) => {
        axios
            .put(
                `${ROOT_URL}/customers/${customer_uuid}/appraisals/${appraisal_uuid}/photos/${uuid}/upload_photo/`,
                picture,
                config
            )
            .then(
                () => {
                    dispatch({
                        type: EDIT_PHOTO,
                    });
                },
                (error) => {
                    dispatch({type: ERROR_EDIT_PHOTO});
                }
            )
            .catch((error) => console.error("upload photo ", error));
    };
};

/******************************Sectors****************************/

export const getSectors = (customer_uuid, appraisal_uuid) => {
    return (dispatch) => {
        dispatch({type: LOAD_SECTORS});
        return axios
            .get(
                `${ROOT_URL}/customers/${customer_uuid}/appraisals/${appraisal_uuid}/sectors/`
            )
            .then(
                (response) => {
                    if (!_.isEmpty(response)) {
                        dispatch({
                            type: GET_SECTORS,
                            payload: response.data,
                        });
                        dispatch({type: GET_CONTENT_ROWS_SECTORS});
                        dispatch(getContentRowsSectors(customer_uuid, appraisal_uuid));
                    } else {
                        dispatch({type: EMPTY_SECTORS});
                    }
                },
                (error) => {
                    dispatch({
                        type: ERROR_GET_SECTORS,
                    });
                }
            )
            .catch((error) => {
                console.error("ERROR GET SECTORS", error);
            });
    };
};

export const getSector = (customer_uuid, appraisal_uuid, uuid) => {
    return (dispatch) => {
        dispatch({type: LOAD_SECTOR});
        return axios
            .get(
                `${ROOT_URL}/customers/${customer_uuid}/appraisals/${appraisal_uuid}/sectors/${uuid}/`
            )
            .then(
                (response) => {
                    if (!_.isEmpty(response)) {
                        dispatch({
                            type: GET_SECTOR,
                            sector: response.data,
                        });
                    } else {
                        dispatch({type: EMPTY_SECTOR});
                    }
                },
                (error) => {
                    dispatch({
                        type: ERROR_GET_SECTOR,
                    });
                }
            )
            .catch((error) => {
                console.error("ERROR GET SECTOR", error);
            });
    };
};

export const addSector = (customer_uuid, appraisal_uuid, sectorInfo) => {
    return (dispatch) => {
        dispatch({type: ADDING_SECTOR});
        axios
            .post(
                `${ROOT_URL}/customers/${customer_uuid}/appraisals/${appraisal_uuid}/sectors/`,
                sectorInfo
            )
            .then(
                () => {
                    dispatch({type: ADD_SECTOR});
                    dispatch(getSectors(customer_uuid, appraisal_uuid));
                    dispatch({type: GET_CONTENT_ROWS_SECTORS});
                    dispatch(getContentRowsSectors(customer_uuid, appraisal_uuid));
                },
                (error) => {
                    dispatch({type: ADD_SECTOR_ERROR});
                }
            )
            .catch((error) => console.error("add Sector ", error));
    };
};

export const editSector = (customer_uuid, appraisal_uuid, uuid, sectorInfo) => {
    return (dispatch) => {
        axios
            .put(
                `${ROOT_URL}/customers/${customer_uuid}/appraisals/${appraisal_uuid}/sectors/${uuid}/`,
                sectorInfo
            )
            .then(
                () => {
                    dispatch({type: EDIT_SECTOR,});
                    dispatch(getSectors(customer_uuid, appraisal_uuid));
                    dispatch({type: GET_CONTENT_ROWS_SECTORS});
                    dispatch(getContentRowsSectors(customer_uuid, appraisal_uuid));
                },
                (error) => {
                    dispatch({type: ERROR_EDIT_SECTOR});
                }
            )
            .catch((error) => console.error("edit sector ", error));
    };
};

export const deleteSector = (customer_uuid, appraisal_uuid, uuid) => {
    return (dispatch) => {
        return axios
            .delete(
                `${ROOT_URL}/customers/${customer_uuid}/appraisals/${appraisal_uuid}/sectors/${uuid}/`
            )
            .then(() => {
                dispatch({type: DELETE_SECTOR});
                dispatch(getSectors(customer_uuid, appraisal_uuid));
                dispatch({type: GET_CONTENT_ROWS_SECTORS});
                dispatch(getContentRowsSectors(customer_uuid, appraisal_uuid));
            })
            .catch((error) => {
                console.error(error);
            });
    };
};

/******************************Assets****************************/

export const getAssets = (customer_uuid) => {
    return (dispatch) => {
        dispatch({type: LOAD_ASSETS});
        return axios
            .get(`${ROOT_URL}/customers/${customer_uuid}/assets/`)
            .then(
                (response) => {
                    if (!_.isEmpty(response)) {
                        dispatch({
                            type: GET_ASSETS,
                            assets: response.data,
                        });
                    } else {
                        dispatch({type: EMPTY_ASSETS});
                    }
                },
                (error) => {
                    dispatch({
                        type: ERROR_GET_ASSETS,
                    });
                }
            )
            .catch((error) => {
                console.error("ERROR GET ASSETS", error);
            });
    };
};

export const getAsset = (customer_uuid, uuid) => {
    return (dispatch) => {
        dispatch({type: LOAD_ASSET});
        return axios
            .get(`${ROOT_URL}/customers/${customer_uuid}/assets/${uuid}/`)
            .then(
                (response) => {
                    if (!_.isEmpty(response)) {
                        dispatch({
                            type: GET_ASSET,
                            asset: response.data,
                        });
                    } else {
                        dispatch({type: EMPTY_ASSETS});
                    }
                },
                (error) => {
                    dispatch({
                        type: ERROR_GET_ASSET,
                    });
                }
            )
            .catch((error) => {
                console.error("ERROR GET ASSET", error);
            });
    };
};

export const addAsset = (customer_uuid, assetInfo) => {
    return (dispatch) => {
        dispatch({type: ADDING_ASSET});
        axios
            .post(`${ROOT_URL}/customers/${customer_uuid}/assets/`, assetInfo)
            .then(
                () => {
                    dispatch({type: ADD_ASSET});
                },
                (error) => {
                    dispatch({type: ADD_ASSET_ERROR});
                }
            )
            .catch((error) => console.error("add asset ", error));
    };
};

export const editAsset = (customer_uuid, uuid, assetInfo) => {
    return (dispatch) => {
        axios
            .put(`${ROOT_URL}/customers/${customer_uuid}/assets/${uuid}/`, assetInfo)
            .then(
                () => {
                    dispatch({
                        type: EDIT_ASSET,
                    });
                },
                (error) => {
                    dispatch({type: ERROR_EDIT_ASSET});
                }
            )
            .catch((error) => console.error("edit Asset ", error));
    };
};

export const deleteAsset = (customer_uuid, uuid) => {
    return (dispatch) => {
        return axios
            .delete(`${ROOT_URL}/customers/${customer_uuid}/assets/${uuid}/`)
            .then(() => {
                dispatch({type: DELETE_ASSET});
            })
            .catch((error) => {
                console.error(error);
            });
    };
};

export const getActualizationIndex = (customer_uuid, appraisal_uuid, purchasing_year) => {
    return (dispatch) => {
        return axios
            .get(`${ROOT_URL}/customers/${customer_uuid}/appraisals/${appraisal_uuid}/actualization/`, {params: {purchasing_year}})
            .then(res => {
                    if (res.status === 200) {
                        dispatch({type: GET_ACTUALIZATION_FACTOR, payload: res.data.actualization_factor});
                    }
                    return res;
                },
            )
            .catch((error) => {
                console.error("getActualizationIndex: ", error);
                return error;
            });
    };
};

export const getFilteredAssets = (customer_uuid, risklocation_uuid) => {
    return (dispatch) => {
        return axios
            .get(`${ROOT_URL}/customers/${customer_uuid}/risklocations/${risklocation_uuid}/assets/`)
            .then(res => {
                    if (res.status === 200) {
                        dispatch({type: GET_FILTERED_ASSETS, payload: res.data});
                    }
                    return res;
                },
            )
            .catch((error) => {
                console.error("get filtered assets ", error);
                return error;
            });
    };
};
