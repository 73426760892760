import React from "react";
import {Col, Row, Spin} from "antd";

const Loading = () => {
  return (
    <Row justify={"center"}>
        <Col flex={1}
             style={{textAlign: "center", margin: "1rem"}}>
            <Spin/>
        </Col>
    </Row>
  );
};

export default Loading;
