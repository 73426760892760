import {
    ARE_BALANCES_EMPTY,
    ARE_FINANTIAL_RATIOS_EMPTY,
    CLEAR_BALANCES,
    CLEAR_DEFAULT_PROBABILITY,
    CLEAR_FINANCIAL_RATIOS,
    CLEAR_HEATMAP,
    CLEAR_HEATMAP_STATUS,
    CLEAR_MORE_SCORE,
    CLEAR_RATING_DATA,
    CLEAR_SCORE_DISTRIBUTION,
    CLEAR_UPDATE_CHART_DATA,
    DELETE_BALANCE,
    ERROR_BALANCES,
    ERROR_DEFAULT_PROBABILITY,
    ERROR_FINANCIAL_RATIOS,
    ERROR_HEATMAP,
    ERROR_MORE_SCORE,
    ERROR_RATING_DATA,
    ERROR_SCORE_DISTRIBUTION,
    ERROR_UPDATE_CHART_DATA,
    GET_BALANCES,
    GET_DEFAULT_PROBABILITY,
    GET_FINANCIAL_RATIOS,
    GET_HEATMAP,
    GET_MORE_SCORE,
    GET_RATING_DATA,
    GET_SCORE_DISTRIBUTION,
    GET_UPDATE_HEATMAP_STATUS,
    IS_DEFAULT_PROBABILITY_EMPTY,
    IS_HEATMAP_EMPTY,
    IS_MORE_SCORE_EMPTY,
    IS_RATING_DATA_EMPTY,
    IS_SCENARIOS_EMPTY,
    IS_SCORE_DISTRIBUTION_EMPTY,
    UPDATE_CHART_DATA,
} from "./actions";

const INITIAL_STATE = {
  heatMapStatus: {},
  scenarios: {},
  isScenariosEmpty: false,
  errorScenarios: false,
  balances: undefined,
  updateChartData: false,
  updateChartDataMsg: {},
  errorUpdateChartData: false,
  areBalancesEmpty: undefined,
  errorBalances: undefined,
  moreScoreData: undefined,
  isMoreScoreEmpty: undefined,
  errorMoreScore: undefined,
  scoreDistribution: undefined,
  isScoreDistributionEmpty: undefined,
  errorScoreDistribution: undefined,
  ratingData: undefined,
  isRatingDataEmpty: undefined,
  errorRatingData: undefined,
  probabilityOfDefaultData: undefined,
  isProbabilityOfDefaultEmpty: undefined,
  errorProbabilityDefault: undefined,
  financialRatios: undefined,
  areFinancialRatiosEmpty: undefined,
  errorFinancialRatios: undefined,
  heatmap: undefined,
  isHeatMapEmpty: undefined,
  errorHeatmap: undefined,
  isBalanceDeleted: false,
};

export default function ChartsReducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    case GET_UPDATE_HEATMAP_STATUS:
      return {
        ...state,
        heatMapStatus: { ...action.heatMapStatus },
      };
    case UPDATE_CHART_DATA:
      return {
        ...state,
        updateChartDataMsg: action.updateChartDataMsg,
        updateChartData: true,
        errorUpdateChartData: false,
      };
    case GET_HEATMAP:
      return {
        ...state,
        heatmap: { ...action.heatmap },
        isHeatMapEmpty: false,
        errorHeatmap: false,
      };
    case GET_BALANCES:
      return {
        ...state,
        balances: action.balances,
        areBalancesEmpty: false,
        errorBalances: false,
      };
    case GET_MORE_SCORE:
      return {
        ...state,
        moreScoreData: { ...action.moreScoreData },
        isMoreScoreEmpty: false,
        errorMoreScore: false,
      };
    case GET_SCORE_DISTRIBUTION:
      return {
        ...state,
        scoreDistribution: action.scoreDistribution,
        isScoreDistributionEmpty: false,
        errorScoreDistribution: false,
      };
    case GET_RATING_DATA:
      return {
        ...state,
        ratingData: { ...action.ratingData },
        isRatingDataEmpty: false,
        errorRatingData: false,
      };
    case GET_DEFAULT_PROBABILITY:
      return {
        ...state,
        probabilityOfDefaultData: { ...action.probabilityOfDefaultData },
        isProbabilityOfDefaultEmpty: false,
        errorProbabilityDefault: false,
      };
    case GET_FINANCIAL_RATIOS:
      return {
        ...state,
        financialRatios: { ...action.financialRatios },
        areFinancialRatiosEmpty: false,
        errorFinancialRatios: false,
      };
    case IS_HEATMAP_EMPTY:
      return {
        ...state,
        isHeatMapEmpty: true,
        heatmap: {},
        errorHeatmap: false,
      };
    case IS_SCENARIOS_EMPTY:
      return {
        ...state,
        scenarios: {},
        isScenariosEmpty: true,
        errorScenarios: false,
      };
    case IS_RATING_DATA_EMPTY:
      return {
        ...state,
        isRatingDataEmpty: true,
        ratingData: {},
        errorRatingData: false,
      };
    case IS_SCORE_DISTRIBUTION_EMPTY:
      return {
        ...state,
        isScoreDistributionEmpty: true,
        scoreDistribution: {},
        errorScoreDistribution: false,
      };
    case IS_MORE_SCORE_EMPTY:
      return {
        ...state,
        moreScoreData: {},
        isMoreScoreEmpty: true,
        errorMoreScore: false,
      };
    case ARE_FINANTIAL_RATIOS_EMPTY:
      return {
        ...state,
        financialRatios: {},
        areFinancialRatiosEmpty: true,
        errorFinancialRatios: false,
      };
    case ARE_BALANCES_EMPTY:
      return {
        ...state,
        balances: {},
        areBalancesEmpty: true,
        errorBalances: false,
      };
    case IS_DEFAULT_PROBABILITY_EMPTY:
      return {
        ...state,
        probabilityOfDefaultData: {},
        isProbabilityOfDefaultEmpty: true,
        errorProbabilityDefault: false,
      };
    case CLEAR_BALANCES:
      return {
        ...state,
        areBalancesEmpty: undefined,
        balances: undefined,
        errorBalances: undefined,
      };
    case CLEAR_RATING_DATA:
      return {
        ...state,
        isRatingDataEmpty: undefined,
        ratingData: undefined,
        errorRatingData: undefined,
      };
    case CLEAR_SCORE_DISTRIBUTION:
      return {
        ...state,
        isScoreDistributionEmpty: undefined,
        scoreDistribution: undefined,
        errorScoreDistribution: undefined,
      };
    case CLEAR_MORE_SCORE:
      return {
        ...state,
        isMoreScoreEmpty: undefined,
        errorMoreScore: undefined,
        moreScoreData: undefined,
      };
    case CLEAR_FINANCIAL_RATIOS:
      return {
        ...state,
        areFinancialRatiosEmpty: undefined,
        financialRatios: undefined,
        errorFinancialRatios: undefined,
      };
    case CLEAR_DEFAULT_PROBABILITY:
      return {
        ...state,
        probabilityOfDefaultData: undefined,
        isProbabilityOfDefaultEmpty: undefined,
        errorProbabilityDefault: undefined,
      };
    case CLEAR_HEATMAP:
      return {
        ...state,
        heatmap: undefined,
        isHeatMapEmpty: undefined,
        errorHeatmap: undefined,
      };
    case CLEAR_HEATMAP_STATUS:
      return {
        ...state,
        heatMapStatus: {},
      };

    case CLEAR_UPDATE_CHART_DATA:
      return {
        ...state,
        updateChartDataMsg: {},
        updateChartData: false,
        errorUpdateChartData: false,
      };
    case DELETE_BALANCE:
      return {
        ...state,
        isBalanceDeleted: true,
      };
    case ERROR_MORE_SCORE:
      return {
        ...state,
        moreScoreData: {},
        isMoreScoreEmpty: false,
        errorMoreScore: true,
      };
    case ERROR_BALANCES:
      return {
        ...state,
        balances: {},
        areBalancesEmpty: false,
        errorBalances: true,
      };
    case ERROR_SCORE_DISTRIBUTION:
      return {
        ...state,
        scoreDistribution: {},
        isScoreDistributionEmpty: false,
        errorScoreDistribution: true,
      };
    case ERROR_RATING_DATA:
      return {
        ...state,
        ratingData: {},
        isRatingDataEmpty: false,
        errorRatingData: true,
      };
    case ERROR_FINANCIAL_RATIOS:
      return {
        ...state,
        financialRatios: {},
        areFinancialRatiosEmpty: false,
        errorFinancialRatios: false,
      };
    case ERROR_HEATMAP:
      return {
        ...state,
        heatmap: {},
        isHeatMapEmpty: false,
        errorHeatmap: true,
      };
    case ERROR_DEFAULT_PROBABILITY:
      return {
        ...state,
        probabilityOfDefaultData: {},
        isHeatMapEmpty: false,
        errorProbabilityDefault: true,
      };
    case ERROR_UPDATE_CHART_DATA:
      return {
        ...state,
        updateChartDataMsg: {},
        updateChartData: false,
        errorUpdateChartData: true,
      };
    default:
      return state;
  }
}
