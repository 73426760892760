import axios from "axios";

const ROOT_URL = process.env.REACT_APP_API_URL;
export const GET_USER_DATA = "GET_USER_DATA";
export const CLEAR_USER_DATA = "CLEAR_USER_DATA";
export const GET_SPECIFIC_USER_DATA = "GET_SPECIFIC_USER_DATA";
export const EDIT_USER_DATA = "EDIT_USER_DATA";
export const ERROR_EDIT_USER_DATA = "ERROR_EDIT_USER_DATA";
export const ERROR_UPLOADING_USER_PICTURE = "ERROR_UPLOADING_USER_PICTURE";
export const ERROR_UPLOADING_ORGANIZATION_PICTURE =
  "ERROR_UPLOADING_ORGANIZATION_PICTURE";
export const SUCCESS_UPLOADING_USER_PICTURE = "SUCCESS_UPLOADING_USER_PICTURE";
export const SUCCESS_UPLOADING_ORGANIZATION_PICTURE =
  "SUCCESS_UPLOADING_ORGANIZATION_PICTURE";
export const IS_FETCHING_SPECIFIC_USER_DATA = "IS_FETCHING_SPECIFIC_USER_DATA"

export const setUserData = (decodedUser) => {
  return (dispatch) => {
    dispatch({
      type: GET_USER_DATA,
      payload: decodedUser,
    });
    dispatch(getSpecificUserData());
  };
};

export const getSpecificUserData = () => {
  return (dispatch) => {
    axios
        .get(`${ROOT_URL}/user/`)
        .then((response) => {
          dispatch({
            type: GET_SPECIFIC_USER_DATA,
            payload: response.data,
          });
        });
  };
};


export const editUserData = (values) => {
  return (dispatch) => {
    return axios
        .put(`${ROOT_URL}/user/`, values)
        .then(
            (response) => {
              dispatch({
                type: EDIT_USER_DATA,
              });
              dispatch({
                type: GET_SPECIFIC_USER_DATA,
                payload: response.data,
              });
              return {type: "success", content: "user.success_user_update"}
            },
            (error) => {
              return {type: "error", content: "user.error_user_update"}
            }
        )
        .catch((error) => {
          console.error("editUserData ", error)
          return {type: "error", content: "user.error_user_update"}
        });
  };
};


export const clearUserData = () => {
  localStorage.clear();
  return {
    type: CLEAR_USER_DATA,
  };
};

export const uploadUserAvatar = (userId, file) => {
  const config = {
    headers: { "content-type": "multipart/form-data" },
  };
  let picture = new FormData();
  picture.append("file", file);
  return (dispatch) => {
    return axios
      .put(`${ROOT_URL}/avatars/${userId}/`, picture, config)
      .then(
        (response) => {
          dispatch({ type: SUCCESS_UPLOADING_USER_PICTURE });
          dispatch(getSpecificUserData());
          return response;
        },
        (error) => {
          dispatch({ type: ERROR_UPLOADING_USER_PICTURE });
          return error;
        }
      )
      .catch((error) => {
        console.error(error);
        return error;
      });
  };
};

export const uploadOrganizationPicture = (orgId, file) => {
  const config = {
    headers: { "content-type": "multipart/form-data" },
  };
  let picture = new FormData();
  picture.append("file", file);
  return (dispatch) => {
    return axios
      .put(`${ROOT_URL}/logoes/${orgId}/`, picture, config)
      .then(
        (res) => {
          dispatch({ type: SUCCESS_UPLOADING_ORGANIZATION_PICTURE });
          dispatch(getSpecificUserData());
          return res;
        },
        (error) => {
          dispatch({
            type: ERROR_UPLOADING_ORGANIZATION_PICTURE,
          });
          return error;
        }
      )
      .catch((error) => {
        console.error("uploadOrganizationPicture", error);
        return error;
      });
  };
};


export const changePassword = (old_password, new_password) => {
  return (dispatch) => {
    return axios
        .post(`${ROOT_URL}/password/`, {
          old_password,
          new_password,
        })
        .then(
            (res) => {
              return {type: "success", content: res.data.MSG};
            },
            (error) => {
                return {type: "error", content: error.response.data.MSG};
            }
        );
  };
};
