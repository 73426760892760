import {
    CLEAR_REPORTS,
    ERROR_GENERATE_REPORT,
    GENERATE_REPORT,
    GET_REPORT_LIST,
    LOAD_GENERATE_REPORT,
    REPORT_DOWNLOADED,
} from "./actions";
import _ from "lodash";

const INITIAL_STATE = {
  isGenerateReportLoading: false,
  isGenerateReportError: false,
  reportList: undefined,
  currentPageReportList: 1,
 }

 export default function ReportReducer(state = INITIAL_STATE, action) {
   switch (action.type) {
      case LOAD_GENERATE_REPORT:
        return {
          ...state,
          isGenerateReportLoading: true,
        };
      case GENERATE_REPORT:
        return {
          ...state,
          isGenerateReportLoading: false,
        };
      case ERROR_GENERATE_REPORT:
        return {
          ...state,
          isGenerateReportError: false,
        };
     case GET_REPORT_LIST:
       if (! _.isEqual(state, {
         ...state,
         reportList: action.payload.apiData,
         currentPageReportList: action.payload?.page,
       })) {
         return {
           ...state,
           reportList: action.payload.apiData,
           currentPageReportList: action.payload?.page,
         }
       } else {
         return state
       }
       case REPORT_DOWNLOADED:
           return {...state, reportList: {...state.reportList, results: [...state.reportList.results.filter(report => report.uuid !== action.payload), {...state.reportList.results.find(report => report.uuid === action.payload), downloaded : true}]}};

     case CLEAR_REPORTS:
       return INITIAL_STATE;

      default:
            return state;
    }
  }

  
  