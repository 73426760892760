import _ from "lodash";
import axios from "axios";

const ROOT_URL = process.env.REACT_APP_API_URL;
export const GET_GRAPHS = "GET_GRAPHS";
export const ERROR_GET_GRAPHS = "ERROR_GET_GRAPHS";
export const EMPTY_GRAPHS = "EMPTY_GRAPHS";

export const GET_ASSET = "GET_ASSET";
export const EMPTY_ASSET = "EMPTY_ASSET";
export const ERROR_GET_ASSET = "ERROR_GET_ASSET";

export const SET_ASSET_UUID_FROM_MENU = "SET_ASSET_UUID_FROM_MENU";

export const CLEAR_ASSETS = "CLEAR_ASSETS";
export const CLEAR_RISK_LOCATIONS = "CLEAR_RISK_LOCATIONS";
export const CLEAR_ASSET_UUID_FROM_MENU = "CLEAR_ASSET_UUID_FROM_MENU";
export const CLEAR_GRAPHS = "CLEAR_GRAPHS";

export const FETCH_INTENDED_USE_TYPES = "FETCH_INTENDED_USE_TYPES";
export const FETCH_ASSET_INTENDED_USES = "FETCH_ASSET_INTENDED_USES";
export const REMOVE_ASSET_INTENDED_USE = "REMOVE_ASSET_INTENDED_USE";
export const ADD_ASSET_INTENDED_USE = "ADD_ASSET_INTENDED_USE";
export const PATCH_ASSET_INTENDED_USE = "PATCH_ASSET_INTENDED_USE";
export const FETCH_ASSET_VALUES = "FETCH_ASSET_VALUES";

export const FETCH_ASSET_DETAILS = "FETCH_ASSET_DETAILS";

export const getAssetsDetails = (customer_uuid) => {
    return (dispatch) => {
        return axios
            .get(`${ROOT_URL}/customers/${customer_uuid}/assetsvalue/`)
            .then(
                (response) =>
                    dispatch({
                        type: FETCH_ASSET_DETAILS,
                        payload: response.data,
                    }),
                (error) => {
                    console.error("getAssetsDetails: ", error);
                    return error;
                }
            );
    };
};

export const getRisklocationAssetValues = (customer_uuid) => {
    return (dispatch) => {
        return axios
            .get(`${ROOT_URL}/customers/${customer_uuid}/risklocationassetvalues/`)
            .then(
                (response) =>
                    dispatch({
                        type: FETCH_ASSET_VALUES,
                        payload: response.data,
                    }),
                (error) => {
                    console.error("getRisklocationAssetValues: ", error);
                    return error;
                }
            );
    };
};

export const getIntendedUseTypes = () => {
    return (dispatch) => {
        return axios
            .get(`${ROOT_URL}/intendedusetypes/`)
            .then(
                (response) =>
                    dispatch({
                        type: FETCH_INTENDED_USE_TYPES,
                        payload: response.data,
                    }),
                (error) => {
                    console.error("intendedusetypes: ", error);
                    return error;
                }
            );
    };
};

export const getIntendedUses = (customer_uuid, asset_uuid) => {
    return (dispatch) => {
        return axios
            .get(`${ROOT_URL}/customers/${customer_uuid}/assets/${asset_uuid}/intendeduse/`)
            .then(
                (response) =>
                    dispatch({
                        type: FETCH_ASSET_INTENDED_USES,
                        payload: response.data,
                    }),
                (error) => {
                    console.error("getIntendedUses: ", error);
                    return error;
                }
            );
    };
};

export const addIntendedUse = (customer_uuid, asset_uuid, body) => {
    return (dispatch) => {
        return axios
            .post(`${ROOT_URL}/customers/${customer_uuid}/assets/${asset_uuid}/intendeduse/`, body)
            .then(
                (response) =>
                    dispatch({
                        type: ADD_ASSET_INTENDED_USE,
                        payload: response.data,
                    }),
                (error) => {
                    console.error("intendedusetypes: ", error);
                    return error;
                }
            );
    };
};

export const patchIntendedUse = (customer_uuid, asset_uuid, use_uuid, body) => {
    return (dispatch) => {
        return axios
            .patch(`${ROOT_URL}/customers/${customer_uuid}/assets/${asset_uuid}/intendeduse/${use_uuid}/`, body)
            .then(
                (response) =>
                    dispatch({
                        type: PATCH_ASSET_INTENDED_USE,
                        payload: {uuid: use_uuid, patch: response.data},
                    }),
                (error) => {
                    console.error("intendedusetypes: ", error);
                    return error;
                }
            );
    };
};

export const deleteIntendedUse = (customer_uuid, asset_uuid, use_uuid) => {
    return (dispatch) => {
        return axios
            .delete(`${ROOT_URL}/customers/${customer_uuid}/assets/${asset_uuid}/intendeduse/${use_uuid}/`)
            .then(
                (response) => {
                    dispatch({
                        type: REMOVE_ASSET_INTENDED_USE,
                        payload: use_uuid,
                    });
                    return response;
                },
                (error) => {
                    console.error("deleteIntendedUse: ", error);
                    return error;
                }
            );
    };
};


export const getGraphs = (cid) => {
    return (dispatch) => {
        return axios
            .get(`${ROOT_URL}/customers/${cid}/graphs/`)
            .then(
                (response) => {
                    if (_.isEmpty(response.data) && _.isEmpty(response.data.results)) {
                        dispatch({
                            type: EMPTY_GRAPHS,
                        });
                    } else {
                        dispatch({
                            type: GET_GRAPHS,
                            payload: [...response.data.results],
                        });
                    }
                },
                (error) => dispatch({type: ERROR_GET_GRAPHS})
            )
            .catch((error) => console.error("getGraphs ", error));
    };
};

/**
 *
 * @param {string} cid customer id
 * @param {string} assetId id dell"asset
 */
export const getAsset = (cid, assetId) => {
    return (dispatch) => {
        return axios
            .get(`${ROOT_URL}/customers/${cid}/assets/${assetId}/`)
            .then(
                (response) => {
                    if (_.isEmpty(response.data) && _.isEmpty(response.data)) {
                        dispatch({
                            type: EMPTY_ASSET,
                        });
                    } else {
                        dispatch({
                            type: GET_ASSET,
                            payload: {...response.data},
                        });
                    }
                },
                (error) => dispatch({type: ERROR_GET_ASSET})
            )
            .catch((error) => console.error("getAssets ", error));
    };
};

export const setAssetUuidFromMenu = (uuid) => {
    return {
        type: SET_ASSET_UUID_FROM_MENU,
        payload: uuid,
    };
};

export const clearAssets = () => {
    return {
        type: CLEAR_ASSETS,
    };
};

export const clearRiskLocations = () => {
    return {
        type: CLEAR_RISK_LOCATIONS,
    };
};

export const clearAssetUuidFromMenu = () => {
    return {
        type: CLEAR_ASSET_UUID_FROM_MENU,
    };
};

export const clearGraphs = () => {
    return {
        type: CLEAR_GRAPHS,
    };
};
