import {applyMiddleware, compose, createStore} from "redux";

import rootReducer from "./rootReducer";
import thunk from "redux-thunk";

let composeEnhancers;
if (process.env.NODE_ENV !== "production") {
  composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
} else {
  composeEnhancers = compose;
}
const store = createStore(
  rootReducer,
  composeEnhancers(applyMiddleware(thunk))
);

export default store;
