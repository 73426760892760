import {CLEAR_WEATHER, ERROR_WEATHER, IS_WEATHER_EMPTY, LOAD_WEATHER, WEATHER_FETCHED,} from "./actions";

const INITIAL_STATE = {
  weather: {},
  isWeatherEmpty: undefined,
  errorWeather: false,
  isLoading: false,
};

export default function WeatherReducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    case WEATHER_FETCHED:
      return {
        ...state,
        weather: action.payload,
        isLoading: false,
        isWeatherEmpty: false,
        errorWeather: false,
      };
    case LOAD_WEATHER:
      return {
        ...state,
        isLoading: true,
      };
    case IS_WEATHER_EMPTY:
      return {
        ...state,
        weather: {},
        isLoading: false,
        isWeatherEmpty: true,
        errorWeather: false,
      };
    case ERROR_WEATHER:
      return {
        ...state,
        weather: {},
        isLoading: false,
        isWeatherEmpty: false,
        errorWeather: true,
      };
    case CLEAR_WEATHER:
      return {
        weather: {},
        isWeatherEmpty: undefined,
        errorWeather: false,
        isLoading: false,
      };

    default:
      return state;
  }
}
