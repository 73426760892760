import {useEffect, useState} from "react";
import {Button, Col, notification, Row} from "antd";
import {useSelector} from "react-redux";
import _ from "lodash";

/*Handmade translations to keep them working offline*/
const online_notification_description = {
  it: "Connessione nuovamente disponibile. Clicca per aggiornare la pagina.",
  en: "Connection restored. Click to refresh the page.",
  de: "Verbindung wiederhergestellt. Klicken Sie, um die Seite zu aktualisieren."
};

const offline_notification_description = {
  it: "Connessione non disponibile. Si prega di controllare la connessione e riprovare.",
  en: "Connection not available. Please check your connection and try again.",
  de: "Verbindung nicht verfügbar. Bitte überprüfen Sie Ihre Verbindung und versuchen Sie es erneut."
};

const connection_notification_message = {
  it: "Stato connessione:",
  en: "Connection status:",
  de: "Verbindungsstatus:"
};

const click_to_reload_page = {
  it: "Clicca qui per ricaricare la pagina",
  en: "Click here to refresh the page",
  de: "Klicken Sie hier, um die Seite zu aktualisieren",
};


export const useConnectionCheck = () => {

  const [isOnline, setIsOnline] = useState(navigator.onLine);
  const [lang, setLang] = useState("it");

  const specificUserData = useSelector(store => store.user.specificUserData);

  useEffect(() => {
    if (!_.isEmpty(specificUserData) && specificUserData?.profile?.language) {
      setLang(specificUserData.profile.language);
    }
  }, [specificUserData]);

  useEffect(() => {
    const handleOnline = () => {
      setIsOnline(true);
      notification.close("offlineNotification");
      notification.success(
        {
          message: connection_notification_message[lang],
          description: (
            <>
              <Row justify={"space-between"} align={"middle"} wrap={false}>
                <Col flex={1}>{online_notification_description[lang]}</Col>
              </Row>
              <Row justify={"end"}>
                <Col>
                  <Button type={"link"}
                          onClick={() => window.location.reload()}>
                    {click_to_reload_page[lang]}
                  </Button>
                </Col>
              </Row>
            </>),
          key: "onlineNotification"
        });
    };

    const handleOffline = () => {
      setIsOnline(false);
      notification.close("onlineNotification");

      notification.warning({
        message: connection_notification_message[lang],
        description: offline_notification_description[lang],
        duration: null,
        key: "offlineNotification"
      });
    };

    window.addEventListener("online", handleOnline);
    window.addEventListener("offline", handleOffline);

    return () => {
      window.removeEventListener("online", handleOnline);
      window.removeEventListener("offline", handleOffline);
    };
  }, []);

  return isOnline;
};
