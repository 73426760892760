import {
    ADD_SCENARIOS,
    ARE_RISK_LOCATIONS_EMPTY,
    ARE_SCENARIOS_EMPTY,
    CLEAR_RISK_LOCATIONS,
    CLEAR_SCENARIOS,
    CREATE_ANSWER,
    DELETE_SURVEYS_RESULTS,
    ERROR_ADDING_SCENARIOS,
    ERROR_GETTING_RISK_LOCATIONS,
    ERROR_GETTING_SCENARIOS,
    FETCH_ASSETS, FETCH_CONTENT_ROWS_TOTAL_SUM,
    FETCH_CUSTOMER_SURVEY,
    FETCH_CUSTOMER_SURVEY_TYPE,
    FETCH_CUSTOMER_SURVEYS,
    FETCH_CUSTOMER_SURVEYS_FILTERED,
    FETCH_SURVEY_RESULT,
    FETCH_SURVEY_RESULTS,
    GET_RISK_LOCATIONS,
    GET_SCENARIOS,
    PATCH_SURVEY_RESULT,
    UPDATE_CUSTOMER_SURVEY,
    UPDATE_SURVEYS_RESULTS
} from "./actions";

const INITIAL_STATE = {
    scenarios: undefined,
    errorGettingScenarios: undefined,
    areScenariosEmpty: undefined,
    errorAddingScenarios: undefined,
    riskLocations: undefined,
    errorGettingRiskLocations: undefined,
    areRiskLocationsEmpty: undefined,
    surveyType: [],
    all: null,
    survey: null,
    surveyDevice: null,
    surveys: null,
    createAnswer: "",
    surveyResults: null,
    surveyResult: {},
    assets: [],
    contentRowsTotalSumFilterdByRiskLocation: [],
};

export default function ScenariosReducer(state = INITIAL_STATE, action) {
    switch (action.type) {
        case FETCH_CONTENT_ROWS_TOTAL_SUM:
            return {...state, contentRowsTotalSumFilterdByRiskLocation: action.payload};
        case FETCH_ASSETS:
            return {...state, assets: action.payload};
        case ADD_SCENARIOS:
            return {
                ...state,
                scenarios: [...action.scenarios],
                errorAddingScenarios: false,
            };
        case ERROR_ADDING_SCENARIOS:
            return {
                ...state,
                scenarios: [],
                errorAddingScenarios: true,
            };
        case GET_SCENARIOS:
            return {
                ...state,
                scenarios: [...action.scenarios],
                errorGettingScenarios: false,
                areScenariosEmpty: false,
            };
        case ERROR_GETTING_SCENARIOS:
            return {
                ...state,
                scenarios: [],
                errorGettingScenarios: true,
                areScenariosEmpty: false,
            };
        case ARE_SCENARIOS_EMPTY:
            return {
                ...state,
                scenarios: [],
                errorGettingScenarios: false,
                areScenariosEmpty: true,
            };
        case GET_RISK_LOCATIONS:
            return {
                ...state,
                riskLocations: [...action.riskLocations],
                errorGettingRiskLocations: false,
                areRiskLocationsEmpty: false,
            };
        case ERROR_GETTING_RISK_LOCATIONS:
            return {
                ...state,
                riskLocations: {},
                errorGettingRiskLocations: true,
                areRiskLocationsEmpty: false,
            };
        case ARE_RISK_LOCATIONS_EMPTY:
            return {
                ...state,
                riskLocations: {},
                errorGettingRiskLocations: false,
                areRiskLocationsEmpty: true,
            };
        case CLEAR_RISK_LOCATIONS:
            return {
                ...state,
                riskLocations: undefined,
                errorGettingRiskLocations: undefined,
                areRiskLocationsEmpty: undefined,
            };
        case CLEAR_SCENARIOS:
            return {
                ...state,
                scenarios: undefined,
                errorGettingScenarios: undefined,
                areScenariosEmpty: undefined,
            };

        case FETCH_CUSTOMER_SURVEY_TYPE:
            return {...state, surveyType: action.payload};
        case FETCH_CUSTOMER_SURVEYS:
            return {...state, all: action.payload};
        case FETCH_CUSTOMER_SURVEYS_FILTERED:
            return {...state, surveys: action.payload};
        case FETCH_CUSTOMER_SURVEY:
            return {...state, survey: action.payload};
        case UPDATE_CUSTOMER_SURVEY:
            return {...state, survey: action.payload};
        case CREATE_ANSWER:
            return {...state, createAnswer: action.payload};

        case FETCH_SURVEY_RESULTS:
            return {...state, surveyResults: action.payload};
        case UPDATE_SURVEYS_RESULTS:
            return {...state, surveyResults: [...state.surveyResults, action.payload]};
        case DELETE_SURVEYS_RESULTS:
            return {...state, surveyResults: state.surveyResults.filter(survey => survey.uuid !== action.payload)};
        case PATCH_SURVEY_RESULT:
            return {...state, surveyResult: {...state.surveyResult, ...action.payload}};
        case FETCH_SURVEY_RESULT:
            return {...state, surveyResult: action.payload};
        default:
            return state;
    }
}
