import React from "react";
import {T} from "react-polyglot-hooks";
import {MemoSingleCard} from "../../common/components/SingleCard";

const GuideLines = ({modal}) => {
  if (modal) {
    return (
        <div className="card-content">
          <T phrase="user.guides"/>
        </div>
    );
  } else {
    return (
        <MemoSingleCard title={<T phrase="user.how_to_start"/>} style={{flexGrow: 1}}>
          <div style={{padding: "1rem"}}>
            <T phrase="user.no_guides"/>
          </div>
        </MemoSingleCard>
    );
  }
};

export default GuideLines;
