import React, {memo} from "react";
import {Card, Col, Row} from "antd";

const SingleCard = ({title = null, extra = null, children = null, footer = null, style = {}, loading=false}) => {
    return (
        <Card title={<div style={{marginTop: "0.2rem", marginBottom: "0.2rem"}}>{title}</div>}
              loading={loading}
              extra={extra}
              bordered={true}
              style={{...style, borderRadius: "5px", border: "1px solid #dedede"}}
              actions={footer ? [<Row justify={"end"} style={{padding: "0 1rem", backgroundColor: "transparent"}}><Col>{footer}</Col></Row>] : null}
              headStyle={{backgroundColor: "rgb(250 250 250)", borderRadius: "5px 5px 0 0", padding: "0 1rem"}}
              bodyStyle={{borderRadius: "0 0 5px 5px"}}
        >
            {children && children}
        </Card>
    );
}

export const MemoSingleCard = memo(SingleCard);