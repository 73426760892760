import React, {Suspense, useEffect, useState} from "react";
import "./constructionvalue/components/akane/css/glyphicons.css";
import "antd/dist/antd.css";
import "./style/antd-modifications.css";
import "./style/yourisk-style.css";
import {Redirect, Route, Switch, useHistory} from "react-router-dom";
import axios from "axios";
import {useDispatch, useSelector} from "react-redux";
import store from "./store";
import {loginWithToken, serverDown, tokenExpired, userUnauthorized} from "./login/actions";
import {I18n, T} from "react-polyglot-hooks";
import translation from "./locale/translations.json";
import _ from "lodash";
import {Alert, Button, Layout, message, Result} from "antd";
import {Sidebar} from "./common/components/Sidebar";
import Loading from "./common/components/Loading";
import {ReportPooling} from "./common/ReportPooling";
import {TokenExpiredModal} from "./login/components/TokenExpiredModal";
import {UserUnauthorizedModal} from "./login/components/UserUnauthorizedModal";
import {useConnectionCheck} from "./common/customHooks/useConnectionCheck";


(() => {
    axios.interceptors.request.use(
        config => {
            const token = localStorage.getItem("token");
            if (token) {
                config.headers.Authorization = "JWT " + token;
            }
            return config;
        },
        error => {
            return Promise.reject(error);
        });


    axios.interceptors.response.use(
        response => response,
        error => {
            if (error.response === undefined) {
                store.dispatch(serverDown());
                //store.dispatch(logout());
            } else {
                if (error.response.status === 401 && error.response.config.url.split("/").at(-2) !== "get-token") {
                    store.dispatch(tokenExpired());
                } else if (error.response.status === 403) {
                    store.dispatch(userUnauthorized);
                }
            }
            return Promise.reject(error);
        });
})();

const LoginView = React.lazy(() => import("./views/LoginView"));

const Customers = React.lazy(() => import("./views/CustomersView"));

const Dashboard = React.lazy(() => import("./views/DashboardView"));

const UserPage = React.lazy(() => import("./views/UserView"));

const InsuranceManagement = React.lazy(() => import("./views/InsuranceManagementView"));

const ConstructionsValue = React.lazy(() => import("./views/ConstructionsValueView"));

const ContentVal = React.lazy(() => import("./views/ContentValueAppraisalsView"));

const ContentValWizard = React.lazy(() => import("./views/ContentValueWizardView"));

const GrossProfitView = React.lazy(() => import("./views/GrossProfitView"));

const Survey = React.lazy(() => import("./views/SurveyView"));

function App() {
    const dispatch = useDispatch();
    const history = useHistory();
    const {ErrorBoundary} = Alert;
    const connectionCheck = useConnectionCheck();

    const token = useSelector(store => store.login.token) || localStorage.getItem("token");
    const specificUserData = useSelector(store => store.user.specificUserData);
    const isTokenExpired = useSelector(store => store.login.hasTokenExpired);

    const [lang, setLang] = useState("it");
    const [collapsed, setCollapsed] = useState(true);

    useEffect(() => {
        message.config({maxCount: 1});
        if (!token) {
            if (history && history.location.pathname !== "/login/")
                history.push("/login/");
        } else {
            dispatch(loginWithToken(token));
        }
    }, [dispatch, history, token]);

    useEffect(() => {
        if (!_.isEmpty(specificUserData) && specificUserData?.profile?.language) {
            setLang(specificUserData.profile.language);
        }
    }, [specificUserData]);

    /*this useEffect hides the application whether if it contained in an iframe*/
    useEffect(()=> {
        if (window.self !== window.top) {
            document.getElementById("anticlickjack").className = "clickjackCountermeasure";
            window.top.location = window.self.location;
        }
    }, []);

    return (
        <I18n locale={lang} phrases={translation[lang]}>
            <div id={"anticlickjack"}>
                <Layout>
                    <ErrorBoundary style={{height: "100%", backgroundColor: "#fff", border: "none"}}
                                   description={null}
                                   message={<Result status="500"
                                                    title="Oops!"
                                                    subTitle={<T phrase={"general.something_went_wrong"}/>}
                                                    extra={<Button className={"primary-btn"}
                                                                   type="primary"
                                                                   onClick={() => history.go(0)}>
                                                        {<T phrase={"general.reload_page"}/>}
                                                    </Button>}/>}>
                        {isTokenExpired && <TokenExpiredModal/>}
                        <UserUnauthorizedModal/>
                        {token && <Sidebar isOpen={collapsed} setIsOpen={setCollapsed}/>}
                        {token && <ReportPooling/>}
                        <Layout style={{height: "100vh", overflow: "hidden"}}>
                            <Suspense
                                fallback={<div style={{height: "100vh", textAlign: "center", marginTop: "50vh"}}>
                                    <Loading/>
                                </div>}>
                                <Switch>
                                    <Route exact path="/login" component={LoginView}/>
                                    <Route exact path="/customers" component={Customers}/>
                                    <Route exact path="/customers/:customerId" component={Dashboard}/>
                                    <Route exact path="/user/" component={UserPage}/>
                                    <Route exact path="/customers/:customerId/scenarios"
                                           component={InsuranceManagement}/>
                                    <Route exact path="/customers/:customerId/construction-value"
                                           component={ConstructionsValue}/>
                                    <Route exact path="/customers/:customerId/content-value" component={ContentVal}/>
                                    <Route exact path="/customers/:customerId/content-value/:appraisalId"
                                           component={ContentValWizard}/>
                                    <Route exact path="/customers/:customerId/gross-Profit"
                                           component={GrossProfitView}/>
                                    <Route exact path="/customers/:customerId/survey/:surveyId" component={Survey}/>
                                    <Route path={"*"}>
                                        <Redirect to={"/customers/"}/>
                                    </Route>
                                </Switch>
                            </Suspense>
                        </Layout>
                    </ErrorBoundary>
                </Layout>
            </div>
        </I18n>
    );
}

export default App;
