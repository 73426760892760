import axios from "axios";

export const FETCH_DOCUMENTS = "FETCH_DOCUMENTS";
export const RESET_DOCUMENTS = "RESET_DOCUMENTS";
export const DELETE_DOCUMENT = "DELETE_DOCUMENT";
export const UPLOAD_DOCUMENT = "UPLOAD_DOCUMENT";
export const FETCH_DOCUMENT_CATEGORIES = "FETCH_DOCUMENT_CATEGORIES";
export const SET_DOCUMENT_CATEGORY = "SET_DOCUMENT_CATEGORY";
export const UPDATE_DOCUMENT = "UPDATE_DOCUMENT";
export const ERROR_UPDATE_DOCUMENT = "ERROR_UPDATE_DOCUMENT";

export const ADD_CUSTOMER_DOCUMENT = "ADD_CUSTOMER_DOCUMENT";

const ROOT_URL = process.env.REACT_APP_API_URL;

export function fetchCustomerDocuments(cID) {
  return (dispatch) => {
    return axios
      .get(`${ROOT_URL}/customers/${cID}/documents/`)
      .then((res) => {
        dispatch({
          type: FETCH_DOCUMENTS,
          payload: res.data,
        });
        return res;
      })
      .catch((error) => {
        return { status: error.status };
      });
  };
}

export function addCustomerDocument(document) {
  if (document) {
    return (dispatch) => {
      dispatch({type: ADD_CUSTOMER_DOCUMENT, payload: document});
    };
  }

}

export function deleteCustomerDocument(cID, dID) {
  return (dispatch) => {
    return axios
      .delete(`${ROOT_URL}/customers/${cID}/documents/${dID}/`)
      .then((res) => {
        dispatch({
          type: DELETE_DOCUMENT,
          payload: dID,
        });
        return res;
      })
      .catch((error) => {
        return { status: error.status };
      });
  };
}

export function updateDocument(cid, documentId, docData) {
  return (dispatch) => {
    return axios
      .patch(`${ROOT_URL}/customers/${cid}/documents/${documentId}/`, docData)
      .then((res) => {
        dispatch({
          type: UPDATE_DOCUMENT,
          payload: res
        });
        return res;
      })
      .catch((error) => {
        dispatch({
          type: ERROR_UPDATE_DOCUMENT,
        });
      });
  };
}

export function resetDocuments() {
  return (dispatch) => {
    dispatch({
      type: RESET_DOCUMENTS,
      payload: null,
    });
  };
}

export function downloadDocument(cid, did) {
  return (dispatch) => {
    return axios
      .get(`${ROOT_URL}/customers/${cid}/documents/${did}/download/`, {
        responseType: "arraybuffer",
      })
      .then((res) => {
        const blob = new Blob([res.data], {
          type: res.headers["content-type"],
        });
        let n = window.open(URL.createObjectURL(blob));
        // if it"s active pop-up blocker or adblock it opens the window in the same page
        if (n === null) {
          window.open(URL.createObjectURL(blob), "_self");
        }
        window.setTimeout(() => {
          if (
            res.headers["content-type"] === "application/pdf" &&
            (!n || n.closed || typeof n.closed === "undefined")
          ) {
            window.open(URL.createObjectURL(blob), "_self");
          }
        }, 1000);

        return res;
      })
      .catch((error) => {
        return { status: error.status };
      });
  };
}

export function fetchDocumentCategories(cid) {
  // ! TEMPORANEE INTANTO CHE MANCANO LE API
  const categories = [
    { uuid: "1", category: "Prima categoria" },
    { uuid: "2", category: "Seconda categoria" },
    { uuid: "3", category: "Terza categoria" },
  ];
  return {
    type: FETCH_DOCUMENT_CATEGORIES,
    payload: [...categories],
  };
}

export function setDocumentCategory(cid, documentUuid, categoryUuid) {
  // ! MANCANO API
  return {
    type: SET_DOCUMENT_CATEGORY,
  };
}
