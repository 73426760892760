import axios from "axios";
import jwtDecode from "jwt-decode";
import {setUserData} from "../user/actions";
import moment from "moment";
import "moment/locale/it";
import "moment/locale/en-gb";

const ROOT_URL = process.env.REACT_APP_API_URL;

export const LOGIN_USER_SUCCESS = "LOGIN_USER_SUCCESS";
export const LOGIN_USER_FAILURE = "LOGIN_USER_FAILURE";
export const USER_LOGOUT = "USER_LOGOUT";
export const SERVER_DOWN = "SERVER_DOWN";
export const START_LOGIN = "START_LOGIN";
export const SET_TOKEN = "SET_TOKEN";

export const TOKEN_EXPIRED = "TOKEN_EXPIRED";

export const USER_UNAUTHORIZED = "USER_UNAUTHORIZED";


export const login = (user, password) => {
  return (dispatch) => {
    dispatch({ type: START_LOGIN });
    return axios
      .post(`${ROOT_URL}/get-token/`, {
        username: user,
        password: password,
      })
      .then(
        (response) => {
          localStorage.setItem("token", response.data.token);
          dispatch({ type: SET_TOKEN, token: response.data.token,});
          return response.data.token;
        },
        (error) => {
          dispatch(loginFailure(error));
        }
      )
      .catch((error) => {
        console.error("LOGIN ERROR", error);
      });
  };
};

export const loginWithToken = (token) => {
  const decoded = jwtDecode(token);
  moment.locale(decoded.language);
  return (dispatch) => {
    dispatch({
      type: LOGIN_USER_SUCCESS,
      token: token,
      decodedUser: { ...decoded },
    });
    dispatch(setUserData(decoded));
  };
};

const loginFailure = (error) => {
  return (dispatch) => {
    if (!error.response) {
      dispatch(serverDown());
      return error;
    } else {
      dispatch({
        type: LOGIN_USER_FAILURE,
        errorMessageLogin: true,
      });
      return error;
    }
  };
};

export const logout = () => {
  localStorage.clear();
  return {
    type: USER_LOGOUT,
  };
};

export const serverDown = () => {
  return (dispatch) => {
    dispatch({
      type: SERVER_DOWN,
    });
  };
};

export const tokenExpired = () => {
  return (dispatch) => {
    dispatch({
      type: TOKEN_EXPIRED,
      payload: true
    });
    return true;
  };
};

export const userUnauthorized = (status) => {
  return (dispatch) => {
    dispatch({
      type: USER_UNAUTHORIZED,
      payload: status
    });
  };
};

export const resetTokenExpired = () => {
  return (dispatch) => {
    dispatch({
      type: TOKEN_EXPIRED,
      payload: false
    });
    return false;
  };
};
