import {
    EMPTY_CID,
    EMPTY_GET_USERS_LIST,
    END_ONBOARDING,
    ERROR_GET_USERS_LIST,
    GET_COUNTRIES,
    GET_CURRENCIES,
    GET_ISIC,
    GET_SEASONALITIES,
    GET_USERS_LIST,
    IS_FETCHING_COUNTRIES,
    IS_FETCHING_CURRENCIES,
    IS_FETCHING_ISIC,
    IS_FETCHING_SEASONALITIES,
    SET_CID,
    SET_CUSTOMERS_ARCHIVED_FILTER,
    SET_CUSTOMERS_NAME_FILTER,
    SET_CUSTOMERS_ORDER_FILTER,
    SET_FULLSCREEN_MODE,
    SET_LATEST_REPORT,
    SET_ONBOARDING_INDEX,
    SET_SIDEBAR_OPEN,
    START_ONBOARDING
} from "./actions";

const INITIAL_STATE = {
  countries: [],
  isFetchingCountries: false,
  isic: [],
  isFetchingIsic: false,
  seasonalities: [],
  isFetchingSeasonalities: false,
  currencies: [],
  isFetchingCurrencies: false,
  cid: null,
  usersList: [],
  errorUsersList: false,
  emptyUsersList: false,
  isSidebarOpen: false,
  isInFullScreenMode: false,
  latestReport: undefined,
  customersOrderFilter:  localStorage.getItem("customersOrderFilter") || "name",
  customersArchivedFilter: localStorage.getItem("customersArchivedFilter") === "true",
  customersNameFilter: "",
  isOnboardingRunning: false,
  onboardingIndex: 0,
};

export default function CommonReducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    case SET_ONBOARDING_INDEX:
      return {...state, onboardingIndex: action.payload};
    case START_ONBOARDING:
      return {...state, isOnboardingRunning: true, onboardingIndex: 0};
    case END_ONBOARDING:
      return {...state, isOnboardingRunning: false, onboardingIndex: 0};
    case SET_FULLSCREEN_MODE:
      return {
        ...state,
        isInFullScreenMode: action.payload,
      }
    case SET_SIDEBAR_OPEN:
      return {
        ...state,
        isSidebarOpen: action.payload,
      };
    case SET_CID:
      return {
        ...state,
        cid: action.payload,
      };
    case EMPTY_CID:
      return {
        ...state,
        cid: null,
      };
    case GET_COUNTRIES:
      return {
        ...state,
        countries: [...action.payload],
        isFetchingCountries: false,
      };
    case IS_FETCHING_COUNTRIES:
      return {
        ...state,
        isFetchingCountries: true,
      };
    case GET_ISIC:
      return {
        ...state,
        isic: [...action.payload],
        isFetchingIsic: false,
      };
    case IS_FETCHING_ISIC:
      return {
        ...state,
        isFetchingIsic: true,
      };
    case GET_SEASONALITIES:
      return {
        ...state,
        seasonalities: { ...action.payload },
        isFetchingSeasonalities: false,
      };
    case IS_FETCHING_SEASONALITIES:
      return {
        ...state,
        isFetchingSeasonalities: true,
      };
    case GET_CURRENCIES:
      return {
        ...state,
        currencies: [...action.payload],
        isFetchingCurrencies: false,
      };
    case IS_FETCHING_CURRENCIES:
      return {
        ...state,
        isFetchingCurrencies: true,
      };
    case GET_USERS_LIST:
      return {
        ...state,
        usersList: [...action.payload],
        errorUsersList: false,
        emptyUsersList: false,
      };
    case EMPTY_GET_USERS_LIST:
      return {
        ...state,
        usersList: [],
        errorUsersList: false,
        emptyUsersList: true,
      };
    case ERROR_GET_USERS_LIST:
      return {
        ...state,
        usersList: [],
        errorUsersList: true,
        emptyUsersList: false,
      };
    case SET_LATEST_REPORT:
      return {
        ...state,
        latestReport: action.payload
      }
    case SET_CUSTOMERS_ORDER_FILTER:
      return {
        ...state,
        customersOrderFilter: action.value
      }
    case SET_CUSTOMERS_ARCHIVED_FILTER:
      return {
        ...state,
        customersArchivedFilter: action.value
      }
    case SET_CUSTOMERS_NAME_FILTER:
      return {
        ...state,
        customersNameFilter: action.value
      }
    default:
      return state;
  }
}
