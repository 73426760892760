import {
    ADD_CUSTOMER,
    ADD_CUSTOMER_CONTACT,
    ADD_CUSTOMER_CONTACT_ERROR,
    ADD_CUSTOMER_ERROR, ADD_CUSTOMER_PICTURES,
    ADDING_CUSTOMER,
    ADDING_CUSTOMER_CONTACT,
    AUTOPILOT_STATUS,
    CANDIDATES_LOADING,
    CLEAN_PIVA_DUPLICATE,
    CLEAR_AUTOPILOT,
    CLEAR_CUSTOMER_CONTACT,
    CLEAR_CUSTOMER_DATA,
    CLEAR_CUSTOMER_PICTURES,
    DELETE_CUSTOMER_PIC,
    EMPTY_ARCHIVED_CUSTOMERS,
    EMPTY_AUTOPILOT_STATUS,
    EMPTY_CANDIDATES,
    EMPTY_NOT_ARCHIVED_CUSTOMERS,
    ERROR_AUTOPILOT_STATUS,
    ERROR_CANDIDATES,
    ERROR_GET_CUSTOMER,
    ERROR_GET_CUSTOMER_CONTACT,
    ERROR_GET_CUSTOMERS,
    ERROR_UPDATE_CUSTOMER,
    ERROR_UPLOADING_CUSTOMER_PICTURE,
    FETCH_PICTURE_TYPES,
    GET_CANDIDATES,
    GET_CUSTOMER,
    GET_CUSTOMER_CONTACT,
    GET_CUSTOMER_PICTURES,
    GET_CUSTOMERS,
    IS_FETCHING_CUSTOMER_PICTURES,
    LOAD_CUSTOMERS, PATCH_CUSTOMER_PICTURE,
    PIC_UPLOADED,
    PIC_UPLOADED_DONE,
    PIVA_DUPLICATE,
    RESET_SAVE_CHANGES,
    SAVE_CHANGES,
    SELECTED_AUTOPILOT_UUID,
    SET_SHOW_AUTO_ADD_CUSTOMER_MODAL,
    SET_USE_CUSTOMERS_FILTER_NAME,
    START_AUTOPILOT,
    STOP_ADDING_CUSTOMER,
    STOP_AUTOPILOT,
    SUCCESS_UPLOADING_CUSTOMER_PICTURE,
    UPDATE_CUSTOMER,
    UPDATE_STATE,
    UPDATING_CUSTOMER
} from "./actions";

const INITIAL_STATE = {
    customerIsAdded: false,
    addCustomerContactError: undefined,
    addingCustomerContact: undefined,
    customerContact: undefined,
    errorGetCustomerContact: undefined,
    emptyCustomerContact: undefined,
    selectedAutopilotUuid: "",
    customers: {},
    errorGetCustomers: false,
    customer: {},
    errorGetCustomer: false,
    emptyArchivedCustomers: undefined,
    emptyCustomers: undefined,
    addCustomerError: false,
    addCustomerPivaError: false,
    isLoading: true,
    addingCustomer: false,
    updatingCustomer: false,
    errorUpdatingCustomer: false,
    changesSaved: undefined,
    errorUploadingCustomerPicture: false,
    customerPictures: [],
    isFetchingCustomerPictures: undefined,
    candidates: [],
    candidatesLoading: false,
    errorCandidates: false,
    emptyCandidates: false,
    autopilotLoading: undefined,
    autopilotFinished: false,
    autopilotStatus: {},
    emptyAutopilotStatus: false,
    errorAutopilotStatus: false,
    pictureTypes: [],
    pictureDelete: false,
    pictureUploading: false,
    showAutoAddCustomerModal: false,
    useCustomersFilterName: false,
    status: 1
};

export default function CustomersReducer(state = INITIAL_STATE, action) {
    switch (action.type) {
        case SELECTED_AUTOPILOT_UUID:
            return {
                ...state,
                selectedAutopilotUuid: action.payload,
            };
        case LOAD_CUSTOMERS:
            return {
                ...state,
                isLoading: true,
            };
        case GET_CUSTOMER_CONTACT:
            return {
                ...state,
                //isLoading: false,
                customerContact: {...action.customerContact},
                errorGetCustomerContact: false,
                emptyCustomerContact: false,
            };
        case GET_CUSTOMERS:
            return {
                ...state,
                isLoading: false,
                customers: {...action.customers},
                errorGetCustomers: false,
                emptyCustomers: false,
                emptyArchivedCustomers: false,
            };
        case ERROR_GET_CUSTOMERS:
            return {
                ...state,
                customers: {},
                isLoading: false,
                errorGetCustomers: true,
            };
        case GET_CUSTOMER:
            return {
                ...state,
                isLoading: false,
                customer: {...action.customer},
                errorGetCustomer: false,
            };
        case CLEAR_CUSTOMER_DATA:
            return {
                ...state,
                customer: {},
                errorGetCustomer: false,
            };
        case CLEAR_CUSTOMER_CONTACT:
            return {
                ...state,
                addCustomerContactError: undefined,
                addingCustomerContact: undefined,
                customerContact: undefined,
                errorGetCustomerContact: undefined,
                emptyCustomerContact: undefined,
            };
        case EMPTY_NOT_ARCHIVED_CUSTOMERS:
            return {
                ...state,
                isLoading: false,
                emptyCustomers: true,
                customers: [],
                emptyArchivedCustomers: false,
            };
        case EMPTY_ARCHIVED_CUSTOMERS:
            return {
                ...state,
                isLoading: false,
                customers: [],
                emptyArchivedCustomers: true,
                emptyCustomers: false,
            };
        case ERROR_GET_CUSTOMER:
            return {
                ...state,
                isLoading: false,
                errorGetCustomer: true,
            };
        case ERROR_GET_CUSTOMER_CONTACT:
            return {
                ...state,
                //isLoading: false,
                errorGetCustomerContact: true,
            };
        case ADD_CUSTOMER:
            return {
                ...state,
                addCustomerError: false,
                addingCustomer: false,
                addCustomerPivaError: false,
                customerIsAdded: true,
            };
        case ADD_CUSTOMER_CONTACT:
            return {
                ...state,
                addCustomerContactError: false,
                addingCustomerContact: false,
                addCustomerPivaError: false,
                customerContact: {...action.payload}
            };
        case ADD_CUSTOMER_CONTACT_ERROR:
            return {
                ...state,
                addCustomerContactError: true,
            };
        case ADDING_CUSTOMER_CONTACT:
            return {
                ...state,
                addingCustomerContact: true,
            };
        case ADD_CUSTOMER_ERROR:
            return {
                ...state,
                addCustomerError: true,
                customerIsAdded: false,
            };
        case ADDING_CUSTOMER:
            return {
                ...state,
                addingCustomer: true,
            };

        case STOP_ADDING_CUSTOMER:
            return {
                ...state,
                addingCustomer: false,
            };
        case PIVA_DUPLICATE:
            return {
                ...state,
                addCustomerPivaError: true,
            };
        case UPDATING_CUSTOMER:
            return {
                ...state,
                errorUpdatingCustomer: false,
                updatingCustomer: true,
            };
        case UPDATE_CUSTOMER:
            return {
                ...state,
                errorUpdatingCustomer: false,
                updatingCustomer: false,
            };
        case ERROR_UPDATE_CUSTOMER:
            return {
                ...state,
                errorUpdatingCustomer: true,
                updatingCustomer: false,
            };
        case SAVE_CHANGES:
            return {
                ...state,
                changesSaved: true,
                updatingCustomer: false,
            };
        case RESET_SAVE_CHANGES:
            return {
                ...state,
                changesSaved: false,
            };
        case SUCCESS_UPLOADING_CUSTOMER_PICTURE:
            return {
                ...state,
                updatingCustomer: false,
                errorUploadingCustomerPicture: false,
            };

        case ERROR_UPLOADING_CUSTOMER_PICTURE:
            return {
                ...state,
                updatingCustomer: false,
                errorUploadingCustomerPicture: true,
            };
        case GET_CUSTOMER_PICTURES:
            return {
                ...state,
                customerPictures: [...action.payload],
                isFetchingCustomerPictures: false,
            };
        case ADD_CUSTOMER_PICTURES:
            return {
                ...state,
                customerPictures: [...state.customerPictures, ...action.payload],
                isFetchingCustomerPictures: false,
            };
        case PATCH_CUSTOMER_PICTURE:
            return {
                ...state,
                customerPictures: state.customerPictures.map(picture => picture.uuid === action.payload.uuid ? {...picture, type: action.payload.type}: picture),
            };
        case IS_FETCHING_CUSTOMER_PICTURES:
            return {
                ...state,
                isFetchingCustomerPictures: true,
            };
        case CLEAR_CUSTOMER_PICTURES:
            return {
                ...state,
                customerPictures: [],
            };
        case GET_CANDIDATES:
            return {
                ...state,
                candidates: [...action.payload],
                errorCandidates: false,
                emptyCandidates: false,
                candidatesLoading: false,
            };
        case CANDIDATES_LOADING:
            return {
                ...state,
                candidatesLoading: true,
            };
        case ERROR_CANDIDATES:
            return {
                ...state,
                candidates: [],
                errorCandidates: true,
                emptyCandidates: false,
                candidatesLoading: false,
            };
        case EMPTY_CANDIDATES:
            return {
                ...state,
                candidates: [],
                errorCandidates: false,
                emptyCandidates: true,
                candidatesLoading: false,
            };
        case START_AUTOPILOT:
            return {
                ...state,
                autopilotLoading: true,
                autopilotFinished: false,
            };
        case STOP_AUTOPILOT:
            return {
                ...state,
                autopilotLoading: false,
                autopilotFinished: true,
            };
        case AUTOPILOT_STATUS:
            return {
                ...state,
                autopilotStatus: {...action.payload},
                emptyAutopilotStatus: false,
                errorAutopilotStatus: false,
            };
        case EMPTY_AUTOPILOT_STATUS:
            return {
                ...state,
                autopilotStatus: {},
                emptyAutopilotStatus: true,
                errorAutopilotStatus: false,
            };
        case ERROR_AUTOPILOT_STATUS:
            return {
                ...state,
                autopilotStatus: {},
                emptyAutopilotStatus: false,
                errorAutopilotStatus: true,
            };
        case CLEAR_AUTOPILOT:
            return {
                ...state,
                autopilotStatus: {},
                emptyAutopilotStatus: false,
                errorAutopilotStatus: false,
                selectedAutopilotUuid: "",
            };
        case CLEAN_PIVA_DUPLICATE:
            return {
                ...state,
                addCustomerPivaError: false,
            };
        case FETCH_PICTURE_TYPES:
            return {...state, pictureTypes: action.payload};

        case PIC_UPLOADED:
            return {...state, pictureUploading: true};

        case PIC_UPLOADED_DONE:
            return {...state, pictureUploading: false};

        case DELETE_CUSTOMER_PIC:
            return {
                ...state,
                pictureDelete: true,
                customerPictures: state.customerPictures.filter(image => image.uuid !== action.payload)
            };

        case SET_SHOW_AUTO_ADD_CUSTOMER_MODAL:
            return {...state, showAutoAddCustomerModal: action.payload};

        case UPDATE_STATE:
            return {
                ...state,
                customer: {
                    ...state.customer,
                    status: action.payload
                }
            };
        case SET_USE_CUSTOMERS_FILTER_NAME:
            return {
                ...state,
                useCustomersFilterName: action.payload
            };
        default:
            return state;
    }
}
