import {
    CLEAR_USER_DATA,
    EDIT_USER_DATA,
    ERROR_EDIT_USER_DATA,
    ERROR_UPLOADING_ORGANIZATION_PICTURE,
    ERROR_UPLOADING_USER_PICTURE,
    GET_SPECIFIC_USER_DATA,
    GET_USER_DATA,
    IS_FETCHING_SPECIFIC_USER_DATA,
    SUCCESS_UPLOADING_ORGANIZATION_PICTURE,
    SUCCESS_UPLOADING_USER_PICTURE,
} from "./actions";

const INITIAL_STATE = {
  userData: {},
  specificUserData: {},
  isFetchingSpecificUserData: false,
  errorEditUserData: false,
  errorUploadUserPicture: false,
  errorUploadingOrganizationPicture: false,
};

export default function UserReducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    case GET_USER_DATA:
      return {
        ...state,
        userData: action.payload,
        errorEditUserData: false,
        errorUploadUserPicture: false,
      };
    case CLEAR_USER_DATA: {
      return {
        ...state,
        userData: {},
        specificUserData: {},
        errorEditUserData: false,
        errorUploadUserPicture: false,
      };
    }
    case GET_SPECIFIC_USER_DATA:
      return {
        ...state,
        isFetchingSpecificUserData: false,
        specificUserData: { ...action.payload },
        errorEditUserData: false,
        errorUploadUserPicture: false,
      };
    case IS_FETCHING_SPECIFIC_USER_DATA:
      return {
        ...state,
        isFetchingSpecificUserData: true
      };
    case EDIT_USER_DATA:
      return {
        ...state,
        errorEditUserData: false,
        errorUploadUserPicture: false,
      };
    case ERROR_EDIT_USER_DATA:
      return {
        ...state,
        errorEditUserData: true,
      };
    case ERROR_UPLOADING_USER_PICTURE:
      return {
        ...state,
        errorUploadUserPicture: true,
      };
    case SUCCESS_UPLOADING_USER_PICTURE:
      return {
        ...state,
        errorUploadUserPicture: false,
      };
    case ERROR_UPLOADING_ORGANIZATION_PICTURE:
      return {
        ...state,
        errorUploadingOrganizationPicture: true,
      };
    case SUCCESS_UPLOADING_ORGANIZATION_PICTURE:
      return {
        ...state,
        errorUploadingOrganizationPicture: false,
      };
    default:
      return state;
  }
}
