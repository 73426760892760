import axios from "axios";
import _ from "lodash";

export const FETCH_GROSS_PROFIT_DATA = "FETCH_GROSS_PROFIT_DATA";
export const UPDATE_GROSS_PROFIT_DATA = "UPDATE_GROSS_PROFIT_DATA";
export const ERROR_GROSS_PROFIT_DATA = "ERROR_GROSS_PROFIT_DATA";
export const IS_GROSS_PROFIT_EMPTY = "IS_GROSS_PROFIT_EMPTY";
export const CLEAR_GROSS_PROFIT_DATA = "CLEAR_GROSS_PROFIT_DATA";

const ROOT_URL = process.env.REACT_APP_API_URL;

export const fetchGrossProfitData = (cid) => {
  return (dispatch) => {
    return axios.get(`${ROOT_URL}/customers/${cid}/grossprofitdata/last/`)
        .then((res) => {
              if (!_.isEmpty(res.data)) {
                dispatch({
                  type: FETCH_GROSS_PROFIT_DATA,
                  payload: res.data
                });
              } else {
                dispatch({
                  type: IS_GROSS_PROFIT_EMPTY,
                });
              }
              return res;
            },
            (error) => {
              dispatch({
                type: ERROR_GROSS_PROFIT_DATA,
              });
            })
        .catch((error) => {
          console.log(error)
        });
  }
}

export const fetchLastGrossProfitData = (cid, gpID) => {
  return (dispatch) => {
    return axios.post(`${ROOT_URL}/customers/${cid}/retrievegrossprofitfromfinancialstatement/${gpID}/`, {})
        .then((res) => {
              if (!_.isEmpty(res.data)) {
                dispatch({
                  type: FETCH_GROSS_PROFIT_DATA,
                  payload: res.data
                });
              } else {
                dispatch({
                  type: IS_GROSS_PROFIT_EMPTY,
                });
              }
              return res;
            },
            (error) => {
              dispatch({
                type: ERROR_GROSS_PROFIT_DATA,
              });
            })
        .catch((error) => {
          console.log(error)
        });
  }
}

export const updateGrossProfitData = (cid, gpID, grossProfitData) => {
  return (dispatch) => {
    return axios.put(`${ROOT_URL}/customers/${cid}/grossprofitdata/${gpID}/`, grossProfitData)
        .then((res) => {
              dispatch({
                type: UPDATE_GROSS_PROFIT_DATA,
                payload: res.data
              });
              return res;
            },
            (error) => {
              dispatch({
                type: ERROR_GROSS_PROFIT_DATA,
              });
                return error;
            });
  };
};

export const clearGrossProfit = () => {
  return {
    type: CLEAR_GROSS_PROFIT_DATA
  }
}
