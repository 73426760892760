//props: isOpen, setIsOpen
import logoPicture from "../../style/images/logonotxt.png";
import {Avatar, Button, Col, Layout, Menu, Modal, Row} from "antd";
import {useHistory} from "react-router-dom";
import {T, useT} from "react-polyglot-hooks";
import {LogoutOutlined, QuestionCircleOutlined} from "@ant-design/icons";
import React, {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import GuideLines from "../../user/components/GuideLines";
import {logout} from "../../login/actions";
import {endOnboarding, setSidebarOpen, startOnboarding} from "../actions";
import {useWindowSize} from "../customHooks/useWindowSize";
import {getItem, isMobile} from "../sidebarUtils";
import SidebarExtraItems from "./SidebarExtraItems";

const {Sider} = Layout;


export const Sidebar = (props) => {
    const history = useHistory();
    const dispatch = useDispatch();
    const getPhrase = useT();
    const windowSize = useWindowSize();

    const specificUserData = useSelector(store => store.user.specificUserData);
    const isContactUser = useSelector((store) => store.user.userData?.contact_user);
    const isOpen = useSelector(store => store.common.isSidebarOpen);
    const isInFullscreenMode = useSelector(store => store.common.isInFullScreenMode);
    const isOnboardingRunning = useSelector(store => store.common.isOnboardingRunning);

    const [showGuidesModal, setGuidesModal] = useState(false);
    const [showLogoutModal, setShowLogoutModal] = useState(false);

    useEffect(() => {
        document.getElementById("main-sider").style.display = isInFullscreenMode ? "none" : "block";
    }, [isInFullscreenMode]);

    const standardItems = [
        getItem(
            <T phrase="user.user_profile"/>,
            "2",
            <Avatar src={specificUserData?.profile?.avatar} size={36}/>,
            null,
            () => {
                history.push(`/user/`);
                if (isMobile(windowSize) === true) dispatch(setSidebarOpen(false));
            }),
        getItem(
            <T phrase="content_value.content_value_help"/>,
            "3",
            <QuestionCircleOutlined style={{fontSize: "25px"}}/>,
            null,
            () => {
                //setGuidesModal(true);
                if (!isOnboardingRunning)
                    dispatch(startOnboarding());
                else dispatch(endOnboarding());
                if (isMobile(windowSize) === true) dispatch(setSidebarOpen(false));
            }),
        getItem(
            <T phrase="content_value.content_value_exit"/>,
            "4",
            <LogoutOutlined style={{fontSize: "25px"}}/>,
            null,
            () => {
                setShowLogoutModal(true);
                if (isMobile(windowSize) === true) dispatch(setSidebarOpen(false));
            })
    ];

    return (<React.Fragment>
        {<Modal open={showGuidesModal}
                title={getPhrase("content_value.content_value_help")}
                centered={true}
                onCancel={() => setGuidesModal(false)}
                footer={<Button onClick={() => setGuidesModal(false)}>
                    <T phrase="general.close"/>
                </Button>}
        >
            <GuideLines modal={true}/>
        </Modal>}
        {<Modal open={showLogoutModal}
                title={"Logout"}
                onCancel={() => setShowLogoutModal(false)}
                onOk={() => {
                    setShowLogoutModal(false);
                    history.push("/login/");
                    dispatch(logout());
                }}
                okText="Logout"
                centered
                cancelText={getPhrase("general.cancel")}
                okButtonProps={{danger: true}}>
            <T phrase="user.confirm_logout"/>
        </Modal>}
        <Sider id={"main-sider"}
               breakpoint="sm"
               collapsedWidth={isMobile(windowSize) ? "0px" : "80px"}
               collapsible={true}
               trigger={null}
               collapsed={(!isOpen && isMobile(windowSize)) || (isOpen && !isMobile(windowSize)) || isInFullscreenMode}
               onBreakpoint={(broken) => dispatch(setSidebarOpen(!broken))}
               style={{
                   height: "100vh",
                   zIndex: 10,
                   backgroundColor: "rgb(182, 213, 182)",
               }}>
            <div style={{width: "100%", margin: "auto"}}>
                <img alt="MindRisk logo" src={logoPicture} className="project-logo-menu"/>
            </div>
            {!isContactUser && <SidebarExtraItems/>}
            <Row justify={"start"}
                 align={"bottom"}
                 style={{position: "absolute", bottom: "80px", left: 0, width: "100%"}}>
                <Col flex={1}>
                    <Menu mode={"vertical"}
                          className={"standardItems"}
                          items={standardItems}
                          style={{backgroundColor: "transparent", border: "none"}}/>
                </Col>
            </Row>
        </Sider>
    </React.Fragment>);
};