export const getItem = (label, key, icon, children, onClick) => {
    return {
        key,
        icon,
        children,
        label,
        onClick
    };
};

export const isMobile = (size) => size < 576;