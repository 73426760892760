import {
    ADD_APPRAISAL,
    ADD_APPRAISAL_ERROR,
    ADD_ASSET,
    ADD_ASSET_ERROR,
    ADD_CONTENT_ROW,
    ADD_CONTENT_ROW_ERROR,
    ADD_MACRO_CATEGORIE,
    ADD_MACRO_CATEGORIE_ERROR,
    ADD_PHOTO,
    ADD_PHOTO_ERROR,
    ADD_SECTOR,
    ADD_SECTOR_ERROR,
    ADDING_APPRAISAL,
    ADDING_ASSET,
    ADDING_CONTENT_ROW,
    ADDING_MACRO_CATEGORIE,
    ADDING_PHOTO,
    ADDING_SECTOR, CLEAR_CONTENT_VALUE,
    DELETE_APPRAISAL,
    DELETE_ASSET,
    DELETE_CONTENT_ROW,
    DELETE_MACRO_CATEGORIE,
    DELETE_PHOTO,
    DELETE_SECTOR,
    EDIT_APPRAISAL,
    EDIT_ASSET,
    EDIT_CONTENT_ROW,
    EDIT_MACRO_CATEGORIE,
    EDIT_PHOTO,
    EDIT_SECTOR,
    EMPTY_APPRAISAL,
    EMPTY_APPRAISALS,
    EMPTY_ASSET,
    EMPTY_ASSETS,
    EMPTY_CONTENT_ROW,
    EMPTY_CONTENT_ROWS,
    EMPTY_CONTENT_ROWS_BASE_RISK_LOCATIONS,
    EMPTY_CONTENT_ROWS_RISK_LOCATIONS,
    EMPTY_CONTENT_ROWS_SECTORS,
    EMPTY_MACRO_CATEGORIE,
    EMPTY_MACRO_CATEGORIES,
    EMPTY_PHOTO,
    EMPTY_PHOTOS,
    EMPTY_RISK_LOCATIONS_NAMES,
    EMPTY_SECTOR,
    EMPTY_SECTORS,
    ERROR_EDIT_APPRAISAL,
    ERROR_EDIT_ASSET,
    ERROR_EDIT_CONTENT_ROW,
    ERROR_EDIT_MACRO_CATEGORIE,
    ERROR_EDIT_SECTOR,
    ERROR_GET_APPRAISAL,
    ERROR_GET_APPRAISALS,
    ERROR_GET_ASSET,
    ERROR_GET_ASSETS,
    ERROR_GET_CONTENT_ROW,
    ERROR_GET_CONTENT_ROWS,
    ERROR_GET_CONTENT_ROWS_BASE_RISK_LOCATIONS,
    ERROR_GET_CONTENT_ROWS_RISK_LOCATIONS,
    ERROR_GET_CONTENT_ROWS_SECTORS,
    ERROR_GET_MACRO_CATEGORIE,
    ERROR_GET_MACRO_CATEGORIES,
    ERROR_GET_PHOTO,
    ERROR_GET_PHOTOS,
    ERROR_GET_SECTOR,
    ERROR_GET_SECTORS,
    ERROR_RISK_LOCATIONS_NAMES,
    GET_ACTUALIZATION_FACTOR,
    GET_APPRAISAL,
    GET_APPRAISALS,
    GET_ASSET,
    GET_ASSETS,
    GET_CONTENT_ROW,
    GET_CONTENT_ROWS,
    GET_CONTENT_ROWS_BASE_RISK_LOCATIONS,
    GET_CONTENT_ROWS_RISK_LOCATIONS,
    GET_CONTENT_ROWS_SECTORS, GET_FILTERED_ASSETS,
    GET_LAST_ADDED_APPRAISAL,
    GET_MACRO_CATEGORIE,
    GET_MACRO_CATEGORIES,
    GET_PHOTO,
    GET_PHOTOS,
    GET_RISK_LOCATIONS_NAMES,
    GET_SECTOR,
    GET_SECTORS,
    LOAD_APPRAISAL,
    LOAD_APPRAISALS,
    LOAD_ASSET,
    LOAD_ASSETS,
    LOAD_CONTENT_ROW,
    LOAD_CONTENT_ROWS,
    LOAD_CONTENT_ROWS_BASE_RISK_LOCATIONS,
    LOAD_CONTENT_ROWS_RISK_LOCATIONS,
    LOAD_CONTENT_ROWS_SECTORS,
    LOAD_MACRO_CATEGORIE,
    LOAD_MACRO_CATEGORIES,
    LOAD_PHOTO,
    LOAD_PHOTOS,
    LOAD_RISK_LOCATIONS_NAMES,
    LOAD_SECTOR,
    LOAD_SECTORS,
    LOAD_CONTENT_ROWS_ASSETS,
    GET_CONTENT_ROWS_ASSETS,
    EMPTY_CONTENT_ROWS_ASSETS,
    ERROR_GET_CONTENT_ROWS_ASSETS,
} from "./actions";
import _ from "lodash";

const INITIAL_STATE = {
    appraisalAddedInfo: {},
    appraisals: [],
    isAppraisalsLoading: false,
    emptyAppraisals: false,
    errorGetAppraisals: false,
    appraisal: {},
    isAppraisalLoading: false,
    emptyAppraisal: false,
    errorGetAppraisal: false,
    isAppraisalAdding: false,
    isAppraisalAdded: false,
    errorAddAppraisal: false,
    isAppraisalEdited: false,
    errorEditAppraisal: false,
    isAppraisalDeleted: false,
    contentRows: {},
    isContentRowsLoading: false,
    emptyContentRows: false,
    errorGetContentRows: false,
    contentRow: {},
    isContentRowLoading: false,
    emptyContentRow: false,
    errorGetContentRow: false,
    isContentRowAdding: false,
    isContentRowAdded: false,
    errorAddContentRow: false,
    isContentRowEdited: false,
    errorEditContentRow: false,
    isContentRowDeleted: false,
    contentRowRiskLocations: [],
    isContentRowRiskLocationsLoading: false,
    emptyContentRowRiskLocations: false,
    errorGetContentRowRiskLocations: false,
    contentRowBaseRiskLocations: [],
    isContentRowBaseRiskLocationsLoading: false,
    emptyContentRowBaseRiskLocations: false,
    errorGetContentRowBaseRiskLocations: false,
    contentRowsSectors: [],
    isContentRowsSectorsLoading: false,
    emptyContentRowsSectors: false,
    errorGetContentRowsSectors: false,
    macroCategories: [],
    isMacroCategoriesLoading: false,
    emptyMacroCategories: false,
    errorGetMacroCategories: false,
    macroCategorie: {},
    isMacroCategorieLoading: false,
    emptyMacroCategorie: false,
    errorGetMacroCategorie: false,
    isMacroCategorieAdding: false,
    isMacroCategorieAdded: false,
    errorAddMacroCategorie: false,
    isMacroCategorieEdited: false,
    errorEditMacroCategorie: false,
    isMacroCategorieDeleted: false,
    photos: [],
    isPhotosLoading: false,
    emptyPhotos: false,
    errorGetPhotos: false,
    photo: {},
    isPhotoLoading: false,
    emptyPhoto: false,
    errorGetPhoto: false,
    isPhotoAdding: false,
    isPhotoAdded: false,
    errorAddPhoto: false,
    isPhotoEdited: false,
    errorEditPhoto: false,
    isPhotoDeleted: false,
    sectors: [],
    isSectorsLoading: false,
    emptySectors: false,
    errorGetSectors: false,
    sector: {},
    isSectorLoading: false,
    emptySector: false,
    errorGetSector: false,
    isSectorAdding: false,
    isSectorAdded: false,
    errorAddSector: false,
    isSectorEdited: false,
    errorEditSector: false,
    isSectorDeleted: false,
    assets: {},
    isAssetsLoading: false,
    emptyAssets: false,
    errorGetAssets: false,
    asset: {},
    isAssetLoading: false,
    emptyAsset: false,
    errorGetAsset: false,
    isAssetAdding: false,
    isAssetAdded: false,
    errorAddAsset: false,
    isAssetEdited: false,
    errorEditAsset: false,
    isAssetDeleted: false,
    riskLocationsNames: [],
    isRiskLocationsNamesLoading: false,
    errorRiskLocationsNames: false,
    emptyRiskLocationsNames: false,
    actualizationFactor: null,
    filteredAssets: [],
    contentRowAssets: [],
    isContentRowAssetsLoading: false,
    emptyContentRowAssets: false,
    errorGetContentRowAssets: false,
};

export default function ContentValueReducer(state = INITIAL_STATE, action) {
    switch (action.type) {
        case LOAD_CONTENT_ROWS_ASSETS:
            return {
                ...state, isContentRowAssetsLoading: true,
            };

        case GET_CONTENT_ROWS_ASSETS:
            return {
                ...state,
                isContentRowAssetsLoading: false,
                contentRowAssets: action.payload,
                errorGetContentAssets: false,
                emptyContentRowAssets: false,
            };

        case EMPTY_CONTENT_ROWS_ASSETS:
            return {
                ...state,
                isContentRowAssetsLoading: false,
                emptyContentRowAssets: true,
                contentRowAssets: [],
                errorGetContentAssets: false,
            };

        case ERROR_GET_CONTENT_ROWS_ASSETS:
            return {
                ...state, isContentRowAssetsLoading: false, errorGetContentRowAssets: true,
            };
        case CLEAR_CONTENT_VALUE:
            return INITIAL_STATE;
        case LOAD_APPRAISALS:
            return {
                ...state, isAppraisalsLoading: true,
            };

        case GET_APPRAISALS:
            return {
                ...state,
                isAppraisalsLoading: false,
                appraisals: action.payload,
                errorGetAppraisals: false,
                emptyAppraisals: false,
            };

        case GET_LAST_ADDED_APPRAISAL:
            return {
                ...state, appraisalAddedInfo: action.payload,
            };

        case EMPTY_APPRAISALS:
            return {
                ...state, isAppraisalsLoading: false, emptyAppraisals: true, appraisals: [], errorGetAppraisals: false,
            };

        case ERROR_GET_APPRAISALS:
            return {
                ...state, isAppraisalsLoading: false, errorGetAppraisals: true,
            };

        case LOAD_APPRAISAL:
            return {
                ...state, isAppraisalLoading: true,
            };

        case GET_APPRAISAL:
            return {
                ...state,
                isAppraisalLoading: false,
                isAppraisalAdded: false,
                appraisal: action.payload,
                errorGetAppraisal: false,
                emptyAppraisal: false,
            };

        case EMPTY_APPRAISAL:
            return {
                ...state, isAppraisalLoading: false, emptyAppraisal: true, appraisal: {}, errorGetAppraisal: false,
            };

        case ERROR_GET_APPRAISAL:
            return {
                ...state, isAppraisalLoading: false, errorGetAppraisal: true,
            };

        case ADDING_APPRAISAL:
            return {
                ...state, isAppraisalAdding: true, isAppraisalAdded: false, errorAddAppraisal: false,
            };

        case ADD_APPRAISAL:
            return {
                ...state,
                isAppraisalAdding: false,
                isAppraisalAdded: true,
                errorAddAppraisal: false,
                appraisalAddedInfo: action.payload,
            };

        case ADD_APPRAISAL_ERROR:
            return {
                ...state, isAppraisalAdding: false, isAppraisalAdded: false, errorAddAppraisal: true,
            };

        case EDIT_APPRAISAL:
            return {
                ...state, isAppraisalEdited: true, errorEditAppraisal: false, appraisal: action.payload
            };

        case ERROR_EDIT_APPRAISAL:
            return {
                ...state, isAppraisalEdited: false, errorEditAppraisal: true,
            };

        case DELETE_APPRAISAL:
            return {
                ...state,
                isAppraisalDeleted: true,
                appraisal: {},
                appraisals: state.appraisals.filter(appraisal => appraisal.uuid !== action.payload)
            };

        case LOAD_CONTENT_ROWS:
            return {
                ...state, isContentRowsLoading: true,
            };

        case GET_CONTENT_ROWS:
            return {
                ...state,
                isContentRowsLoading: false,
                contentRows: action.payload,
                errorGetContentRows: false,
                emptyContentRows: false,
            };

        case EMPTY_CONTENT_ROWS:
            return {
                ...state,
                isContentRowsLoading: false,
                emptyContentRows: true,
                contentRows: [],
                errorGetContentRows: false,
            };

        case ERROR_GET_CONTENT_ROWS:
            return {
                ...state, isContentRowsLoading: false, errorGetContentRows: true,
            };

        case LOAD_CONTENT_ROW:
            return {
                ...state, isContentRowLoading: true,
            };

        case GET_CONTENT_ROW:
            return {
                ...state,
                isContentRowLoading: false,
                contentRow: action.payload,
                errorGetContentRow: false,
                emptyContentRow: false,
            };

        case EMPTY_CONTENT_ROW:
            return {
                ...state, isContentRowLoading: false, emptyContentRow: true, contentRow: {}, errorGetContentRow: false,
            };

        case ERROR_GET_CONTENT_ROW:
            return {
                ...state, isContentRowLoading: false, errorGetContentRow: true,
            };

        case ADDING_CONTENT_ROW:
            return {
                ...state, isContentRowAdding: true, isContentRowAdded: false, errorAddContentRow: false,
            };

        case ADD_CONTENT_ROW:
            return {
                ...state, isContentRowAdding: false, isContentRowAdded: true, errorAddContentRow: false,
            };

        case ADD_CONTENT_ROW_ERROR:
            return {
                ...state, isContentRowAdding: false, isContentRowAdded: false, errorAddContentRow: true,
            };

        case EDIT_CONTENT_ROW:
            const copy = _.cloneDeep(state.contentRows);
            let index = copy.results.findIndex(row => row.id === action.payload.id);
            if (index >= 0) {
                copy.results[index] = action.payload;
            }

            return {
                ...state, isContentRowEdited: true, errorEditContentRow: false, contentRows: copy
            };

        case ERROR_EDIT_CONTENT_ROW:
            return {
                ...state, isContentRowEdited: false, errorEditContentRow: true,
            };

        case DELETE_CONTENT_ROW:
            return {
                ...state, isContentRowDeleted: true,
            };

        case LOAD_CONTENT_ROWS_RISK_LOCATIONS:
            return {
                ...state, isContentRowRiskLocationsLoading: true,
            };

        case GET_CONTENT_ROWS_RISK_LOCATIONS:
            return {
                ...state,
                isContentRowRiskLocationsLoading: false,
                contentRowRiskLocations: action.payload,
                errorGetContentRowRiskLocations: false,
                emptyContentRowRiskLocations: false,
            };

        case EMPTY_CONTENT_ROWS_RISK_LOCATIONS:
            return {
                ...state,
                isContentRowRiskLocationsLoading: false,
                emptyContentRowRiskLocations: true,
                contentRowRiskLocations: [],
                errorGetContentRowRiskLocations: false,
            };

        case ERROR_GET_CONTENT_ROWS_RISK_LOCATIONS:
            return {
                ...state, isContentRowRiskLocationsLoading: false, errorGetContentRowRiskLocations: true,
            };

        case LOAD_CONTENT_ROWS_BASE_RISK_LOCATIONS:
            return {
                ...state, isContentRowBaseRiskLocationsLoading: true,
            };

        case GET_CONTENT_ROWS_BASE_RISK_LOCATIONS:
            return {
                ...state,
                isContentRowBaseRiskLocationsLoading: false,
                contentRowBaseRiskLocations: action.payload,
                errorGetContentRowBaseRiskLocations: false,
                emptyContentRowBaseRiskLocations: false,
            };

        case EMPTY_CONTENT_ROWS_BASE_RISK_LOCATIONS:
            return {
                ...state,
                isContentRowBaseRiskLocationsLoading: false,
                emptyContentRowBaseRiskLocations: true,
                contentRowBaseRiskLocations: [],
                errorGetContentRowBaseRiskLocations: false,
            };

        case ERROR_GET_CONTENT_ROWS_BASE_RISK_LOCATIONS:
            return {
                ...state, isContentRowBaseRiskLocationsLoading: false, errorGetContentRowBaseRiskLocations: true,
            };

        case LOAD_RISK_LOCATIONS_NAMES:
            return {
                ...state, isRiskLocationsNamesLoading: true,
            };

        case GET_RISK_LOCATIONS_NAMES:
            return {
                ...state,
                isRiskLocationsNamesLoading: false,
                riskLocationsNames: action.payload,
                errorRiskLocationsNames: false,
                emptyRiskLocationsNames: false,
            };

        case EMPTY_RISK_LOCATIONS_NAMES:
            return {
                ...state,
                isRiskLocationsNamesLoading: false,
                emptyRiskLocationsNames: true,
                riskLocationsNames: [],
                errorRiskLocationsNames: false,
            };

        case ERROR_RISK_LOCATIONS_NAMES:
            return {
                ...state, isRiskLocationsNamesLoading: false, errorRiskLocationsNames: true,
            };

        case LOAD_CONTENT_ROWS_SECTORS:
            return {
                ...state, isRiskLocationsNamesLoading: true,
            };

        case GET_CONTENT_ROWS_SECTORS:
            return {
                ...state,
                isContentRowsSectorsLoading: false,
                contentRowsSectors: action.payload,
                errorGetContentRowsSectors: false,
                emptyContentRowsSectors: false,
            };

        case EMPTY_CONTENT_ROWS_SECTORS:
            return {
                ...state,
                isContentRowsSectorsLoading: false,
                emptyContentRowsSectors: true,
                contentRowsSectors: [],
                errorGetContentRowsSectors: false,
            };

        case ERROR_GET_CONTENT_ROWS_SECTORS:
            return {
                ...state, isContentRowsSectorsLoading: false, errorGetContentRowsSectors: true,
            };

        case LOAD_MACRO_CATEGORIES:
            return {
                ...state, isMacroCategoriesLoading: true,
            };

        case GET_MACRO_CATEGORIES:
            return {
                ...state,
                isMacroCategoriesLoading: false,
                macroCategories: action.payload,
                errorGetMacroCategories: false,
                emptyMacroCategories: false,
            };

        case EMPTY_MACRO_CATEGORIES:
            return {
                ...state,
                isMacroCategoriesLoading: false,
                emptyMacroCategories: true,
                macroCategories: [],
                errorGetMacroCategories: false,
            };

        case ERROR_GET_MACRO_CATEGORIES:
            return {
                ...state, isMacroCategoriesLoading: false, errorGetMacroCategories: true,
            };

        case LOAD_MACRO_CATEGORIE:
            return {
                ...state, isMacroCategorieLoading: true,
            };

        case GET_MACRO_CATEGORIE:
            return {
                ...state,
                isMacroCategorieLoading: false,
                macroCategorie: action.payload,
                errorGetMacroCategorie: false,
                emptyMacroCategorie: false,
            };

        case EMPTY_MACRO_CATEGORIE:
            return {
                ...state,
                isMacroCategorieLoading: false,
                emptyMacroCategorie: true,
                macroCategorie: {},
                errorGetMacroCategorie: false,
            };

        case ERROR_GET_MACRO_CATEGORIE:
            return {
                ...state, isMacroCategorieLoading: false, errorGetMacroCategorie: true,
            };

        case ADDING_MACRO_CATEGORIE:
            return {
                ...state, isMacroCategorieAdding: true, isMacroCategorieAdded: false, errorAddMacroCategorie: false,
            };

        case ADD_MACRO_CATEGORIE:
            return {
                ...state,
                isMacroCategorieAdding: false,
                isMacroCategorieAdded: true,
                errorAddMacroCategorie: false,
                macroCategories: [action.payload, ...state.macroCategories],
            };

        case ADD_MACRO_CATEGORIE_ERROR:
            return {
                ...state, isMacroCategorieAdding: false, isMacroCategorieAdded: false, errorAddMacroCategorie: true,
            };

        case EDIT_MACRO_CATEGORIE:
            return {
                ...state,
                isMacroCategorieEdited: true,
                errorEditMacroCategorie: false,
                macroCategories: state.macroCategories.map(macro => {
                    return (macro.uuid === action.payload.uuid ? action.payload : macro);
                })
            };

        case ERROR_EDIT_MACRO_CATEGORIE:
            return {
                ...state, isMacroCategorieEdited: false, errorEditMacroCategorie: true,
            };

        case DELETE_MACRO_CATEGORIE:
            return {
                ...state,
                isMacroCategorieDeleted: true,
                macroCategories: state.macroCategories.filter(macro => macro.uuid !== action.payload)
            };

        case LOAD_PHOTOS:
            return {
                ...state, isPhotosLoading: true,
            };

        case GET_PHOTOS:
            return {
                ...state, isPhotosLoading: false, photos: action.payload, errorGetPhotos: false, emptyPhotos: false,
            };

        case EMPTY_PHOTOS:
            return {
                ...state, isPhotosLoading: false, emptyPhotos: true, photos: [], errorGetPhotos: false,
            };

        case ERROR_GET_PHOTOS:
            return {
                ...state, isPhotosLoading: false, errorGetPhotos: true,
            };

        case LOAD_PHOTO:
            return {
                ...state, isPhotoLoading: true,
            };

        case GET_PHOTO:
            return {
                ...state, isPhotoLoading: false, photo: action.payload, errorGetPhoto: false, emptyPhoto: false,
            };

        case EMPTY_PHOTO:
            return {
                ...state, isPhotoLoading: false, emptyPhoto: true, photo: {}, errorGetPhoto: false,
            };

        case ERROR_GET_PHOTO:
            return {
                ...state, isPhotoLoading: false, errorGetPhoto: true,
            };

        case ADDING_PHOTO:
            return {
                ...state, isPhotoAdding: true, isPhotoAdded: false, errorAddPhoto: false,
            };

        case ADD_PHOTO:
            return {
                ...state,
                isPhotoAdding: false,
                isPhotoAdded: true,
                errorAddPhoto: false,
                photos: [action.payload, ...state.photos]
            };

        case ADD_PHOTO_ERROR:
            return {
                ...state, isPhotoAdding: false, isPhotoAdded: false, errorAddPhoto: true,
            };

        case EDIT_PHOTO:
            return {
                ...state, isPhotoEdited: true, errorEditPhoto: false, photos: state.photos.map(photo => {
                    return (photo.uuid === action.payload.uuid ? action.payload : photo);
                })
            };
        /*
        case ERROR_EDIT_PHOTO:
          return {
            ...state,
            isPhotoEdited: false,
            errorEditPhoto: true,
          };
    */
        case DELETE_PHOTO:
            return {
                ...state,
                isPhotoDeleted: true,
                photos: state.photos.filter(photo => photo.uuid !== action.payload)
            };

        case LOAD_SECTORS:
            return {
                ...state, isSectorsLoading: true,
            };

        case GET_SECTORS:
            return {
                ...state,
                isSectorsLoading: false,
                sectors: action.payload,
                errorGetSectors: false,
                emptySectors: false,
                isSectorDeleted: false,
            };

        case EMPTY_SECTORS:
            return {
                ...state, isSectorsLoading: false, emptySectors: true, sectors: [], errorGetSectors: false,
            };

        case ERROR_GET_SECTORS:
            return {
                ...state, isSectorsLoading: false, errorGetSectors: true,
            };

        case LOAD_SECTOR:
            return {
                ...state, isSectorLoading: true,
            };

        case GET_SECTOR:
            return {
                ...state, isSectorLoading: false, sector: action.payload, errorGetSector: false, emptySector: false,
            };

        case EMPTY_SECTOR:
            return {
                ...state, isSectorLoading: false, emptySector: true, sector: {}, errorGetSector: false,
            };

        case ERROR_GET_SECTOR:
            return {
                ...state, isSectorLoading: false, errorGetSector: true,
            };

        case ADDING_SECTOR:
            return {
                ...state, isSectorAdding: true, isSectorAdded: false, errorAddSector: false,
            };

        case ADD_SECTOR:
            return {
                ...state, isSectorAdding: false, isSectorAdded: true, errorAddSector: false,
            };

        case ADD_SECTOR_ERROR:
            return {
                ...state, isSectorAdding: false, isSectorAdded: false, errorAddSector: true,
            };

        case EDIT_SECTOR:
            return {
                ...state, isSectorEdited: true, errorEditSector: false,
            };

        case ERROR_EDIT_SECTOR:
            return {
                ...state, isSectorEdited: false, errorEditSector: true,
            };

        case DELETE_SECTOR:
            return {
                ...state, isSectorDeleted: true,
            };

        case LOAD_ASSETS:
            return {
                ...state, isSectorsLoading: true,
            };

        case GET_ASSETS:
            return {
                ...state, isSectorsLoading: false, sectors: action.payload, errorGetSectors: false, emptySectors: false,
            };

        case EMPTY_ASSETS:
            return {
                ...state, isSectorsLoading: false, emptySectors: true, sectors: {}, errorGetSectors: false,
            };

        case ERROR_GET_ASSETS:
            return {
                ...state, isSectorsLoading: false, errorGetSectors: true,
            };

        case LOAD_ASSET:
            return {
                ...state, isSectorLoading: true,
            };

        case GET_ASSET:
            return {
                ...state, isSectorLoading: false, sector: action.payload, errorGetSector: false, emptySector: false,
            };

        case EMPTY_ASSET:
            return {
                ...state, isSectorLoading: false, emptySector: true, sector: {}, errorGetSector: false,
            };

        case ERROR_GET_ASSET:
            return {
                ...state, isSectorLoading: false, errorGetSector: true,
            };

        case ADDING_ASSET:
            return {
                ...state, isSectorAdding: true, isSectorAdded: false, errorAddSector: false,
            };

        case ADD_ASSET:
            return {
                ...state, isSectorAdding: false, isSectorAdded: true, errorAddSector: false,
            };

        case ADD_ASSET_ERROR:
            return {
                ...state, isSectorAdding: false, isSectorAdded: false, errorAddSector: true,
            };

        case EDIT_ASSET:
            return {
                ...state, isSectorEdited: true, errorEditSector: false,
            };

        case ERROR_EDIT_ASSET:
            return {
                ...state, isSectorEdited: false, errorEditSector: true,
            };

        case DELETE_ASSET:
            return {
                ...state, isAssetDeleted: true,
            };

        case GET_ACTUALIZATION_FACTOR:
            return {...state, actualizationFactor: action.payload};
        case GET_FILTERED_ASSETS:
            return {...state, filteredAssets: action.payload};
        default:
            return state;
    }
}
